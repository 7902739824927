import React, { useState } from 'react'
import { useAuthState } from '../../store/store'

const InfoCustomerComponent = () => {

    const {getUser} = useAuthState();
    const [email, setEmail] = useState();

  return (
    <div>
        <p className='font-semibold pb-4 text-xl'>Mes informations personnelles</p>
        <div className='flex flex-row gap-2 lg:gap-6'>
        <div className='my-2 py-1 w-full px-2 rounded-md border border-greyAccent bg-white'>
            <label htmlFor="" className='text-greyAccent text-xs'>Nom</label>
            <input className='w-full text-black font-medium' value={getUser('lastname')} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="" id="" />
        </div>
        <div className='my-2 py-1 w-full px-2 rounded-md border border-greyAccent bg-white'>
            <label htmlFor="" className='text-greyAccent text-xs'>Prénom</label>
            <input className='w-full text-black font-medium' value={getUser('firstname')} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="" id="" />
        </div>
        </div>
        <div className='my-2 py-1 w-full px-2 rounded-md border border-greyAccent bg-white'>
        <label htmlFor="" className='text-greyAccent text-xs'>E-mail</label>
        <input className='w-full text-black font-medium' value={getUser('email')} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="" id="" />
        </div>
        <div className='my-2 py-1 w-full px-2 rounded-md border border-greyAccent bg-white'>
        <label htmlFor="" className='text-greyAccent text-xs'>Téléphone</label>
        <input className='w-full text-black font-medium' value={getUser('phone')} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="" id="" />
        </div>
        <div className='my-2 py-1 w-full px-2 rounded-md border border-greyAccent bg-white'>
        <label htmlFor="" className='text-greyAccent text-xs'>Date de naissance</label>
        <input className='w-full text-black font-medium' value={getUser('birthday')} onChange={(e) => setEmail(e.target.value)} type="date" placeholder="" id="" />
        </div>
        <p className='font-semibold pb-4 text-xl'>Information de l’entreprise </p>
        <div className='my-2 py-1 w-full px-2 rounded-md border border-greyAccent bg-white'>
        <label htmlFor="" className='text-greyAccent text-xs'>Nom de l’entreprise</label>
        <input className='w-full text-black font-medium uppercase' value={getUser('compagnyname')} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="" id="" />
        </div>
        <div className='my-2 py-1 w-full px-2 rounded-md border border-greyAccent bg-white'>
        <label htmlFor="" className='text-greyAccent text-xs'>Numéro de SIRET</label>
        <input className='w-full text-black font-medium uppercase' value={getUser('siret')} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="" id="" />
        </div>
        <div className='my-2 py-1 w-full px-2 rounded-md border border-greyAccent bg-white'>
        <label htmlFor="" className='text-greyAccent text-xs'>Domaine d’activité</label>
        <input className='w-full text-black font-medium uppercase' value={getUser('activitydomaine')} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="" id="" />
        </div>
    </div>
  )
}

export default InfoCustomerComponent