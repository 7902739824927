import React, { useState } from 'react'
import { toPrice } from '../utils/someFunctions'
import DropzoneWithPreview from './html/Dropzone'
import DropzoneFileSend from './html/DropzoneFilesSend';
import MultiRangeSlider from 'multi-range-slider-react';

const ModalFinancementComponent = ({closeModal, product, data}) => {


    const [form, setForm] = useState({
        loyer: parseFloat(product.price_ht*0.14).toFixed(2),
        duree: 24,
        nom_societe: '',
        nom_prenom: '',
        email: '',
        telephone: '',
        kbis: '',
    });

    const setFormData = (key, value) => {
        setForm({...form, [key]: value});
    }

    const sendForm = () => {
        // Send to directus
        var formdata = new FormData();
        formdata.append('company_name', form.nom_societe);
        formdata.append('name', form.nom_prenom);
        formdata.append('email', form.email);
        formdata.append('telephone', form.telephone);
        formdata.append('kbis', form.kbis);
        formdata.append('product_ref', product.reference);
        formdata.append('product_name', product.name);
        formdata.append('product_id', product.id);
        formdata.append('duree', form.duree);
        formdata.append('montant_first_loyer', form.loyer);

        fetch(process.env.REACT_APP_DIRECTUS_URL+'items/demandes_devis', {
            method: 'POST',
            body: JSON.stringify({
                company_name: form.nom_societe,
                name: form.nom_prenom,
                email: form.email,
                telephone: form.telephone,
                kbis: form.kbis,
                product_ref: product.reference,
                product_name: product.name,
                product_id: product.id,
                duree: form.duree,
                montant_first_loyer: form.loyer
            }),
        }).then(response => console.log(response));


    }



  return (
    <div className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center">
        <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-50" onClick={closeModal}></div>
        </div>
        <div className="bg-white px-12 py-3 mobile:p-6 my-8 rounded-xl shadow-lg z-50 m-4 w-3/5 mobile:w-full">
            <div className="">
                <div className='flex flex-row justify-between items-center'>
                    <div className=''>
                        <h1 className='text-4xl mobile:text-lg font-semibold'>Calculateur de financement</h1>
                    </div>
                    <div className=''>
                        <img className='lg:w-full w-12' src={window.location.origin + '/images/Calculator.png'} alt="" />
                    </div>
                </div>
                <p className='font-bold'>Nos offres de financement pour  : </p>
                <p className='font-semibold text-2xl mobile:text-lg'>{product.name}</p>
                <p className="py-4 font-light">{data.description}</p>
                <div className="w-full h-0.5 bg-greySoft rounded-xl"></div>
                <h2 className='font-bold text-xl py-4 mobile:text-sm'>Demande de financement</h2>
                <div className="grid grid-cols-1 mobile:grid-cols-1">
                    <div className='grid grid-cols-2 gap-4 mobile:grid-cols-1'>
                        <div className='py-2 flex flex-col'>
                            <label className='text-sm font-semibold py-2'>Montant du 1er loyer souhaité</label>
                            <input onChange={(e) => setFormData('loyer', e.target.value)} value={form.loyer} className='bg-grey border border-greySoft rounded-md py-2 px-6 w-full' type="number" />
                        </div>
                        <div className='py-2 flex flex-col w-full'>
                            <label className='text-sm font-semibold py-2'>Durée (mois)</label>
                            <MultiRangeSlider
                                min={18}
                                max={24}
                                minValue={1}
                                maxValue={24}
                                thumbLeftColor='#F1671A'
                                thumbRightColor='#F1671A'
                                barInnerColor='#F1671A'
                                style={{ border: "none", boxShadow: "none", padding: "15px 10px" }}
                                ruler={false}
                                label={false}
                                className='accent-orange'
                                onInput={(e) => {
                                    // setFilters({...filters, minPrice: e.minValue, maxPrice: e.maxValue});
                                    // onFilterChange(e, 'range');
                                }}
                                />
                        </div>
                        <div>
                            <label className='text-sm font-semibold py-2'>Nom de la société </label>
                            <input onChange={(e) => setFormData('nom_societe', e.target.value)} value={form.nom_societe} className='bg-grey border border-greySoft rounded-md py-2 px-6 w-full' type="text" />
                        </div>
                        <div>
                            <label className='text-sm font-semibold py-2'>Nom et prénom</label>
                            <input onChange={(e) => setFormData('nom_prenom', e.target.value)} value={form.nom_prenom} className='bg-grey border border-greySoft rounded-md py-2 px-6 w-full' type="text" />
                        </div>
                        <div>
                            <label className='text-sm font-semibold py-2'>Adresse E-mail</label>
                            <input onChange={(e) => setFormData('email', e.target.value)} value={form.email} className='bg-grey border border-greySoft rounded-md py-2 px-6 w-full' type="text" />
                        </div>
                        <div>
                            <label className='text-sm font-semibold py-2'>Numéro de téléphone</label>
                            <input onChange={(e) => setFormData('telephone', e.target.value)} value={form.telephone} className='bg-grey border border-greySoft rounded-md py-2 px-6 w-full' type="text" />
                        </div>
                    </div>
                    <div className='py-6'>
                    <DropzoneFileSend file_to_depose='votre KBIS'/>
                    </div>
                    
                    {/* <div>
                        <h2 className='text-xl font-semibold py-2'>Assurances</h2>
                        <div>
                            <div className='py-2 flex flex-col w-full mobile:w-full'>
                                <select className="bg-grey border-greySoft border-2 rounded-lg py-2 px-4" name="" id="">
                                    <option value="Décès - Incapacité">Décès - Incapacité + 78,00 €</option>
                                </select>
                            </div>
                            <div className='py-2 flex flex-col w-full mobile:w-full'>
                                <select className="bg-grey border-greySoft border-2 rounded-lg py-2 px-4" name="" id="">
                                    <option value="Décès - Incapacité">Perte financière + 28,80 €</option>
                                </select>
                            </div>
                            <div className='py-2 flex flex-col w-full mobile:w-full'>
                                <select className="bg-grey border-greySoft border-2 rounded-lg py-2 px-4" name="" id="">
                                    <option value="Décès - Incapacité">Entretien et réparation + 228,80 €</option>
                                </select>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div class="grid grid-cols-2 mobile:grid-cols-1">
                    <div></div>
                    {/* <div className='flex items-end flex-col font-semibold'>
                        <h2 className='text-2xl'>Loyers avec assurance </h2>
                        <p className='text-4xl mobile:text-2xl font-bold'>{toPrice(250)}</p>
                    </div> */}
                </div>
                <div class="grid grid-cols-2 mobile:grid-cols-1">
                    <div></div>
                    <div className='flex justify-end py-3'>
                        <button onClick={() => sendForm()} className="px-4 py-3 bg-green text-white text-center font-semibold rounded-xl flex  items-center justify-center">
                            <span>Faire une demande de financement</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
</div>
  )
}

export default ModalFinancementComponent