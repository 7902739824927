import React from 'react'
import { toPrice } from '../utils/someFunctions'

const ModalRecyclerMaterielComponent = ({closeModal, data, approveRecyclage}) => {



  return (
    <div className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center">
        <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-50" onClick={closeModal}></div>
        </div>
        <div className="bg-white m-12 mobile:mx-2 p-12 mobile:p-6 my-8 rounded-xl shadow-lg z-50 w-4/5 mobile:w-full">
            <div className="">
                <div className='flex justify-between items-center'>
                    <div className=''>
                        <h1 className='text-4xl mobile:text-xl font-semibold'>Recycler mon matériel</h1>                
                    </div>
                    <div className='flex justify-end'>
                        <img className='mobile:w-12' src={window.location.origin + '/images/Recycle symbol.png'} alt="" />
                    </div>
                </div>
                <div className="grid lg:grid-cols-2 py-3">
                    <div>
                        <div className='py-2 flex flex-col w-1/2 mobile:w-full'>
                            <label className='text-sm font-semibold py-2'>Sélectionnez la marque du matériel</label>
                            <select className="bg-grey border-greySoft border-2 rounded-lg py-2 px-4" name="" id="">
                                <option value="Canon">Canon</option>
                                <option value="HP">HP</option>
                                <option value="Epson">Epson</option>
                            </select>
                        </div>
                        <div className='py-2 flex flex-col w-1/2 mobile:w-full'>
                            <label className='text-sm font-semibold py-2'>Modèle du matériel </label>
                            <input type="text" className='bg-grey border border-greySoft rounded-md py-2 px-4' name="" id=""/>
                        </div>
                        <div className='py-2 flex flex-col w-1/2 mobile:w-full'>
                            <label className='text-sm font-semibold py-2'>Sélectionnez l’état du matériel</label>
                            <select className="bg-grey border-greySoft border-2 rounded-lg py-2 px-4" name="" id="">
                                <option value="Bon état">Bon état</option>
                                <option value="Etat moyen">Etat moyen</option>
                                <option value="Mauvais état">Mauvais état</option>
                            </select>
                        </div>
                        <div className='py-2 flex flex-col w-1/2 mobile:w-full'>
                            <label className='text-sm font-semibold py-2'>Précisez la date d’achat du matériel</label>
                            <input type='text' placeholder='02/03/2023' className="bg-grey border-greySoft border-2 rounded-lg py-2 px-4" name="" id="" />
                                
                        </div>
                    </div>
                    <div className='flex flex-col justify-between'>
                        <div>
                            <p className='font-light'>{data.description}</p>
                        </div>
                        <div>
                            <p className="text-xl mobile:text-sm font-bold py-3">Prix de rachat de votre matériel</p>
                            <p className="text-6xl mobile:text-4xl font-semibold">{toPrice(data.price)}</p>
                        </div>
                    </div>
                </div>
                <div className="w-full h-0.5 bg-greySoft rounded-xl"></div>
                <div class="grid grid-cols-2 mobile:grid-cols-1">
                    <div></div>
                    <div className='flex justify-end py-3'>
                        <button onClick={approveRecyclage} className="px-4 py-3 bg-green text-white text-center font-semibold rounded-xl flex  items-center justify-center">
                            <span>Faire une demande de recyclage</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
</div>
  )
}

export default ModalRecyclerMaterielComponent