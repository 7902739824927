import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useOneProduct, useProduct, useProductsCategoryName } from '../hooks/usePrestashopProducts';
import HeaderComponent from '../components/headerComponent';
import LoadingComponent from '../components/loadingComponent';
import SliderProductComponent from '../components/sliderProductsComponent';
import ModalExtensionGarantieComponent from '../components/modalExtensionGarantieComponent';
import { useCartState } from '../store/store';
import ModalRecyclerMaterielComponent from '../components/modalRecyclerMateriel';
import ModalFinancementComponent from '../components/modalFinancement';
import { toPrice } from '../utils/someFunctions';
import ProductCardComponent from '../components/productCardComponent';
import Input from '../components/html/input';
import FooterComponent from '../components/footerComponent';
import ImageZoom from "react-image-zooom";


function ProductPage() {
  const { addToCart } = useCartState();
  const { categoryName, linkrewrite} = useParams();
  const {product, loading, error} = useProduct('id_product', linkrewrite.split('-')[0]);
  const [productQte, setProductQte] = useState(1);
  const [selectedImage, setSelectedImage] = useState();
  const [optionGarantie, setOptionGarantie] = useState([]);
  const [optionProduit, setOptionProduit] = useState([]);
  const [recycleProduit, setRecycleProduit] = useState([]);
  const [cadeauProduit, setCadeauProduit] = useState([]);
  const [tab, setTab] = useState(0);
  const [total, setTotal] = useState(0);

  const { products: productsCat, error: errorCat, loading: loadingCat } = useProductsCategoryName(product.categorie_name_defaut, 10);
  
  /* Récuperer les vidéos qui viennent des IFRAME */
  // Étape 1: Extraire toutes les balises <iframe>
  const iframeRegex = /<iframe[^>]*src="[^"]*"[^>]*><\/iframe>/g;
  let iframes = product.description_short ? product.description_short.match(iframeRegex) || [] : [];

  // const iframes = []

  // Étape 2: Nettoyer la description pour enlever les balises <iframe>
  let cleanDescription =  product.description_short? product.description_short.replace(iframeRegex, '') : '';
  // let cleanDescription = product.description_short;

  // Fonction pour extraire l'ID de la vidéo YouTube à partir de l'URL de l'iframe
  const extractYouTubeId = (iframe) => {
    const regex = /youtube\.com\/embed\/([a-zA-Z0-9_-]+)/;
    const match = iframe.match(regex);
    return match ? match[1] : null;
  };

  const isIframe = (src) => {
    // Simple check for common iframe sources (e.g., YouTube, Vimeo) or an <iframe> tag
    return src ? src.includes('youtube') || src.includes('vimeo') || src.startsWith('<iframe') : false;
  }


  // Change width and height of the iframe
  const changeIframeSize = (iframe) => {
    // add css class to iframe
    return iframe.replace(/<iframe/, '<iframe class="product-video"').replace(/width="[^"]*"/, '').replace(/height="[^"]*"/, '');
    // return iframe.replace(/width="[^"]*"/, '').replace(/height="[^"]*"/, '');

  };
  
  // Modals
  const [modalExtension, setModalExtension] = useState(false);
  const [modalFinancement, setModalFinancement] = useState(false);
  const [dataModalFinancement, setDataModalFinancement] = useState([]);

  const handleModalFinancement = (financement) => {
    setModalFinancement(!modalFinancement);
    setDataModalFinancement(financement);
  }

  const [modalRecyclage, setModalRecyclage] = useState(false);
  const [dataModalRecyclage, setDataModalRecyclage] = useState([]);


  const handleModalRecyclage = (reprise) => {
    setModalRecyclage(!modalRecyclage);
    setDataModalRecyclage(reprise);
  }


  useEffect(() => {
    var total = 0;

    total += product.price_ht * productQte;
    if(optionGarantie.length > 0) {
      total += parseFloat(optionGarantie[0].price);
    }

    optionProduit.forEach(option => {
      total += parseFloat(option.price);
    });

    if(recycleProduit != '') {
      total += - parseFloat(recycleProduit.price);
    }

    setTotal(total);

    }, [product, optionGarantie, optionProduit, recycleProduit]
  );

  const chooseGarantie = (garantie) => {

    console.log(optionGarantie)
    // Logique spécifique pour choisir une garantie
    if(optionGarantie.length > 0 && garantie.name == optionGarantie[0]['name']) {
      setOptionGarantie([]);
    }
    else {
      setOptionGarantie([garantie]);
    }
    setModalExtension(false)
  };

  const setUnsetOptionProduit = (option, index) => {
    setOptionProduit(prevOptions => {
      // Check if the option is already in the array
      const exists = prevOptions.find(opt => opt.name === option.name);
  
      if (exists) {
        // If the option exists, remove it from the array
        return prevOptions.filter(opt => opt.name !== option.name);
      } else {
        // If the option doesn't exist, add it to the array
        return [...prevOptions, option];
      }
    });
  }

  const makeReprise = (priceReprise) => {
    setModalRecyclage(false);
    setRecycleProduit(priceReprise);
  }



  const addToCartProduct = () => {
    addToCart({
      'image': product.cover_image,
      'product_id': product.id,
      'link_rewrite': product.link_rewrite,
      'quantity': productQte,
      'options': optionProduit,
      'garanties': optionGarantie,
      'recyclage': recycleProduit,
      'cadeau': cadeauProduit,
      'total': total
    })
  }


console.log(product.active)


  return (
    <>
      <HeaderComponent />

      {modalExtension && (
        <ModalExtensionGarantieComponent closeModal={() => setModalExtension(false)} garanties={product.garanties} onChooseOption={chooseGarantie} />
      )}
      {modalRecyclage && dataModalRecyclage && product.reprises && recycleProduit == '' &&(
        <ModalRecyclerMaterielComponent data={dataModalRecyclage} approveRecyclage={() => makeReprise(product.reprises[0])} closeModal={() => setModalRecyclage(false)} />
      )}
      {modalFinancement && dataModalFinancement &&(
        <ModalFinancementComponent closeModal={() => setModalFinancement(false)} product={product} data={dataModalFinancement} />
      )}

      {
        loading ? <LoadingComponent />
        :
        <div className="container mx-auto">

          {/* Fil d'ariane */}
          <div className="py-6">
            {/* <span>Tous les produits / {product.categorie_name_defaut} / {product.name}</span> */}
          </div>

          
          <div className='flex flex-row mobile:flex-col py-6'>
            <div className='w-3/6 mobile:w-full flex flex-row mobile:flex-col'>
              <div className={`flex md:flex-col items-center md:w-1/6 pr-2 mobile:hidden`}>
              {
                product.images && product.images.length > 1
                  ? product.images.map((element, index) => (
                      <div key={element.id} className='border-[0.5px] border-greySoft my-1'>
                        <img
                        className='h-[60px] w-[60px] cursor-pointer'
                          src={element}
                          alt={'image-'+product.name+'-'+index}
                          onClick={() => setSelectedImage(element)}
                        />
                      </div>
                    ))
                  : null
              }
              {iframes.map((iframe, index) => {
                const videoId = extractYouTubeId(iframe);
                const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`; // URL de la vignette YouTube
                return (
                  <div key={index} className="my-1 relative">
                    <img
                      src={thumbnailUrl}
                      alt={`Video ${index + 1}`}
                      // onClick={() => setSelectedVideoUrl(`https://www.youtube.com/embed/${videoId}`)}
                      className='cursor-pointer h-[60px] w-[60px]'
                    />
                    {/* Make VIDEO PLAY BUTTON svg */}
                    <div className='absolute h-[60px] top-1 left-2' onClick={() => setSelectedImage(changeIframeSize(iframe.toString()))}>
                      <svg className='' width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="12" cy="12" r="10" stroke="black" stroke-width="2" fill="none"/>
                      <polygon points="10,8 16,12 10,16" fill="black"/>
                    </svg>
                    </div>
                  </div>
                );
              })}
              </div>
              <div className='relative w-5/6 mobile:w-full'>
                <div className='absolute top-3 right-2 z-10'>
                  {product.reductions && product.reductions.map((price) => (
                    <span key={price.id} className='bg-[#FA000033] text-[#D90E0E] text-xs rounded-full font-semibold p-2'>
                      {price.reduction_type === 'percentage' ? '-' + parseFloat(price.reduction * 100).toFixed(2) + ' %' : '- ' + toPrice(price.reduction).toString()}
                    </span>
                  ))}
                </div>
                <div>
                  <div className='bg-grey rounded-sm p-3 '>
                    {isIframe(selectedImage) ? 
                    <div style={{ width: '100%', height: '100%' }} dangerouslySetInnerHTML={{ __html: selectedImage }}></div>
                      :
                        loading ?
                        <img className='mix-blend-multiply' src={selectedImage || (product.images && product.images[0])} alt={'produit-' + product.link_rewrite} />
                        :
                      <ImageZoom className={`mix-blend-multiply`} src={selectedImage || product.cover_image} alt={'produit-' + product.link_rewrite} />
                    }
                    
                  </div>
                  <div className='mobile:grid mobile:grid-cols-6 hidden mobile:block mobile:gap-2'>
                  {
                    product.images && product.images.length > 1
                      ? product.images.map((element, index) => (
                          <div key={element.id} className='border-[0.5px] border-greySoft my-1'>
                            <img
                            className='max-h-[60px]'
                              src={element}
                              alt={'image-'+product.name+'-'+index}
                              onClick={() => setSelectedImage(element)}
                            />
                          </div>
                        ))
                      : null
                  }
                  {iframes.map((iframe, index) => {
                    const videoId = extractYouTubeId(iframe);
                    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`; // URL de la vignette YouTube
                    return (
                      <div key={index} className="my-1 relative">
                        <img
                          src={thumbnailUrl}
                          alt={`Video ${index + 1}`}
                          // onClick={() => setSelectedVideoUrl(`https://www.youtube.com/embed/${videoId}`)}
                          className='cursor-pointer max-h-[60px]'
                        />
                        {/* Make VIDEO PLAY BUTTON svg */}
                        <div className='absolute h-[60px] top-1 left-2' onClick={() => setSelectedImage(changeIframeSize(iframe.toString()))}>
                          <svg className='' width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="12" cy="12" r="10" stroke="black" stroke-width="2" fill="none"/>
                          <polygon points="10,8 16,12 10,16" fill="black"/>
                        </svg>
                        </div>
                      </div>
                    );
                  })}
                  
                  </div>
                </div>
                <div className='flex flex-row pt-6'>
                  <img src={window.location.origin + '/images/service-clients 1.png'} alt="" />
                  <div className='pl-2 mobile:text-sm'>
                    <p>Besoin d’aide ? Un conseiller vous répond et <br/> vous guide : <strong><a href="tel:0 805 130 013">0 805 130 013</a></strong></p>
                    <p className='text-xs'>Coût d’un appel local depuis la France</p>
                  </div>
                </div>
              </div>
              
            </div>
            <div className='px-6 mobile:px-2 lg:w-3/6 mobile:py-6'>
              <h1 className='text-3xl mobile:text-xl font-semibold'>{product.name}</h1>
              <div className='grid grid-cols-4 mobile:grid-cols-2 items-center mobile:py-3'>
                <div>
                  <span className='text-greyAccent italic'>Réf: {product.reference}</span>
                </div>
                <div className='text-grey-Accent'>
                  <span className=''>État: <span className='font-semibold'>{product.condition == 'new' ? 'Neuf' : 'Occasion'}</span></span>
                </div>
                
              </div>
              
              <div className='py-4' dangerouslySetInnerHTML={ {__html: cleanDescription} } />

              <div className="md:flex md:flex-row">
                <div className='flex flex-col justify-between'>
                  {product.stock > 0 && product.price_ht > 0 && product.garanties != '' && 
                    <div className='bg-grey px-4 py-4 rounded-lg'>
                      <h2 className='text-xl font-semibold py-2'>Protégez votre investissement !</h2>
                      <div className='grid-cols-3 mobile:grid-cols-1 grid gap-4'>
                      {product.garanties && product.garanties.map((garantie, i) => (
                          <div onClick={() => chooseGarantie(garantie)} className={`border-2 rounded-lg p-2 cursor-pointer ${optionGarantie.find(opt => opt.name === garantie.name) ? 'bg-orange text-white border-black' : 'bg-white text-black border-greySoft' }`}>
                            <p className="text-sm font-bold cursor-pointer">{garantie.name}</p>
                            <p className="font-light text-xs cursor-pointer">{garantie.description}</p>
                            <p className='italic text-right cursor-pointer'>+ {toPrice(garantie.price)}</p>
                        </div>
                      ))}
                      </div>
                    </div>
                  }
                  
                  {product.stock > 0 &&  product.price_ht > 0 && product.options != '' &&
                    <div className='bg-grey p-4 rounded-lg my-2'>
                      <h2 className='text-xl font-semibold py-2'>Choisissez vos options !</h2>
                      <div className='grid-cols-3 mobile:grid-cols-1 grid gap-4'>
                      {product.options && product.options.map((option, i) => (
                          <div onClick={() => setUnsetOptionProduit(option, i)} className={`border-2 rounded-lg p-2 ${optionProduit.find(opt => opt.name === option.name) ? 'bg-orange text-white border-black' : 'bg-white text-black border-greySoft' }`}>
                            <p className="text-sm font-bold">{option.name}</p>
                            <p className="font-light text-xs">{option.description}</p>
                            <p className='italic text-right'>+ {toPrice(option.price)}</p>
                        </div>
                      ))}
                      </div>
                    </div>
                  }

                  {product.stock > 0 &&  product.price_ht > 0 && product.cadeaux != '' &&
                    <div className='bg-grey p-4 rounded-lg my-2'>
                      <h2 className='text-xl font-semibold py-2'>Offre spéciale du mois</h2>
                      <div className='grid-cols-2 mobile:grid-cols-1 grid gap-4'>
                      {product.cadeaux && product.cadeaux.map((cadeau, i) => (
                          <div onClick={() => setCadeauProduit(cadeau)} className={`border-2 rounded-lg p-2 ${cadeau.name === cadeauProduit.name ? 'bg-orange text-white border-black' : 'bg-white text-black border-greySoft' }`}>
                            <p className="text-sm font-bold">{cadeau.name}</p>
                            <p className="font-light text-xs">{cadeau.description}</p>
                        </div>
                      ))}
                      </div>
                    </div>
                  }
                  
                  <div className="grid grid-cols-2 mobile:grid-cols-1 items-end py-4">
                  

                    
                    <div className=''>
                    {product.delivery_in_stock != '' && product.delivery_in_stock != null &&
                      <span className='inline-flex items-center bg-grey p-2 rounded-xl text-xs'>
                        <img src={window.location.origin +'/images/delivery-24h.png'} alt="" />
                        <span className='pl-3 font-bold text-black'> {product.delivery_in_stock}</span>
                      </span>
                      }
                      {product.price_ht > 300 && product.stock > 0 &&
                      <div className='py-2'>
                        <span className='inline-flex items-center bg-grey p-2 rounded-xl text-xs'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                          <span className='pl-3 font-bold text-black'> Livraison gratuite</span>
                        </span>
                      </div>}
                      {product.stock > 0 &&
                      <div>
                        <span className='inline-flex items-center bg-greenBg p-2 rounded-xl text-xs'>
                          <svg width="20" height="20" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="7" cy="7.5" r="4" fill="#79C399"/>
                            <circle cx="7" cy="7.5" r="5.5" stroke="#37D677" strokeOpacity="0.15" strokeWidth="3"/>
                          </svg>
                          <span className='pl-3 font-semibold text-greenAccent'> En stock</span>
                        </span>
                      </div>
                      }
                      {product.stock === 0 &&
                      <div>
                        <span className='inline-flex items-center bg-[#FE0000] p-2 rounded-xl text-xs'>
                        <svg width="20" height="20" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="7" cy="7.5" r="5.5" fill="white" stroke="#F7C6B7" stroke-width="3"/>
                          </svg>
                          <span className='pl-3 font-semibold text-white'> Rupture de stock</span>
                        </span>
                      </div>
                      }
                      <div className='py-6'>
                      {product.old_price_ht > 0 && product.old_price_ht && product.old_price_ht != product.price_ht && (
                        <div className='text-[#A9A9A9] line-through text-xl'>{toPrice(product.old_price_ht)} HT</div>
                      )}
                      {product.price_ht > 0 && <div className='text-orange text-4xl font-semibold'>{toPrice(product.price_ht)}<span className='text-sm text-greyAccent pl-2'>HT</span></div>}
                      {product.price_ttc > 0 && <div className='text-greyAccent font-medium text-xl'>{toPrice(product.price_ttc)} TTC</div>}
                      </div>
                      {
                        product.stock == 0 &&
                        <div className='py-4'>
                          <h4 className='font-semibold text-lg'>Vous souhaitez être informé ? </h4>
                          <p>Recevez un mail dès le produit de nouveau en stock.</p>
                          <input type="text" className='bg-grey border border-greySoft rounded-md py-2 px-6 w-full my-3' placeholder='Votre email ' />
                          <div className='flex justify-end'>
                            <button className="py-2 px-4 text-sm rounded-full bg-green text-white text-center font-semibold border-4 border-[#00B67A] flex  items-center justify-center">
                              <svg className='mr-2' width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.8334 8H3.16675" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="bevel"/>
                                <path d="M8.5 13.3333V2.66663" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="bevel"/>
                              </svg>
                              <span>Envoyer</span>
                            </button>
                          </div>
                        </div>
                      }
                      
                    </div>
                    

                    <div className=''>
                     {product.stock > 0 && product.price_ht > 0 &&
                     <>
                      <div>
                        <div className='text-black text-3xl font-light'> TOTAL :  <span className='font-semibold'>{toPrice(total)}</span> <span className='text-sm '>HT</span></div>
                      </div>
                      <div className="flex flex-row gap-2 py-3">
                            <div className="w-1/3 border-2 rounded-xl border-black bg-grey p-3 flex flex-row">
                              <input type="number" className='w-1/2 bg-grey text-right' value={productQte} onChange={(e) => setProductQte(e.target.value)} /> 
                              <span className='pl-2'>PCS</span>
                            </div>
                            <button onClick={addToCartProduct} className="w-2/3 bg-green text-white text-center font-semibold border-4 border-[#00B67A] rounded-xl flex  items-center justify-center">
                              <svg className='mr-2' width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.8334 8H3.16675" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="bevel"/>
                                <path d="M8.5 13.3333V2.66663" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="bevel"/>
                              </svg>
                              <span>Ajouter au panier</span>
                            </button>
                        </div>
                        </>
                        }
                        {product.stock > 0 && product.price_ht > 0 && product.financements &&product.financements.map((financement, i) => (
                          <div className="flex flex-row gap-2">
                            <div className="w-1/3">
                            </div>
                            <button onClick={() => handleModalFinancement(financement)} className="w-2/3 border font-semibold border-greySoft rounded-xl flex items-center justify-center py-2 text-xs">
                              <svg className='mr-2' width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.13333 1H9.86667C9.90203 1 9.93594 1.01405 9.96095 1.03905C9.98595 1.06406 10 1.09797 10 1.13333V9H6V1.13333C6 1.09797 6.01405 1.06406 6.03905 1.03905C6.06406 1.01405 6.09797 1 6.13333 1V1Z" stroke="#E6704B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6 9H2.13333C2.09797 9 2.06406 8.98595 2.03905 8.96095C2.01405 8.93594 2 8.90203 2 8.86667L2 4.46667C2 4.43131 2.01405 4.39739 2.03905 4.37239C2.06406 4.34738 2.09797 4.33333 2.13333 4.33333L6 4.33333L6 9Z" stroke="#E6704B" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.8667 9H10V5.66667H13.8667C13.902 5.66667 13.9359 5.68071 13.9609 5.70572C13.986 5.73072 14 5.76464 14 5.8V8.86667C14 8.90203 13.986 8.93594 13.9609 8.96095C13.9359 8.98595 13.902 9 13.8667 9V9Z" stroke="#E6704B" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                              <span>Simuler un financement </span>
                            </button>
                          </div>
                          ))}
                    </div>
                  </div>
                  {
                    product.price_ht == 0 &&
                    <div className='py-4'>
                      <h4 className='font-semibold text-lg'>Vous souhaitez un devis pour ce matériel  ? </h4>
                      <p className='text-sm'>Renseignez vos informations et recevez votre devis dans les 48h</p>
                      <div class="grid grid-cols-2 gap-3 mobile:grid-cols-1">
                        <Input label='Nom' type='text' />
                        <Input label='Prénom' type='text' />
                        <Input label='E-mail' type='text' />
                        <Input label='Téléphone' type='text' />
                        <Input label="Nom de l'entreprise" type='text' />
                        <Input label="Adresse postale" type='text' />
                      </div>
                      <div className='flex justify-end'>
                        <button className="py-2 px-4 text-sm rounded-full bg-green text-white text-center font-semibold border-4 border-[#00B67A] flex  items-center justify-center">
                          <svg className='mr-2' width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.8334 8H3.16675" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="bevel"/>
                            <path d="M8.5 13.3333V2.66663" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="bevel"/>
                          </svg>
                          <span>Envoyer</span>
                        </button>
                      </div>
                    </div>
                      }

                  {product.stock > 0 && product.price_ht > 0 && product.reprises && recycleProduit && product.reprises.map((reprise, i) => (
                    recycleProduit != '' ? 
                    <div className=''>
                      <button className='bg-green text-white rounded-lg p-3 font-bold'>Reprise de votre ancien matériel  - {toPrice(reprise.price)}</button>
                    </div>
                    :
                    <div>
                      <button className='inline-flex items-center text-xs font-bold rounded-xl border-orange border p-3' onClick={() => handleModalRecyclage(reprise)}>
                      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.47339 5.66661C2.00667 4.38607 2.96718 3.32986 4.19147 2.67771C5.41576 2.02556 6.82819 1.81777 8.18843 2.08968C9.54867 2.3616 10.7727 3.09643 11.6522 4.16913C12.5316 5.24183 13.0123 6.58613 13.0123 7.97328C13.0123 9.36043 12.5316 10.7047 11.6522 11.7774C10.7727 12.8501 9.54867 13.585 8.18843 13.8569C6.82819 14.1288 5.41576 13.921 4.19147 13.2688C2.96718 12.6167 2.00667 11.5605 1.47339 10.2799" stroke="#E6704B" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1 3.33325V5.86659C1 5.90195 1.01405 5.93586 1.03905 5.96087C1.06406 5.98587 1.09797 5.99992 1.13333 5.99992H3.66667" stroke="#E6704B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      <span className='pl-2'>Recycler mon ancien matériel {reprise.name}</span> &nbsp; &nbsp; <span className='text-red'>- {toPrice(reprise.price)}</span>
                    </button>
                  </div>
                  ))
                  }

                </div>
              </div>
              {/* <div className="bg-red rounded-md p-2 text-white mt-8 text-sm flex flex-row justify-between items-center">
                <div className='flex flex-row w-4/5'>
                  <img className='pr-2' src={window.location.origin + '/images/Warning.png'} alt="" /> Ce produit nécessite l’achat de produits complémentaires.  
                </div>
                <button className='bg-white rounded-lg font-bold text-black text-xs p-3'>Voir les produits</button>
              </div> */}
              
            </div>
          </div>
         <div className='py-24 mobile:py-12'>
            <div className='grid grid-cols-4 mobile:grid-cols-1 gap-4'>
                {
                  product.description && (
                    <div>
                      <div><h4 className='font-semibold py-2 cursor-pointer' onClick={() => setTab(0)}>Description</h4> </div>
                      <div className={`h-0.5 ${tab == 0 ? 'bg-orange' : 'bg-grey'}`}></div>
                    </div>
                  )
                }
                {
                  product.features != '' && (
                    <div>
                      <div><h4 className='font-semibold py-2 cursor-pointer' onClick={() => setTab(1)}>Caractéristiques</h4></div>
                      <div className={`h-0.5 ${tab == 1 ? 'bg-orange' : 'bg-grey'}`}></div>
                    </div>
                  )
                }
                {
                 product.attachments && product.attachments.length > 0 && (
                  <div>
                    <div><h4 className='font-semibold py-2 cursor-pointer' onClick={() => setTab(2)}>Documentation <span className='rounded-full text-orange bg-[#F4F8EC] px-4 ml-2'>{product.attachments.length}</span> </h4></div>
                    <div className={`h-0.5 ${tab == 2 ? 'bg-orange' : 'bg-grey'}`}></div>
                  </div>
                 )
                }

                {
                  product.associated_products && product.associated_products.length > 0 && (
                    <div>
                      <div><h4 className='font-semibold py-2 cursor-pointer' onClick={() => setTab(3)}>Consommables</h4></div>
                      <div className={`h-0.5 ${tab == 3 ? 'bg-orange' : 'bg-grey'}`}></div>
                  </div>
                  )
                }
              
            </div>
            <div className='py-6 '>
              {tab === 0 && product.description ? <div className='py-4' dangerouslySetInnerHTML={ {__html: product.description} } /> : null}
              {tab === 1 && product.features ? 
                Object.entries(product.features).map(([key, value], index) => (
                  <div key={index} className={`grid grid-cols-2 gap-1 text-black font-medium py-2 mobile:text-sm  ${index % 2 == 0 ? 'bg-[#F5F5F5]' : 'bg-white'} `} >
                    <span className='px-2'>{key}</span>
                    <span>{value.value}</span>
                  </div>
                ))
              : null
              }
              <div className='grid grid-cols-2 mobile:grid-cols-1 gap-1 justify-center text-black font-medium'>
              {tab === 2 && 
                  product.attachments.map((element, index) => ( 
                    <div key={index} className={` text-black font-medium py-2 `} >
                      <p className='font-medium py-2'>{element.file_name}</p>
                      <a href={element.link} className='border rounded-lg text-xs px-4 py-2 border-greyAccent'>Télécharger le document ({element.size})</a>
                    </div>
                  ))
              }
              </div>
              <div>
                
                <div class="mobile:hidden">
                  {tab === 3 && product.associated_products.map((associatedProduct, index) => (
                      <ProductCardComponent key={index} product={associatedProduct} typeView='list' />
                  ))}
                </div>
                <div class="hidden mobile:block">
                  {tab === 3 && product.associated_products.map((associatedProduct, index) => (
                      <ProductCardComponent key={index} product={associatedProduct} typeView='grid' />
                  ))}
                </div>
              </div>
            </div>
         </div>
        </div>
        
      }
      {loadingCat == false && productsCat &&
        <div className='bg-grey'>
          <div className="container mx-auto py-12">
            <div className='text-center md:text-left font-semibold text-2xl mobile:text-xl'><h2>Produits dans la même catégorie : </h2></div>
            <div className="py-6">
                  <SliderProductComponent products={productsCat} />
              </div>
          </div>
        </div>
      }
      <FooterComponent />
    </>
  )
}

export default ProductPage