import React, { useState } from 'react'
import HeaderComponent from '../components/headerComponent'
import { useAuthState } from '../store/store';
import { Link, useNavigate } from 'react-router-dom';
import FooterComponent from '../components/footerComponent';

const LoginPage = () => {
  const [passwordView, setPasswordView] = useState(false);
  const [error, setError] = useState('');
  const {login, getUser, loadingUser} = useAuthState();
  const navigate= useNavigate();


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async  () => {
    const loginInfo = await login(email, password);
    if(!loginInfo) {
      setError('Votre E-mail ou mot de passe est incorrect');
    } else {
      setError('')
      navigate('/mon-compte/information');
    }
  }


  return (
    <>
        <HeaderComponent />
        <div className='flex justify-center'>
            <div className='bg-grey flex flex-col items-center rounded-lg shadow-lg lg:w-2/6 p-8 my-12'>
                <h1 className='font-bold text-2xl pb-8'>Connexion</h1>
                {error != '' && (
                  <div class="bg-red border-l-4 border-orange-500 text-orange-700 p-4 ">
                    <p class="font-bold text-white">Erreur de connexion</p>
                    <p className='text-white'>{error}</p>
                  </div>
                )}
                <div className='w-3/4'>
                  <div className='my-2 py-1 w-full px-6 rounded-md border border-greyAccent bg-white'>
                    <label htmlFor="" className='text-greyAccent text-xs'>E-mail</label>
                    <input className='w-full text-black font-semibold' onChange={(e) => setEmail(e.target.value)} type="text" placeholder="" id="" />
                  </div>
                  <div className='my-2 py-1 w-full px-6 rounded-md border border-greyAccent bg-white'>
                    <label htmlFor="" className='text-greyAccent text-xs'>Mot de passe</label>
                    <div className="flex flex-row justify-between">
                      <input className='w-full text-black font-semibold' onChange={(e) => setPassword(e.target.value)} type={passwordView ? 'text': 'password'} placeholder="" id="" />
                      {passwordView ? (
                        <svg onClick={() => setPasswordView(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                      ) : (
                        <svg onClick={() => setPasswordView(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                        </svg>
                      )}
                    </div>
                  </div>
                  <div className='text-sm'>
                    Mot de passe oublié ?
                  </div>
                  <button onClick={() => handleLogin()} className='w-full bg-black text-white py-3 rounded-lg font-semibold my-8'>
                    Se connecter
                  </button>
                  <Link to={'/creer-mon-compte'}>
                    <div className='text-center text-sm'>
                      Créer un compte
                    </div>
                  </Link>
                </div>
            </div>
        </div>
       <FooterComponent />
    </>
  )
}

export default LoginPage