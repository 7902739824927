import React, { useState } from 'react'
import HeaderComponent from '../components/headerComponent'
import { CartCrossSelling, CartItem } from '../components/cartComponent';
import { useAuthState, useCartState } from '../store/store';
import { getImage, toPrice, validateEmail } from '../utils/someFunctions';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../components/html/input';
import { DD_MM_formatter, creditCardFormatter, cvcFormatter, siretFormatter } from '../utils/formatters';
import DropzoneComponent from '../components/html/Dropzone';
import DropzoneWithPreview from '../components/html/Dropzone';
import PaypluForm from '../components/PayplugFormComponent';
import CountrySelect from '../components/html/CountrySelect';
import RibFormComponent from '../components/paymentMethod/RibFormComponent';
import { toast } from 'react-toastify';
import MonCompteDropDown from '../components/accountComponents/MonCompteDropDown';
import AdresseAccountComponent from '../components/accountComponents/adresseAccountComponent';
import PaypalComponent from '../components/paymentMethod/PaypalComponent';
import { create } from 'filepond';
import FooterComponent from '../components/footerComponent';
import Slider from 'react-slick';


export const CheckoutPage = () => {

  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const { getCartNumberItems, getCart, removeCart, getTotalCartHT, getTotalCartTVA, getTotalCartTTC, setPromoCode, getPromoCode, setLivraison, getLivraison } = useCartState();
  const cartItems = getCart();

  const {getUser, isAuthenticated} = useAuthState();

  // Champs pour les infos de commande
  const [shippingMethod, setShippingMethod] = useState('');


  const configSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const chooseShippingMethod = (method) => {
    if(getTotalCartHT() < 300 && method == 'Livraison') {
      setLivraison(15);
    }
    if(method == 'Retrait') {
      setLivraison(null);
    }
    setShippingMethod(method);
  }


  // Champ du formulaire
  const [form, setForm] = useState({
    'delivery_different_billing': false,
    'pays_delivery': 'fr',
    'pays_billing': 'fr',
  });

  // Choix des modes de paiements
  const [paymentMethod, setPaymentMethod] = useState('');

  const [promoCodeValue, setPromoCodeValue] = useState('');
  const checkPromoCode = async () => {

    // const { getTotalCartHT } = useCartState();
    // Vérifier si le code promo est valide en faisant une requete
    try {

      var formdata = new FormData();

      formdata.append('code', promoCodeValue);
      formdata.append('total_cart', getTotalCartHT());

      const response = await fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/orders/CheckPromoCode.php', {
        method: 'POST',
        body: formdata
      });

      console.log(response)

      if(response.ok) {
        const data = await response.json();
        console.log(data)
        if(data.active == 1) {
          console.log('activvve')
          setPromoCode(data.reduction_amount, promoCodeValue);
        }
      }
    } catch (error) {
      
    }
  }

  // Formulaire client
  const setFormValue = (key, value) => {
    setForm({
      ...form,
      [key]: value
    })
  };

  // Formulaire client
  const getFormValue = (key) => {
    return form[key];
  }

  const addStep = () => {
    setStep(step + 1);
    window.scrollTo(0, 0);
  }

  // Regles de validation pour les étapes
  const nextSetp = () => {
    if (step == 1) {
      addStep();
    } else if (step == 2) {
      if (isAuthenticated()) {
        if(form.id_address) {
          addStep();
        } else {
          toast.error('Veuillez choisir une adresse de livraison');
          return;
        }
      } else {
        if (form.nom && form.prenom && form.telephone && form.entreprise && form.adresse_delivery && form.ville_delivery && form.pays_delivery && form.code_postal_delivery && validateEmail(form.email)) {
          addStep();
        } else {
          toast.error('Veuillez vérifier tous les champs du formulaire');
          return;
        }
      }
    } else if (step == 3) {
      if (shippingMethod != '') {
        addStep();
      } else {
        toast.error('Veuillez choisir un mode de livraison');
        return;
      }
    }
    
    addStep();    
  }



  return (
    <>
      <div className='hidden md:block shadow-lg'>
        <div className='container mx-auto'>
            <div className="py-4 border-b border-grey">
                <div className="flex flex-wrap justify-between">
                    <div className='w-1/3'>
                        Besoin d’aide ? Appelez-nous au <a className='text-orange font-semibold' href="tel:01 48 58 26 26">01 48 58 26 26</a>
                    </div>
                    <div className='font-bold w-1/3 text-center'>Livraison gratuite dès 300€HT d'achat</div>
                    <div className='flex flex-wrap justify-around w-1/3'>
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center py-3">
                <div className='w-1/4 flex justify-center'><Link to="/"><img className='w-full' src={'https://laboutiquedutraceur-16-to-8.mi2a-innovation.com/api/images/general/header?ws_key=aviel_S38M2jgt423n2tUX9qSHDj7nnX'} alt="logo-la-boutique-du-traceur" /></Link></div>
                <MonCompteDropDown />
            </div>
        </div>
    </div>
    {/* Menu Mobile */}
    <div className='block md:hidden'>
        <div className="container mx-auto">
            <div className="flex flex-row items-center">
                <div className='w-1/5'><img src="/images/menu-icon-mobile.svg" alt="" /></div>
                <div className='w-3/5'><img src={'https://laboutiquedutraceur-16-to-8.mi2a-innovation.com/api/images/general/header?ws_key=aviel_S38M2jgt423n2tUX9qSHDj7nnX'} alt="logo-la-boutique-du-traceur" /></div>
                <div className='w-1/5 flex flex-row justify-around'>
                    <div><img src="/images/icon-user.png" alt="" /></div>
                    <div><img src="/images/icon-panier.svg" alt="" /></div>
                </div>
            </div>
        </div>
    </div>
      <div className='container mx-auto'>
        <div className="relative py-12">
          <div class="flex justify-between items-center mobile:text-[10px]">
            <div class="flex flex-col items-center">
              <div class={`h-12 w-12 text-white mobile:h-6 mobile:w-6 ${step == 1 ? 'bg-orange  shadow-xl': 'bg-[#9A9191] '} rounded-full flex items-center justify-center mb-2`}>
                <span class="text-lg font-bold">1</span>
              </div>
              <span className={`${step == 1 ? 'font-bold text-orange' : 'text-[#9A9191]'}`}>Récapitulatif</span>
            </div>
            <div class="flex flex-col items-center">
              <div className={`h-12 w-12 text-white mobile:h-6 mobile:w-6 ${step == 2 ? 'bg-orange  shadow-xl': 'bg-[#9A9191] '} rounded-full flex items-center justify-center mb-2`}>
                <span class="text-lg font-bold">2</span>
              </div>
              <span className={`${step == 2 ? 'font-bold text-orange' : 'text-[#9A9191]'}`}>Informations</span>
            </div>
            <div class="flex flex-col items-center">
              <div className={`h-12 w-12 text-white mobile:h-6 mobile:w-6 ${step == 3 ? 'bg-orange  shadow-xl': 'bg-[#9A9191] '} rounded-full flex items-center justify-center mb-2`}>
                <span class="text-lg font-bold">3</span>
              </div>
              <span className={`${step == 3 ? 'font-bold text-orange' : 'text-[#9A9191]'}`}>Mode d’expédition</span>
            </div>
            <div class="flex flex-col items-center">
              <div className={`h-12 w-12 text-white mobile:h-6 mobile:w-6 ${step == 4 ? 'bg-orange  shadow-xl': 'bg-[#9A9191] '} rounded-full flex items-center justify-center mb-2`}>
                <span class="text-lg font-bold">4</span>
              </div>
              <span className={`${step == 4 ? 'font-bold text-orange' : 'text-[#9A9191]'}`}>Paiement</span>
            </div>
          </div>
          <div className="w-[92%] mobile:left-[8%] mobile:w-[86%] left-[5%] right-[5%] h-0.5 bg-greySoft absolute top-[43%] -z-10 "></div>
        </div>
        <div className="grid grid-cols-2 mobile:grid-cols-1">
          <div>
            {step == 1 && (
              <div>
               <Slider {...configSlider} >
                {cartItems.map((item) => (
                  <CartCrossSelling item={item} />
                ))}
                </Slider>
              </div>
            )}
            {step == 2 && (
              <div>
                <h1 className='text-2xl text-black font-semibold'>Information de facturation</h1>
                <p className='text-sm text-greyAccent py-1'>Veuillez saisir vos informations pour la facturation de votre commande</p>
                
                {isAuthenticated() == false &&
                  <>
                  <div className="">
                    <p className=' text-greyAccent py-1'>Déjà client ?  Cliquez sur le lien suivant pour vous connecter : <span className='font-medium underline'><Link to={'/login'}>Se connecter</Link></span> </p>
                  </div>
                  <div className='grid grid-cols-2 mobile:grid-cols-1 gap-3'>
                    <Input label={'Nom'}  value={getFormValue('nom')} valueChanged={(v) => setFormValue('nom', v)} />
                    <Input label={'Prenom'}  value={getFormValue('prenom')} valueChanged={(v) => setFormValue('prenom', v)} />
                    <Input label={'Email'}  value={getFormValue('email')} valueChanged={(v) => setFormValue('email', v)} />
                    <Input label={'Telephone'} value={getFormValue('telephone')} valueChanged={(v) => setFormValue('telephone', v)} />
                    <Input label={'Siret'}  formatter={siretFormatter} value={getFormValue('siret')} valueChanged={(v) => setFormValue('siret', v)} />
                    <Input label={'Nom de l\'entreprise'}  value={getFormValue('entreprise')} valueChanged={(v) => setFormValue('entreprise', v)} />
                    
                    
                    
                    {getFormValue('delivery_different_billing') ? <> <div>Adresse de livraison</div><div></div></>: null}

                    <Input label={'Adresse'} value={getFormValue('adresse_delivery')} valueChanged={(v) => setFormValue('adresse_delivery', v)} />
                    <Input label={'Ville'}  value={getFormValue('ville_delivery')} valueChanged={(v) => setFormValue('ville_delivery', v)} />
                    <CountrySelect onChange={(e) => setFormValue('pays_delivery', e.target.value)} value={getFormValue('pays_delivery')} />
                    <Input label={'Code Postal'}  value={getFormValue('code_postal_delivery')} valueChanged={(v) => setFormValue('code_postal_delivery', v)} />
                    

                    {getFormValue('delivery_different_billing') == true ? <> <div>Adresse de facturation</div><div></div> 
                    <Input label={'Adresse'} value={getFormValue('adresse_billing')} valueChanged={(v) => setFormValue('adresse_billing', v)} />
                    <Input label={'Ville'}  value={getFormValue('ville_billing')} valueChanged={(v) => setFormValue('ville_billing', v)} />
                    <CountrySelect onChange={(e) => setFormValue('pays_billing', e.target.value)} value={getFormValue('pays_billing')} />
                    <Input label={'Code Postal'}  value={getFormValue('code_postal_billing')} valueChanged={(v) => setFormValue('code_postal_billing', v)} />
                    </>: null}

                    <div className='my-6 py-3 w-full px-6 rounded-md border border-greyAccent bg-white flex flex-row gap-3'>
                      <input checked={getFormValue('delivery_different_billing')} onChange={() => setFormValue('delivery_different_billing', !getFormValue('delivery_different_billing'))} type="checkbox" className='accent-black' name="delivery" id="delivery" />
                      <label htmlFor="delivery" className='text-sm'>Livrer à une adresse différente ?</label>
                    </div>
                  </div>
                  </>
                }

                {isAuthenticated() == true && 
                  <div className='py-4'>
                    <AdresseAccountComponent ischeckout={true} onAdressSelected={(id) => setFormValue('id_address', id)} />
                  </div>
                }

                
                <div className="flex justify-end my-4">
                    <button className="bg-green font-semibold uppercase text-white rounded-lg px-4 py-2" onClick={() => nextSetp()}>Suivant</button>
                </div>
              </div>
            )}
            {step == 3 && (
              <div>
                <h1 className='text-3xl lg:text-2xl text-black font-semibold'>Information de livraison</h1>
                <p className='text-sm text-greyAccent py-3'>Veuillez saisir vos informations pour la livraison de votre commande et le mode d’expédition souhaité</p>
                <div className="bg-[#F9F9F9] border p-3 rounded-lg border-greySoft flex flex-row my-4 ">
                  <input type="radio" name="shipping_method" onChange={(e) => chooseShippingMethod(e.target.value)} value='Retrait' id="shipping_method_retrait" />
                  <label htmlFor="shipping_method_retrait" className='pl-4 mobile:pl-2 mobile:text-sm flex flex-row items-center justify-between w-full  font-semibold'>
                    <div>
                      <span>Retrait en boutique</span>
                      <span className='text-green ml-4'>GRATUIT</span>
                      <span className=' ml-2'>Disponible dans 2 heures</span></div>
                    <div><img src={getImage('Pickup.png')} className='w-6' /></div>
                  </label>
                </div>
                <div className="bg-[#F9F9F9] border p-3 rounded-lg border-greySoft flex flex-row my-4 ">
                  <input type="radio" name="shipping_method" onChange={(e) => chooseShippingMethod(e.target.value)} value='Livraison' id="shipping_method_livraison" />
                  <label htmlFor="shipping_method_livraison" className='pl-4 mobile:pl-2 mobile:text-sm flex flex-row items-center justify-between w-full  font-semibold'>
                    <div>
                      <span>Livraison</span>
                      <span className='text-green ml-4'>{getTotalCartHT() > 300 ? 'GRATUIT' : '+'+toPrice(15)}</span>
                      <span className=' ml-2'>Livré en 3 à 5 jours ouvrés</span></div>
                    <div></div>
                  </label>
                </div>
                {/* <h2 className='text-3xl font-bold'>Installez votre matériel sereinement</h2> */}
                <div className="flex justify-end my-4">
                    <button className="bg-green font-semibold uppercase text-white rounded-lg px-4 py-2" onClick={() => nextSetp()}>Suivant</button>
                </div>
              </div>
            )}
            {step == 4 && (
              <div>
                <h1 className='text-3xl lg:text-2xl text-black font-semibold'>Paiement de votre commande</h1>
                <p className='text-sm text-greyAccent py-3'>Veuillez choisir le mode de paiement pour votre commande</p>
                <div className="bg-[#F9F9F9] border p-3 rounded-lg border-greySoft my-4 ">
                  <div className='flex flex-row'>
                    <input type="radio" name="payment_method" onChange={(e) => setPaymentMethod(e.target.value)} value='cb' id="payment_method_cb" />
                    <label htmlFor="payment_method_cb" className='pl-4 mobile:pl-2 mobile:text-sm flex flex-row items-center justify-between w-full  font-semibold'>
                      <div><span>Carte bancaire</span></div>
                      <div><img src={getImage('Visa.png')} /></div>
                    </label>
                  </div>
                  {paymentMethod == 'cb' &&(
                    <div>
                      <PaypluForm form={form} shippingMethod={shippingMethod} total={getTotalCartTTC()} />
                    </div>
                  )}
                </div>
                <div className="bg-[#F9F9F9] border p-3 rounded-lg border-greySoft my-4 ">
                  <div className='flex flex-row justify-between w-full'>
                    <input type="radio" name="payment_method" onChange={(e) => setPaymentMethod(e.target.value)} value='paypal' id="payment_method_paypal" />
                    <label htmlFor="payment_method_paypal" className='pl-4 mobile:pl-2 mobile:text-sm flex flex-row items-center justify-between w-full  font-semibold'>
                      <div><span>PayPal</span></div>
                      <div><img src={getImage('Paypal.png')} /></div>
                    </label>
                  </div>
                  {
                    paymentMethod == 'paypal' && 
                    <div className='py-5'>
                      <PaypalComponent form={form} shippingMethod={shippingMethod} />
                    </div>
                  }
                </div>
                <div className="bg-[#F9F9F9] border p-3 rounded-lg border-greySoft my-4 ">
                  <div className="flex flex-row">
                    <input type="radio" name="payment_method" onChange={(e) => setPaymentMethod(e.target.value)} value='mandat_administratif' id="payment_method_mandat_administratif" />
                    <label htmlFor="payment_method_mandat_administratif" className='pl-4 mobile:pl-2 mobile:text-sm flex flex-row items-center justify-between w-full  font-semibold'>
                      <div><span>Mandat administratif</span></div>
                      <div><img src={getImage('mandat-administratif.png')} className='w-10' /></div>
                    </label>
                  </div>
                  {paymentMethod == 'mandat_administratif' &&(
                    <div className='pt-4'>
                      <DropzoneWithPreview form={form} shippingMethod={shippingMethod} />
                    </div>
                  )}
                </div>

                <div className="bg-[#F9F9F9] border p-3 rounded-lg border-greySoft my-4 ">
                  <div className="flex flex-row">
                    <input type="radio" name="payment_method" onChange={(e) => setPaymentMethod(e.target.value)} value='virement_bancaire' id="payment_method_virement" />
                    <label htmlFor="payment_method_virement" className='pl-4 flex flex-row font-semibold'>
                      Virement bancaire
                    </label>
                  </div>
                    {paymentMethod == 'virement_bancaire' &&(
                      <div>
                        <RibFormComponent form={form} shippingMethod={shippingMethod}></RibFormComponent>
                      </div>
                    
                    )}
                </div>
                <div className="flex justify-end my-4">
                </div>
              </div>
            )}
          </div>
          <div className='lg:p-6'>

            <div className="border-greySoft border rounded-lg p-6 h-max">
              <h2 className='text-2xl lg:text-3xl font-semibold'>Récapitulatif ({getCartNumberItems()} produit{getCartNumberItems() > 1 ? 's' :''})</h2>
              <div>
              {step == 1 && cartItems.map((item) => (
                  <CartItem id={item.product_id} allowQte={true} key={item} item={item} />
              ))}
              {step > 1 && cartItems.map((item) => (
                  <CartItem id={item.product_id} allowQte={false} key={item} item={item} />
              ))}
              </div>
              <div className="grid grid-cols-2 font-semibold">
                <div>
                    {getPromoCode() && <p className="py-2">Code promo {getPromoCode()['code']}</p>}
                    {getLivraison() && <p className="py-2">Livraison</p>}
                    <p className="py-2">Sous-total (HT)</p>
                    <p className="py-2">TVA 20 %</p>
                </div>
                <div className="text-right font-semibold">
                    {getPromoCode() && <p className="py-2"> - {toPrice(getPromoCode()['valueReduction'])}</p>}
                    {getLivraison() && <p className="py-2">{toPrice(getLivraison())}</p>}
                    <p className="py-2">{toPrice(getTotalCartHT())}</p>
                    <p className="py-2">{toPrice(getTotalCartTVA())}</p>
                </div>
              </div>
              {step == 1 &&(
              <div className='bg-grey py-2 px-4 rounded-lg border-greySoft border flex flex-row my-6'>
                <input type="text" value={promoCodeValue} onChange={(e) => setPromoCodeValue(e.target.value)} className='w-full bg-grey' placeholder='Appliquer un code promo' />
                <button className='text-black font-bold' onClick={() => checkPromoCode()}>Appliquer</button>
              </div>
              )}
              <div className="grid grid-cols-2">
                <div>
                    <p className="font-semibold py-2">TOTAL TTC</p>
                </div>
                <div className="text-right">
                    <p className="font-semibold py-2 text-xl text-orange">{toPrice(getTotalCartTTC())} TTC</p>
                </div>
              </div>
            </div>
            {step == 1 &&(
            <div className="pt-6 flex justify-center px-6">
                <button className="bg-green font-semibold text-white w-full rounded-lg px-4 py-2 flex flex-row items-center justify-center" onClick={() => nextSetp()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M4.33651 1.33325H11.6699C11.8797 1.33325 12.0773 1.43205 12.2032 1.59992L14.0032 3.99992V13.9999C14.0032 14.3681 13.7047 14.6666 13.3365 14.6666H2.66984C2.30165 14.6666 2.00317 14.3681 2.00317 13.9999V3.99992L3.80317 1.59992C3.92908 1.43205 4.12667 1.33325 4.33651 1.33325ZM12.3365 3.99992L11.3365 2.66659H4.66984L3.66984 3.99992H12.3365ZM6.00317 6.66659H4.66984V7.99992C4.66984 9.84085 6.16223 11.3333 8.00319 11.3333C9.84412 11.3333 11.3365 9.84085 11.3365 7.99992V6.66659H10.0032V7.99992C10.0032 9.10452 9.10772 9.99992 8.00319 9.99992C6.89859 9.99992 6.00317 9.10452 6.00317 7.99992V6.66659Z" fill="white"/>
                    </svg>
                    <span className="pl-3">Valider ma commande</span>
                </button>
            </div>
            )}
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  )
}
