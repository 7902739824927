import React, { useState } from 'react'
import { toPrice } from '../../utils/someFunctions';
import { Link } from 'react-router-dom';


const SearchComponent = ({placeholder, filters = '', maxResutlt = '3', overloadQuery = ''}) => {

    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const onSearch = async (e) => {
        const query = e.target.value;
        setQuery(query);
        var newQuery = query;
        // Permet de faire une recherche en cachant la recherche prévu 
        if(overloadQuery){
            newQuery = overloadQuery+' '+query;
        } else {
            newQuery = query; 
        }

        if (query.length > 1) { // Effectuer la recherche seulement si la requête est suffisamment longue
        setLoading(true);
        try {
            const response = await fetch(`https://eu1-search.doofinder.com/6/051d0cc5043e2122af59babbe81847b9/_search?query=${newQuery}&rpp=${maxResutlt}${filters}`, {
            headers: {
                'Authorization': 'Bearer eu1-ac41fc7ca4056dd56ce7161c86b29740445ca901' // Remplacez par votre clé API
            },
            });

            if (!response.ok) {
            throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setResults(data.results);
            console.log('Search results:', data.results);
        } catch (error) {
            console.error('Error fetching search results:', error);
        } finally {
            setLoading(false);
        }
        } else {
        setResults([]);
        }
  };

  return (
    <div className='relative'>
        <div className='flex flex-row bg-grey border-greyAccent border px-3 py-2 rounded-lg items-center  relative'>
            <input onChange={(e) => onSearch(e)} className='w-full bg-grey visible' type="text" placeholder={placeholder} />
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.0} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>
        </div>

        {results.length > 0 && (
            <div className='absolute z-20 top-12 w-full bg-white rounded-lg shadow-lg p-4 max-h-[400px] overflow-y-auto'>
            {loading && <div></div>}
            {loading == false && results.length > 0 && results.map((result, index) => (
                <Link key={index} to={result.link.replace('https://laboutiquedutraceur.fr', '')} onClick={() => setResults([])}>
                    <div className='flex flex-row text-sm border-b border-grey p-2 hover:bg-grey rounded-lg'>
                        <div className='w-1/6'><img className='mix-blend-multiply' src={result.image_link} alt="" /></div>
                        <div className='w-4/6 px-4'>
                            <p className='font-semibold text-sm'>{result.title}</p>
                            <div className='py-2'>
                                <span className='inline-flex items-center bg-greenBg p-2 rounded-xl text-xs'>
                                    <svg width="20" height="20" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="7" cy="7.5" r="4" fill="#79C399"/>
                                    <circle cx="7" cy="7.5" r="5.5" stroke="#37D677" strokeOpacity="0.15" strokeWidth="3"/>
                                    </svg>
                                    <span className='pl-3 font-semibold text-greenAccent'> En stock</span>
                                </span>
                            </div>
                        </div>
                        <div className='w-2/6'>
                            <p className='font-semibold text-right'>{toPrice(result.best_price)} HT</p>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
        )}
    </div>
  )
}

export default SearchComponent