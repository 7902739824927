import React, { useEffect, useState } from 'react'
import HeaderComponent from '../components/headerComponent'
import { useProductsCategoryName } from '../hooks/usePrestashopProducts'
import { useCategorieChoosedParams } from '../store/store';
import ProductCardComponent from '../components/productCardComponent';
import LoadingComponent from '../components/loadingComponent';
import { getImage, toPrice } from '../utils/someFunctions';
import RangeSlider from '../components/html/RangeSlider';
import SearchComponent from '../components/html/SearchComponent';
import MultiRangeSlider from "multi-range-slider-react";
import FooterComponent from '../components/footerComponent';
import { useParams } from 'react-router-dom';



function CategoriePage() {

  const {categoryName} = useParams();
  const [viewType, setViewType] = useState('grid');
  const {getCategorieParams} = useCategorieChoosedParams();
  const {products, error, loading} = useProductsCategoryName(getCategorieParams().cat_name, categoryName);

  const [productCat, setProductCat] = useState(products);

  // Ajout de la pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12; // Modifiez ceci selon vos besoins
  const totalPages = Math.ceil(productCat.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = productCat.slice(startIndex, endIndex);

  // Filtres de features
  const [uniqueFeaturesName, setUniqueFeaturesName] = useState([]);
  const [features, setFeatures] = useState(null);
  const [filterFeatures, setFilterFeatures] = useState([]);

  const goToPage = (pageNumber) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentPage(pageNumber);
  };

  const [showFilters, setShowFilters] = useState(false);

  // Permet d'initialiser les filtres de recherche
  // const [filters, setFilters] = useState({minPrice: 0, maxPrice: Math.max(...products.map(product => product.price_ht)), brands: []});

  // Permet de stocker les filtres de recherche
  const [filterData, setFilterData] = useState({prices: [], features: []});


  const changeGridListView = () => {
    if(viewType === 'grid') {
      setViewType('list');
    } else {
      setViewType('grid');
    }
  } 


  // Assign products to productCat
  useEffect(() => {
    if(products.length > 0) {
      const prices = products.map((product) => product.price_ht);
      const minPrice = Math.min(...prices);
      const maxPrice = Math.max(...prices);

      products.filter((product) => product.price_ht > minPrice);

      

        var filterss = new Map();

      products.filter((product) => product.features).forEach((product) => {
        Object.entries(product.features).forEach(([key, values]) => {
          if (values['is_filterable']) {
            const normalizedValue = values['value'].replace(':', '').trim().toUpperCase();
            if (!filterss.has(key)) {
              filterss.set(key, { name: key, values: new Set([normalizedValue]) });
            } else {
              filterss.get(key).values.add(normalizedValue);
            }
          }
        });
      });

      let ListFilters = [];
    // Convert Set to Array for each entry in the Map
    Array.from(filterss.values()).map(entry =>
      ListFilters.push({name: entry.name, values: Array.from(entry.values)})
    );

    console.log(ListFilters);

    setFilterData({...filterData, prices: [minPrice, maxPrice]});
    setProductCat(products);
    setUniqueFeaturesName(ListFilters);

    // console.log('filterss', ListFilters);

    }
  }, [products]);


  const onFilterChange = (e, type, value) => {

    var filteredProducts = [...products];
    
    // Filtrer les produits par prix via le slider
    if(type === 'range') {
      setFilterData({...filterData, prices: [e.minValue, e.maxValue]});
      filteredProducts = filteredProducts.filter((product) => parseFloat(product.price_ht) >= e.minValue && parseFloat(product.price_ht) <= e.maxValue);
    }

    // Filtrer les produits par features
    if (type === 'features') {
      console.log('value', value);

      var filterFeature = {...filterData};

     if( filterFeature.features && filterFeature.features[value.feature]) {
        if(filterFeature.features[value.feature].includes(value.val)) {
          filterFeature.features[value.feature] = filterFeature.features[value.feature].filter((item) => item !== value.val);
        } else {
          filterFeature.features[value.feature].push(value.val);
        }
      } else {
        filterFeature['features'][value.feature] = [value.val];
      }

      var hasNonEmptyArrays = Object.values(filterFeature.features).some(array => array.length > 0);

      console.log('hasNonEmptyArrays', hasNonEmptyArrays);


      setFilterData(filterFeature);

      
      // filtrer sur filterFeatures
      if(hasNonEmptyArrays) {
        console.log('filtres présents :  on filtre !', filterFeature)

        // Filtrer les produits qui ont les features sélectionnées
        var setProd = new Set();
        filteredProducts.filter((product) => {
          // Via every method
          Object.entries(filterFeature.features).every(([key, values]) => {
            console.log('values', values);
            console.log('values key', key);

            console.log('test includes', product.features[key]);

            if(product.features[key] && values.includes(product.features[key].value.replace(':', '').trim().toUpperCase())) {
              setProd.add(product);
              return true;
            }


          });
        });

        filteredProducts = Array.from(setProd);



      // filteredProducts = filteredProducts.filter((product) => {
      //   // var valueOfFeature = product.features;
      //   let includeProduct = true;


      //   if()

      
      //   Object.entries(filterFeature.features).forEach(([key, values]) => {
      //     console.log('values', values);
      //     console.log('key', key);
      //     if (product.features[key] === undefined || !values.includes(product.features[key]['value'].replace(':', '').trim().toUpperCase())) {
      //         includeProduct = false;
      //     }
      //   });
      
      //   return includeProduct;
      // });
      }


    }


    setProductCat(filteredProducts);

  }

  const reinitFilters = () => {
    setFeatures(null);
    setFilterData({...filterData, features: [], prices: [0, Math.max(...products.map(product => product.price_ht))]});
    setProductCat(products);
  }

  return (
    <>
      <HeaderComponent />
      <div className="mx-auto container">
        <div className='py-12'>
          <span className='text-greyAccent'>Accueil</span> / {getCategorieParams().ariane}
        </div>
        {getCategorieParams().cat_id != '' && getCategorieParams().cat_img ?
          <div className='p-6 rounded-xl min-h-[250px]' 
            style={{
              backgroundImage: `url(${process.env.REACT_APP_PRESTASHOP_URL}img/c/${getCategorieParams().cat_img}.jpg)`,
              backgroundSize: 'cover',
            }}>
              <p className='cat_desc_dangerous z-50 top-0 text-white' dangerouslySetInnerHTML={{ __html: getCategorieParams().cat_desc }}></p>
          </div>
          :
          <div className='p-6 rounded-xl min-h-[250px]' 
          style={{
            backgroundImage: `url(${getImage('BG-Undefinded.png')})`,
            backgroundSize: 'cover',
          }}>
            <p className='cat_desc_dangerous z-50 top-0 text-white' dangerouslySetInnerHTML={{ __html: getCategorieParams().cat_desc }}></p>
          </div>
        }

        {
          getCategorieParams().ariane.includes('ENCRES') && (
            
          <div className="py-12">
            <h1 className='text-center font-semibold text-3xl py-4'>Recherchez votre modèle par marque</h1>
            <div className='grid grid-cols-3 mobile:grid-cols-1 gap-6 items-end'>
              <div className='flex flex-col items-center'>
                <div className=''><img className='' src={getImage('1200px-Canon_logo-svg 1.png')} alt="" /></div>
                <div className='w-full my-4'>
                  <SearchComponent placeholder={'Votre modèle Canon'} maxResutlt={'1000'} filters={'&filter[brand][]=Canon'} />
                </div>
              </div>
              <div className='flex flex-col items-center'>
                <div><img className='' src={getImage('1200px-HP_logo_2012-svg 1.png')} alt="" /></div>
                <div className='w-full my-4'>
                  <SearchComponent placeholder={'Votre modèle HP'} maxResutlt='1000' filters={'&filter[brand][]=HP'} />
                </div>
              </div>
              <div className='flex flex-col items-center'>
                <div className=''><img className='' src={getImage('Epson-Logo 1.png')} alt="" /></div>
                <div className='w-full my-4'>
                  <SearchComponent placeholder={'Votre modèle EPSON'} maxResutlt='1000' filters={'&filter[brand][]=Epson'}  />
                </div>
              </div>
            </div>
          </div>
        )}
        {
          getCategorieParams().ariane.includes('encre HP') && (
            
          <div className="py-12">
            <h1 className='text-center font-semibold text-3xl py-4'>Recherchez votre modèle par marque</h1>
            <div className='grid grid-cols-3 mobile:grid-cols-1 gap-6 items-end'>
              <div></div>
              <div className='flex flex-col items-center'>
                <div><img className='' src={getImage('1200px-HP_logo_2012-svg 1.png')} alt="" /></div>
                <div className='w-full my-4'>
                  <SearchComponent placeholder={'Votre modèle HP'} maxResutlt='1000' filters={'&filter[brand][]=HP'} />
                </div>
              </div>
              <div></div>
            </div>
          </div>
        )}
        {
          getCategorieParams().ariane.includes('encre Epson') && (
            
          <div className="py-12">
            <h1 className='text-center font-semibold text-3xl py-4'>Recherchez votre modèle par marque</h1>
            <div className='grid grid-cols-3 mobile:grid-cols-1 gap-6 items-end'>
              <div></div>
              <div className='flex flex-col items-center'>
                <div className=''><img className='' src={getImage('Epson-Logo 1.png')} alt="" /></div>
                <div className='w-full my-4'>
                  <SearchComponent placeholder={'Votre modèle EPSON'} maxResutlt='1000' filters={'&filter[brand][]=Epson'}  />
                </div>
              </div>
              <div></div>
            </div>
          </div>
        )}
        {
          getCategorieParams().ariane.includes('encre Canon') && (
            
          <div className="py-12">
            <h1 className='text-center font-semibold text-3xl py-4'>Recherchez votre modèle par marque</h1>
            <div className='grid grid-cols-3 mobile:grid-cols-1 gap-6 items-end'>
              <div></div>
              <div className='flex flex-col items-center'>
                <div className=''><img className='' src={getImage('1200px-Canon_logo-svg 1.png')} alt="" /></div>
                <div className='w-full my-4'>
                  <SearchComponent placeholder={'Votre modèle Canon'} maxResutlt={'1000'} filters={'&filter[brand][]=Canon'} />
                </div>
              </div>
              <div></div>
            </div>
          </div>
        )}
        
        <div className="grid grid-cols-2 mobile:grid-cols-1 justify-between py-6 items-center relative">
          <div></div>
          <div className='flex justify-end text-sm'>
            <button className='mobile:hidden flex flex-row items-center' onClick={() => changeGridListView()}>
              <svg className='mr-2' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_186_27207)">
                <path d="M13.3334 1.33301H2.66671C1.93033 1.33301 1.33337 1.92996 1.33337 2.66634V13.333C1.33337 14.0694 1.93033 14.6663 2.66671 14.6663H13.3334C14.0698 14.6663 14.6667 14.0694 14.6667 13.333V2.66634C14.6667 1.92996 14.0698 1.33301 13.3334 1.33301Z" stroke="#C1C1C1" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1.33337 8H14.6667" stroke="#C1C1C1" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 1.33301V14.6663" stroke="#C1C1C1" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                <clipPath id="clip0_186_27207">
                <rect width="16" height="16" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              Vue {viewType == 'grid' ? 'grille' : 'liste'}</button>
              {loading ? <div></div> : <div className='pl-4'><span className='text-orange px-3 py-1 bg-grey rounded-full'>{productCat.length}</span> produits</div>}
          </div>
        </div>

        <div class="flex flex-row mobile:flex-col gap-4">
          <div class="w-1/4 mobile:w-full">
            {/* create filter with features of products */}
            <div>
            {loading ? 
            <div>
              {Array.from({ length: 10 }, (_, index) => (
                <>
                  <div class="w-full my-2 h-4 rounded-full bg-grey animate-pulse"></div>
                  <div class="w-1/2 my-2 h-4 rounded-full bg-grey animate-pulse"></div>
                  <div class="w-1/3 my-2 h-4 rounded-full bg-grey animate-pulse"></div>
                </>
                ))}
            </div> :
                <div className='bg-grey p-4 my-4 rounded-md'>
                  {
                    features && (
                      <button onClick={() => reinitFilters()} className='border-red text-red border px-4 py-2 rounded-lg w-full text-center my-2'>Réinitialiser les filtres</button>
                    )
                  }
                  
                   <div className='bg-greySoft p-4 rounded-md cursor-pointer' onClick={() => setFeatures('prix')}>
                        <p className='font-semibold uppercase'>Prix</p>
                    </div>
                    {
                      features === 'prix' ?
                      <>
                      <MultiRangeSlider
                          // min={1}
                          max={Math.max(...products.map(product => product.price_ht))}
                          // minValue={1}
                          maxValue={filterData.prices[1]}
                          minValue={filterData.prices[0]}
                          thumbLeftColor='#F1671A'
                          thumbRightColor='#F1671A'
                          barInnerColor='#F1671A'
                          style={{ border: "none", boxShadow: "none", padding: "15px 10px" }}
                          ruler={false}
                          label={false}
                          className='accent-orange'
                          onInput={(e) => {
                            /* Evite les rerenders inutiles */
                            if(e.minValue != filterData.prices[0] || e.maxValue != filterData.prices[1]) {
                              setFilterData({...filterData, minPrice: e.minValue, maxPrice: e.maxValue});
                              onFilterChange(e, 'range');
                            }
                          }}
                        />
                          <div className="flex gap-3 py-3">
                            <input value={filterData.prices[0]} placeholder='Prix min €' className='w-1/2 border border-[#E8E8E8] bg-grey rounded-lg px-3 py-2' type="text" />
                            <input value={filterData.prices[1]} placeholder='Prix max €' className='w-1/2 border border-[#E8E8E8] bg-grey rounded-lg px-3 py-2' type="text" />
                          </div>
                        </>
                      :
                      null
                    }
                    {uniqueFeaturesName.map(({ name, values }) => (
                      
                      <div key={name} className='py-2'>
                        <div className='bg-greySoft p-4 rounded-md cursor-pointer flex flex-row justify-between' onClick={() => setFeatures(name)}>
                          <p className='font-semibold uppercase'>{name}</p>
                          {filterData.features[name] && filterData.features[name].length > 0 ?
                            <p className='text-xs bg-orange rounded-full px-2 text-white py-1'>{filterData.features[name].length}</p>
                            : null
                          }
                        </div>
                        <div className='px-3'>
                          {features === name ? 
                            values.map((item, index) => (
                              <div key={index} className='py-1'>
                                <input checked={filterData.features[name] && filterData.features[name].includes(item)} onChange={(e) => onFilterChange(e, 'features', {feature: name, val: item})} className='accent-orange' value={item} type="checkbox" id={`${name}-${index}`} />
                                <label htmlFor={`${name}-${index}`} className='pl-4 text-sm'>{item}</label>
                              </div>
                            ))
                            :
                            <div></div>
                          }
                        </div>
                      </div>
                    ))}
                </div>
            }
            </div>
          </div>
          <div class="w-3/4 mobile:w-full">
                <div className='pt-4 min-h-screen'>
              {
                loading ? <LoadingComponent /> : 
                viewType == 'grid' ?
                <div className='grid grid-cols-3 mobile:grid-cols-1'>
                {currentItems.map((item) => (
                    <ProductCardComponent key={item.id_product} product={item} typeView='grid' />
                ))}
                </div>
                :
                <div>
                  {currentItems.map((item) => (
                    <ProductCardComponent key={item.id_product} product={item} typeView='list' />
                  ))}
                </div>
              }
              </div>
          </div>
        </div>
        {/* Contrôles de pagination  et afficher que 5 page suivantes*/}
      {Math.ceil(productCat.length / itemsPerPage) > 1 &&
        <div className='flex justify-end py-12'>
          {/* Bouton Page Précédente */}
          {currentPage > 1 &&
            <button onClick={() => goToPage(currentPage - 1)} className='mx-2'>
            Page précédente
            </button>
          }
          {Array.from({ length: Math.min(5, Math.ceil(productCat.length / itemsPerPage) - currentPage + 1) }, (_, index) => (
            <button key={index} onClick={() => goToPage(currentPage + index)} className={`mx-2 ${index == 0 ? 'text-orange': ''}`}>
              {currentPage + index}
            </button>
          ))}
          {/* Bouton Page Suivante */}
          {currentPage < Math.ceil(productCat.length / itemsPerPage) &&
            <button onClick={() => goToPage(currentPage + 1)} className='mx-2'>
              Page suivante
            </button>
          }
        </div>}
      </div>
      <FooterComponent />
    </>
  )
}

export default CategoriePage