import React, { useEffect, useState } from "react";
import api from "../utils/fetch";
import { useAuthState, useCartState } from "../store/store";

export const useOrders = () => {

    const {getUser} = useAuthState();
    const [orders, setOrders] = useState([])
    const [error,setError] = useState(null)
    const [loading,setLoading] = useState(false)
  
    useEffect(() => {
        (
            async function(){
                try{
                    setLoading(true)
                    const formData = new FormData();
                    formData.append('id_customer', getUser('id_customer'));
                    const ordersData = await fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/orders/getOrders.php' , {
                        method: 'POST',
                        body: formData
                    });
                    const ordersDataJson = await ordersData.json();

                    setOrders(ordersDataJson.orders);
                }catch(err){
                    setError(err)
                }finally{
                    setLoading(false)
                }
            }
        )()
    }, [])
  
    return { orders, error, loading }
  
  }

export const useOrder = (id) => {
    const [order, setOrder] = useState([])
    const [error,setError] = useState(null)
    const [loading,setLoading] = useState(false)

    useEffect(() => {
        (
            async function(){
                try{
                    setLoading(true)
                    const orderData = await api(`orders/${id}`);
                  
                    // retourne les Commandes par utilisateur
                    setOrder(orderData);
                }catch(err){
                    setError(err)
                }finally{
                    setLoading(false)
                }
            }
        )()
    }, [id])

    return { order, error, loading }
}