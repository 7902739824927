import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
import { getImage } from '../../utils/someFunctions';
import { useAuthState, useCartState } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const DropzoneWithPreview = ({form, shippingMethod}) => {

  const { getCartNumberItems, getCart, removeCart, getTotalCartHT, getTotalCartTVA, getTotalCartTTC, setPromoCode, getPromoCode, setLivraison, getLivraison } = useCartState();
  const cartItems = getCart();
  const navigate = useNavigate();
  const {getUser, isAuthenticated} = useAuthState();


  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const [pdfFile, setPdfFile] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (file.type === 'application/pdf') {
        setPdfFile(file);
      } else if (file.type.startsWith('image/')) {
        setImageFiles((prevFiles) => [...prevFiles, file]);
      }
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ['image/*', 'application/pdf'],
  });

  const removeFile = (fileToRemove) => {
    if (fileToRemove.type === 'application/pdf') {
      setPdfFile(null);
    } else {
      setImageFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    }
  };


  const createOrder = async () => {

    // if(pdfFile == null || imageFiles.length < 1) {
    //   toast.error('Veuillez ajouter un mandat administratif');
    //   return;
    // }

    var formdata = new FormData();

    // Enregistrement des produits dans la commande
    formdata.append('products', JSON.stringify(cartItems));

    // Enregistrement de la commande dans le client connecté
    if(isAuthenticated() == true && getUser('id_customer')) {
      formdata.append('id_customer', getUser('id_customer'));
      formdata.append('id_address', form.id_address);
    }

    // Si adresse de livraison différente de facturation
    formdata.append('delivery_different_billing', form.delivery_different_billing);

    // Création du client
    if(isAuthenticated() == false) {
      formdata.append('nom', form.nom);
      formdata.append('prenom', form.prenom);
      formdata.append('email', form.email);
      formdata.append('telephone', form.telephone);
      formdata.append('siret', form.siret);
      formdata.append('entreprise', form.entreprise);
      formdata.append('adresse_delivery', form.adresse_delivery);
      formdata.append('ville_delivery', form.ville_delivery);
      formdata.append('code_postal_delivery', form.code_postal_delivery);
      formdata.append('pays_delivery', form.pays_delivery);
    }

    // Si adresse de livraison différente de facturation création de l'adresse
    if(isAuthenticated() == false && form.delivery_different_billing == true) {
      formdata.append('adresse_billing', form.adresse_billing);
      formdata.append('ville_billing', form.ville_billing);
      formdata.append('pays_billing', form.pays_billing);
      formdata.append('code_postal_billing', form.code_postal_billing);
    }
    // Method de paiement
    formdata.append('payment_method', 'mandat_administratif');
    formdata.append('total_ht', parseFloat(getTotalCartHT()).toFixed(2));
    formdata.append('total_ttc', parseFloat(getTotalCartTTC()).toFixed(2));

    // Ajout des images
    imageFiles.forEach((file, index) => {
      formdata.append(file.name, file);
    });

    // Ajout des pdf
    formdata.append('pdfFile', pdfFile);

    // Méthode de transport
    formdata.append('shipping_method', shippingMethod);

    const response = await fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/orders/CreateOrder.php', {
      method: 'POST',
      body: formdata
    });

    const data = await response.json();
    console.log(data);
    if(response.ok && data.success == true) {
      removeCart();
      navigate('/order-confirmation');
    } else {
      console.log(data);
      toast.error('Erreur, veuillez réessayer, votre commande n\'a pas été enregistrée');
    }
  }

  return (
    <>
    <div>
      { pdfFile != null || imageFiles.length === 0 &&
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''} border-dashed border-2 px-4 py-8 rounded-lg`}>
          <input {...getInputProps()} />
          <div className='flex justify-center py-2'><img src={getImage('/upload.png')} className='text-center' alt="" /></div>
            <div>
              <p className='text-center'>Glisser déposer un mandat administratif</p>
              <p className='text-center text-xs'>Format acceptés: PDF, PNG, JPG, JPEG</p>
            </div>
        </div>
      }

      <div className="preview">
        {pdfFile && (
          <div className="relative py-4">
            <p className='text-center'>{pdfFile.name}</p>
            {/* Ici rendre un lecteur PDF */}
            <embed src={URL.createObjectURL(pdfFile)} type="application/pdf" width="100%" height="400px" />
            <button onClick={() => removeFile(pdfFile)} className='absolute top-0 right-0 text-white bg-red px-4 py-2 rounded-lg'>Supprimer</button>
          </div>
        )}

        {imageFiles.map((file, index) => (
          <div key={index} className="relative py-4">
            <p className='text-center'>{file.name}</p>
            <img src={URL.createObjectURL(file)} alt={file.name} className='shadow-lg' />
            <button onClick={() => removeFile(file)} className='absolute top-0 right-0 text-white bg-red px-4 py-2 rounded-lg'>Supprimer</button>
          </div>
        ))}
      </div>
    </div>
    <button onClick={() => createOrder()} className='bg-green font-semibold text-white rounded-lg px-12 py-2 mt-3'>Valider le paiement</button>
    </>
  );
};

export default DropzoneWithPreview;
