import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { removeHTMLTags, toPrice } from '../utils/someFunctions'
import { useCartState, addToCart } from '../store/store';
import { useProduct } from '../hooks/usePrestashopProducts';

function ProductCardComponent({product, typeView = 'grid'}) {


  const [productQte, setProductQte] = useState(1);
  const { addToCart } = useCartState();

  const addToCartProduct = () => {
    addToCart({
      'image': product.cover_image,
      'product_id': product.id_product,
      'link_rewrite': product.link_rewrite,
      'quantity': productQte,
      'options': [],
      'garanties': [],
      'total': product.price_ht,
      'recyclage': '',
      'cadeau': '',
    })
  }
  return (
    <>
      {
        typeView === 'grid' ?
      <div className='p-2 '>
        <div className="border-greySoft border rounded-lg p-4 bg-white block overflow-hidden flex flex-col justify-between h-[500px]">
          <div className=''>
            <div className='bg-grey rounded-lg p-3 relative'>
                <div className='absolute top-3 left-2 z-10'>
                  {product.reductions && product.reductions.map((price) => (
                    <span key={price.id} className='bg-orange text-white text-xs rounded-full font-semibold py-1 px-4'>
                      {price.reduction_type === 'percentage' ? '-' + parseFloat(price.reduction * 100).toFixed(2) + ' %' : '- ' + toPrice(price.reduction).toString()}
                    </span>
                  ))}
                </div>
              <div className='flex justify-center text-center'><img className='mix-blend-multiply max-h-[150px]' src={product.cover_image} alt={'produit-' + product.link_rewrite} /></div>
            </div>
            <h4 className='text-black font-medium text-md pt-3'>{product.name}</h4>
            <div className="">
              <div>
                <p className='text-greyAccent py-3 text-sm'>Ref : {product.reference}</p>

                { product.old_price_ht > 0 && product.old_price_ht && product.old_price_ht != product.price_ht && (
                  <p className='text-[#A9A9A9] line-through text-sm'>{toPrice(product.old_price_ht)} HT</p>
                )}

                {product.price_ht > 0 &&
                  <p className='font-semibold text-2xl'>{toPrice(product.price_ht)} <span className='text-xs'>HT</span></p>
                }
                {product.price_ttc > 0 &&
                  <p className='text-greyAccent text-sm'>{toPrice(product.price_ttc)} TTC</p>
                }
              </div>
            </div>
          </div>

          <div>
            <div className='py-4'>
              {product.stock == 0 &&
                <div className='text-red font-semibold items-center flex flex-row justify-end'> <div className='bg-red h-4 w-4 rounded-full mr-2'></div> Rupture de stock</div>
              }
              {product.stock > 0 && product.stock == 3 && (
                <div className='text-green font-semibold items-center flex flex-row justify-end'> <div className='bg-green h-4 w-4 rounded-full mr-2'></div> {product.stock} pièces restantes</div>
              )}
              {product.stock > 0  && product.stock >= 10 && (
                <div className='text-green font-semibold items-center flex flex-row justify-end'> <div className='bg-green h-4 w-4 rounded-full mr-2'></div> En stock</div>
              )}
              {product.stock > 0 && product.stock < 3 && (
                <div className='text-orange font-semibold items-center flex flex-row justify-end'> <div className='bg-orange h-4 w-4 rounded-full mr-2'></div> Fin de stock</div>
              )}
            </div>

            <div className='w-full flex text-center'>
              <Link className='w-full border-grayAccent border-2 border-greyAccent rounded-xl p-2 font-bold text-greyAccent' to={window.location.origin + '/' +product.slug_category+'/'+product.id_product+'-'+product.link_rewrite}>Voir le produit </Link>
            </div>
          </div>
        </div>
      </div>
      :
      <div className='border-greySoft border rounded-lg bg-white block overflow-hidden h-full flex flex-row justify-between my-4'>
        <div className='w-1/6'>
          <div className='bg-grey rounded-lg p-3 relative h-full'>
                <div className='absolute top-3 left-2 z-10'>
                  {product.reductions && product.reductions.map((price) => (
                    <span key={price.id} className='bg-orange text-white text-xs rounded-full font-semibold py-1 px-4'>
                      {price.reduction_type === 'percentage' ? '-' + parseFloat(price.reduction * 100).toFixed(2) + ' %' : '- ' + toPrice(price.reduction).toString()}
                    </span>
                  ))}
                </div>
              <div className='flex justify-center items-center text-center max-h-[150px]'><img className='mix-blend-multiply' src={product.cover_image} alt={'produit-' + product.link_rewrite} /></div>
            </div>
        </div>
        <div className='w-4/6 p-6'>
          <h4 className='text-black font-semibold text-lg'>{product.name}</h4>
          <p className='text-greyAccent py-1 text-sm'>Ref : {product.reference}</p>
          <div className='py-1'>
            {product.stock < 1 && (
              <span className='inline-flex items-center bg-[#FE0000] p-2 rounded-xl text-xs'>
              <svg width="20" height="20" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7" cy="7.5" r="5.5" fill="white" stroke="#F7C6B7" stroke-width="3"/>
                </svg>
                <span className='pl-3 font-semibold text-white'> Rupture de stock</span>
              </span>
            )}
            {product.stock && product.stock == 3 && (
              <span className='inline-flex items-center bg-greenBg p-2 rounded-xl text-xs'>
                  <svg width="20" height="20" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="7" cy="7.5" r="4" fill="#79C399"/>
                  <circle cx="7" cy="7.5" r="5.5" stroke="#37D677" strokeOpacity="0.15" strokeWidth="3"/>
                  </svg>
                  <span className='pl-3 font-semibold text-greenAccent'> {product.stock} pièces restantes</span>
              </span>
            )}
            {product.stock && product.stock >= 10 && (
              <span className='inline-flex items-center bg-greenBg p-2 rounded-xl text-xs'>
                  <svg width="20" height="20" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="7" cy="7.5" r="4" fill="#79C399"/>
                  <circle cx="7" cy="7.5" r="5.5" stroke="#37D677" strokeOpacity="0.15" strokeWidth="3"/>
                  </svg>
                  <span className='pl-3 font-semibold text-greenAccent'> En stock</span>
              </span>
            )}
            {product.stock && product.stock < 3 && (
              <span className='inline-flex items-center bg-orangeBg p-2 rounded-xl text-xs'>
                  <svg width="20" height="20" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="7" cy="7.5" r="4" fill="#79C399"/>
                  <circle cx="7" cy="7.5" r="5.5" stroke="#37D677" strokeOpacity="0.15" strokeWidth="3"/>
                  </svg>
                  <span className='pl-3 font-semibold text-white'> Fin de stock</span>
              </span>
            )}
          </div>
          <div className='py-2'>
            <p className='font-light text-sm line-clamp-3'>{removeHTMLTags(product.description_short)}</p>
          </div>
        </div>
        <div className='w-1/6 flex flex-col justify-center px-4'>
          { product.old_price_ht > 0 && product.old_price_ht && product.old_price_ht != product.price_ht && (
            <p className='text-[#A9A9A9] line-through text-sm'>{toPrice(product.old_price_ht)} HT</p>
          )}
          {product.price_ht > 0 && <p className='font-semibold text-2xl'>{toPrice(product.price_ht)} <span className='text-xs'>HT</span></p>}
          {product.price_ttc > 0 && <p className='text-greyAccent text-sm'>{toPrice(product.price_ttc)} TTC</p>}
          <div className="">
              {product.stock > 0 && product.price_ht > 0 &&
                <div className="w-1/2 border-2 rounded-xl border-black bg-grey flex flex-row p-1 my-2">
                <input type="number" className='w-1/2 bg-grey text-right rounded-xl' value={productQte} onChange={(e) => setProductQte(e.target.value)} /> 
                <span className='pl-2 text-sm'>PCS</span>
              </div>
              }
              <div>
                {product.stock > 0 && product.price_ht > 0 &&
                  <button onClick={() => addToCartProduct()} className="w-full py-2 bg-green text-white text-center font-bold rounded-xl flex  items-center justify-center">
                  <svg width="15" height="14" className='mr-2' viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.8333 7H2.16663" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="bevel"/>
                    <path d="M7.5 12.3337V1.66699" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="bevel"/>
                  </svg>
                  Ajouter au panier
                </button>
                }
              </div>
              <div className='my-2 w-full flex text-center'>
                <Link className='w-full border-grayAccent border-2 border-greyAccent rounded-xl p-2 font-bold text-greyAccent' to={window.location.origin + '/' +product.slug_category+'/'+product.id_product+'-'+product.link_rewrite}>Voir le produit </Link>
              </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}

export default ProductCardComponent