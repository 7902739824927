import { hookstate, useHookstate } from "@hookstate/core";
import { toast } from "react-toastify";
import NotifyAddedToCart from "../components/NotifyAddedToCart";
import { parse } from "filepond";




// Créez un état initial pour l'utilisateur authentifié
const storedUserData = JSON.parse(localStorage.getItem('authenticatedUser'));
// const storedUserData = [];

const initialUserState = hookstate({
  authenticated: storedUserData ? true : false,
  token: storedUserData ? storedUserData.token : null,
  user: storedUserData ? storedUserData.user : [],
  loadingUser: false,
});

export const useAuthState = () => {
  const state = useHookstate(initialUserState);

  return {
    loadingUser: () => state.loadingUser.value,
    isAuthenticated: () => state.authenticated.value,
    getUser: (valueOfUser) => {
      if(state.user.value && state.user.value[valueOfUser] !== undefined) {
        return state.user.value[valueOfUser];
      }
      return '';
    },
    checkToken: async () => {
      try {

        var formdata = new FormData();

        formdata.append('token', state.token.value);

        const response = await fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/auth/VerifyToken.php', {
          method: 'POST',
          body: formdata
        });

        if(response.ok) {
          const data = await response.json();
          if(data.success) {
            return true;
          }
          state.set({
            authenticated: false,
            token: null,
            user: []
          });
          localStorage.removeItem('authenticatedUser');
          return false;
        }
      } catch (error) {
        state.set({
          authenticated: false,
          token: null,
          user: []
        });
        localStorage.removeItem('authenticatedUser');
        return false;
      }
    },
    login: async (email, password) => {
      try {

        state.loadingUser.set(true);
        var formdata = new FormData();

        formdata.append('email', email);
        formdata.append('password', password);

        const response = await fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/auth/Login.php', {
          method: 'POST',
          body: formdata
        });

        if(response.ok) {
          const data = await response.json();
          if(data.success) {
            console.log(data.user)
            state.set({
              authenticated: true,
              token: data.token,
              user: data.user,
              loadingUser: false,
            });
            // Stockez les données de l'utilisateur dans le stockage local
            localStorage.setItem('authenticatedUser', JSON.stringify(data));

            return true;
          } else {
            return false
          }
        }
      } catch (error) {
        
      }
    },
    logout: () => {
      state.set({
        authenticated: false,
        token: null,
        user: []
      });
      // localStorage.removeItem('authenticatedUser');
    },
    register: async (email, password, firstname, lastname, birthday, phone, siret, compagnyname, activitydomaine) => {
      // try {


        var formdata = new FormData();

        formdata.append('email', email);
        formdata.append('password', password);
        formdata.append('firstname', firstname);
        formdata.append('lastname', lastname);
        formdata.append('birthday', birthday);
        formdata.append('phone', phone);
        formdata.append('siret', siret);
        formdata.append('compagnyname', compagnyname);
        formdata.append('activitydomaine', activitydomaine);

        const response = await fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/auth/Register.php', {
          method: 'POST',
          body: formdata
        });

        if(response.ok) {
          const data = await response.json();
          console.log(data)
          if(data.success) {
            state.set({
              authenticated: true,
              token: data.token,
              user: data.user[0]
            });
            // Stockez les données de l'utilisateur dans le stockage local
            localStorage.setItem('authenticatedUser', JSON.stringify(data));

            return true;
          } else {
            return false
          }
        } else {
          return false;
        }
      // } catch (error) {
        
      // }
    }
  };
};


// STORE Panier (cart)
const initialCartState = hookstate({
  cart: JSON.parse(localStorage.getItem('cart')) || [],
  promo: null,
  delivery: null,
});

export const useCartState = () => {
  const state = useHookstate(initialCartState);

  return {
    getCartNumberItems: () => state.cart.value.length,
    getCount: () => state.count.value,
    getCart: () => state.cart.value,
    addToCart: (item) => {
      state.cart.set((cart) => {
        const existingItemIndex = cart.findIndex((cartItem) => cartItem.product_id === item.product_id);

        if (existingItemIndex !== -1) {
          // Le produit est déjà dans le panier, augmentez la quantité
          const updatedCart = [...cart];
          updatedCart[existingItemIndex] = item;

          toast.success(<NotifyAddedToCart product={item} text='Panier mis à jour !'/>, {
            position: "bottom-right",
          });
          localStorage.setItem('cart', JSON.stringify(updatedCart));
          return updatedCart;
        } else {
          toast.success(<NotifyAddedToCart product={item} text={'Produit ajouté au panier !'}/>, {
            position: "bottom-right",
          });
          const updatedCart = [...cart, item]; // Utilisez cart.value pour accéder au tableau
          localStorage.setItem('cart', JSON.stringify(updatedCart));
          return updatedCart;
        }
      });
    },
    setNewOption: (item, optionName, index, value, isMultiple) => {
      state.cart.set((cart) => {
        const existingItemIndex = cart.findIndex((cartItem) => cartItem.product_id === item.product_id);

        if (existingItemIndex !== -1) {

          
          const updatedCart = [...cart];

          // Si c'est pas multiple, on doit retirer l'ancienne option et ajouter la nouvelle
          if(!isMultiple) {
            // On retire l'ancienne option
            if(updatedCart[existingItemIndex][optionName] !== undefined) {
              updatedCart[existingItemIndex].total -= parseFloat(updatedCart[existingItemIndex][optionName][0].price);
            }
            updatedCart[existingItemIndex][optionName][0] = value;
            updatedCart[existingItemIndex].total += parseFloat(value.price);
          }

          
          // Si c'est multiple et que l'option existe déjà, on doit la retirer
          if (isMultiple) {
            // Vérifie si le tableau contient un objet avec la même propriété 'price' que 'value'
            const containsValue = updatedCart[existingItemIndex][optionName].some(option => option.price === value.price);
            
            // Si l'option existe déjà, on doit la retirer
            if (containsValue) {
              // Filtre le tableau pour enlever l'objet 'value'
              updatedCart[existingItemIndex][optionName] = updatedCart[existingItemIndex][optionName].filter(option => option.name != value.name);
              console.log(updatedCart[existingItemIndex][optionName]);
              updatedCart[existingItemIndex].total -= parseFloat(value.price);
              return updatedCart;
            } 
            
            if(!containsValue){
              // Ajoute l'objet 'value' au tableau
              updatedCart[existingItemIndex][optionName].push(value);
              updatedCart[existingItemIndex].total += parseFloat(value.price);}
          }
  
          localStorage.setItem('cart', JSON.stringify(updatedCart));
          return updatedCart;
          
        }
      });
    },
    updateQuantity: (item, newQuantity) => {
     state.cart.set((cart) => {
      const existingItemIndex = cart.findIndex((cartItem) => cartItem.product_id === item.product_id);

      // Interdire les valeurs négatives et 0
      if(newQuantity < 1) {
        return cart;
      }

      if (existingItemIndex !== -1) {
        // Le produit est déjà dans le panier, augmentez la quantité
        const updatedCart = [...cart];
        updatedCart[existingItemIndex].quantity = parseInt(newQuantity);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        return updatedCart;
      }

      

    });     
    },
    removeFromCart: (itemId) => {
      console.log(itemId)
      state.cart.set((cart) => cart.filter((item) => item.product_id !== itemId));
      
      const updatedCart = state.cart.value; // Récupérer le panier mis à jour
      localStorage.setItem('cart', JSON.stringify(updatedCart)); // Mettre à jour le stockage local
    },
    removeCart: () => {
      state.cart.set([]);
      localStorage.removeItem('cart');
    },
    getTotalCartHT: () => {
      let total_ht = 0;
      state.cart.value.forEach(item => {
       total_ht += item.total * item.quantity;
      });

      if(state.delivery.value) {
        total_ht += state.delivery.value;
      }

      return total_ht;
    },
    getTotalCartTVA: () => {
      let total_tva = 0;
      state.cart.value.forEach(item => {
       total_tva += item.total * item.quantity;
      });

      return total_tva * 0.20;
    },
    getTotalCartTTC: () => {
      let total_ttc = 0;
      state.cart.value.forEach(item => {
       total_ttc += item.total * item.quantity;
      });

      if(state.promo.value) {
        total_ttc -= state.promo.value.valueReduction;
      }

      if(state.delivery.value) {
        total_ttc += state.delivery.value;
      }

      return total_ttc * 1.20;
    },
    setPromoCode: (valueReduction, code) => {
      // Appliquer le code promo
      state.promo.set({valueReduction, code});
      localStorage.setItem('promo', JSON.stringify({valueReduction, code}));
    },
    getPromoCode: () => state.promo.value,
    setLivraison: (price) => state.delivery.set(price),
    getLivraison: () => state.delivery.value,
  };
};


// Localstorage pour les catégories
const storedCategories = JSON.parse(localStorage.getItem('categories'));
// Paramètres globeaux a passer en URL
const initialGlobalState = hookstate({
  globalCategoryParam: storedCategories || {
    ariane: '',
    cat_name: '',
    cat_id: '',
    cat_desc: '',
    cat_img: '',
  }
});

export const useCategorieChoosedParams = () => {
  const state = useHookstate(initialGlobalState);

  return {
    getCategorieParams: () => state.globalCategoryParam.value,
    setGlobalCategoryParam: (key, value) => {
      state.globalCategoryParam.set((params) => {
        return {...params, [key]: value};
      });
    },
    setCategorieParams: (ariane, cat_name, cat_id, cat_desc, cat_img) => {
      state.globalCategoryParam.set({ariane, cat_name, cat_id, cat_desc, cat_img});
      localStorage.setItem('categories', JSON.stringify({ariane, cat_name, cat_id, cat_desc, cat_img}));
    }
  };
}