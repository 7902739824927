import React from 'react';

const CountrySelect = ({ onChange, valueSelected}) => {

  return (
    <div className='my-2 py-1 w-full px-4 rounded-md border border-greyAccent bg-white'>
      <label htmlFor="" className='text-greyAccent text-xs'>Pays</label>
      <select value={valueSelected != '' && valueSelected != 'undefined' ? valueSelected : 'fr'} onChange={onChange} name="country" id="country" className="w-full px-0 rounded-md bg-white outline:border-white focus:border-white focus-visible:border-white focus:ring-0">
        <option value="fr">France</option>
        <option value="be">Belgique</option>
        <option value="ch">Suisse</option>
        <option value="lu">Luxembourg</option>
        <option value="mc">Monaco</option>
      </select>
    </div>
  );
};

export default CountrySelect;
