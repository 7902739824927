import React, { useState } from 'react'
import HeaderComponent from '../components/headerComponent';
import { useAuthState } from '../store/store';
import Input from '../components/html/input';
import { siretFormatter } from '../utils/formatters';
import FooterComponent from '../components/footerComponent';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {

    const {register} = useAuthState();

    const [errors, setErrors] = useState();
    const [passwordView, setPasswordView] = useState(false);
    const [cgu, setCgu] = useState('');

    const [email, setEmail] = useState('');
    const [password, setpassword] = useState('');
    const [firstname, setfirstname] = useState('');
    const [birthday, setbirthday] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [siret, setSiret] = useState('');
    const [compagnyname, setCompagnyname] = useState('');
    const [activitydomaine, setActivitydomaine] = useState('');

    const navigate= useNavigate();


    const handleRegister = async () => {
      console.log(cgu)
      // if(cgu) {
      // }
      const loginInfo = await register(email, password, firstname, lastname, birthday, phone, siret, compagnyname, activitydomaine);
      console.log(loginInfo)
      if(!loginInfo) {
        toast.error('Erreur lors de l\'inscription');
      } else {
        toast.success('Inscription réussie');
        window.location.href = '/';
      }
    }

  return (
    <>
        <HeaderComponent />
        <div className='flex justify-center'>
            <div className='bg-grey flex flex-col items-center rounded-lg shadow-lg lg:w-2/6 p-8 my-12'>
                <h1 className='font-bold text-2xl pb-8'>Inscription</h1>
                <div className='w-3/4'>
                  <div className='my-2 py-1 w-full px-6 rounded-md border border-greyAccent bg-white'>
                    <label htmlFor="" className='text-greyAccent text-xs'>Nom</label>
                    <input onChange={(e) => setLastname(e.target.value)} className='w-full text-black font-semibold' type="text" placeholder="" id="" />
                  </div>
                  <div className='my-2 py-1 w-full px-6 rounded-md border border-greyAccent bg-white'>
                    <label htmlFor="" className='text-greyAccent text-xs'>Prénom</label>
                    <input onChange={(e) => setfirstname(e.target.value)} className='w-full text-black font-semibold' type="text" placeholder="" id="" />
                  </div>
                  <div className='my-2 py-1 w-full px-6 rounded-md border border-greyAccent bg-white'>
                    <label htmlFor="" className='text-greyAccent text-xs'>E-mail</label>
                    <input onChange={(e) => setEmail(e.target.value)} className='w-full text-black font-semibold' type="text" placeholder="" id="" />
                  </div>
                  <div className='my-2 py-1 w-full px-6 rounded-md border border-greyAccent bg-white'>
                    <label htmlFor="" className='text-greyAccent text-xs'>Téléphone</label>
                    <input onChange={(e) => setPhone(e.target.value)} className='w-full text-black font-semibold' type="text" placeholder="" id="" />
                  </div>
                  <div className='my-2 py-1 w-full px-6 rounded-md border border-greyAccent bg-white'>
                    <label htmlFor="" className='text-greyAccent text-xs'>Date de naissance</label>
                    <input onChange={(e) => setbirthday(e.target.value)} className='w-full text-black font-semibold' type="date" placeholder="" id="" />
                  </div>
                  <div className='my-2 py-1 w-full px-6 rounded-md border border-greyAccent bg-white'>
                    <label htmlFor="" className='text-greyAccent text-xs'>Mot de passe</label>
                    <div className="flex flex-row justify-between">
                      <input onChange={(e) => setpassword(e.target.value)} className='w-full text-black font-semibold' type={passwordView ? 'text': 'password'} placeholder="" id="" />
                      {passwordView ? (
                        <svg onClick={() => setPasswordView(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                      ) : (
                        <svg onClick={() => setPasswordView(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                        </svg>
                      )}
                    </div>
                  </div>
                  
                  <Input className={'uppercase'} label={'Nom de l\'entrerpise'} value={compagnyname} valueChanged={(value) => setCompagnyname(value)} />
                  <Input label={'Numéro de siret'} formatter={siretFormatter} value={siret} valueChanged={(value) => setSiret(value)} />
                  
                  <div className='my-2 py-1 w-full px-6 rounded-md border border-greyAccent bg-white'>
                    <label htmlFor="" className='text-greyAccent text-xs'>Domaine d'activité</label>
                    <input onChange={(e) => setActivitydomaine(e.target.value)} className='w-full text-black font-semibold' type="text" placeholder="" id="" />
                  </div>
                  <div className='p-2 flex items-start'>
                      <input onChange={(e) => setCgu(e.target.checked)} value={cgu} type="checkbox" className='my-1 mr-3 accent-black' name="cgu" id="cgu" />
                      <label htmlFor='cgu' className='text-black font-medium text-xs'>Les données collectées sont nécessaires pour permettre la gestion et le suivi de vos commandes. En créant votre compte vous acceptez notre politique de protection des données.</label>
                  </div>
                  <button onClick={() => handleRegister()} className='w-full bg-black text-white py-3 rounded-lg font-semibold my-4'>
                    Créer mon compte
                  </button>
                  <div className='text-center text-sm'>
                    Déjà un compte ? Se connecter
                  </div>
                </div>
            </div>
        </div>
        <FooterComponent />
    </>
  )
}

export default RegisterPage