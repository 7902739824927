import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../store/store';
import Input from '../html/input';
import CountrySelect from '../html/CountrySelect';
import { getImage } from '../../utils/someFunctions';

const CartesSavedAccoundComponent = ({onCardSelected, ischeckout = false, onAddCard}) => {
    
    const {getUser} = useAuthState();
    const [cards, setCards] = useState([]);
    
    const [loading, setLoading] = useState(true);
    const [selectCard, setselectCard] = useState(null);

    useEffect(() => {
        const formData = new FormData();
        formData.append('id_customer', getUser('id_customer'));
        formData.append('type', 'get');
        fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/customers/customerCards.php', {
           method: 'POST',
           body: formData
       })
       .then(response => response.json())
       .then(data =>  setCards(data.cards))
       .then(console.log(cards))
       .then(() => setLoading(false))
    }, [cards, loading])



    const deleteCard = (id) => {
        const formData = new FormData();
        formData.append('id_card', id);
        formData.append('type', 'delete');
        fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/customers/customerCards.php', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => console.log(data))
    }

    const CardSelect = (card) => {
        if(ischeckout) {
            onCardSelected(card.card_id);
            setselectCard(card.card_id);
        }
    }
    

  return (
    <>
    {loading == true &&
    <div>
        <div className="w-4/6">
            <div className="animate-pulse h-3 rounded-lg w-full bg-greySoft"></div>
            <div className="animate-pulse h-3 rounded-lg w-32 mt-4 bg-greySoft"></div>
            <div className="animate-pulse h-3 rounded-lg w-24 mt-4 bg-greySoft"></div>
            <div className="animate-pulse h-3 rounded-lg w-12 mt-4 bg-orange"></div>
        </div>
        <div className="w-1/6 flex items-center"></div>
    </div>
    }
    {loading == false &&
    <div>
        <div className="grid grid-cols-2 items-center">
            <div><h5 className='font-semibold text-xl mobile:text-sm py-4'>Mes moyens de paiement</h5></div>
            <div className='text-right'>
                {ischeckout == true && <button onClick={() => onAddCard()} className='mobile:text-xs'>+ Ajouter une nouvelle carte</button>}
                {/* {ischeckout && <button>Annuler</button>} */}
            </div>
        </div>



        <div className="grid grid-cols-2 gap-12 mobile:grid-cols-1 mobile:gap-3 py-4">
            {cards.map((card, index) => (
                <div onClick={() => CardSelect(card)} className={`border rounded-sm ${selectCard === card.card_id ? 'border-orange' : 'border-greySoft'}  ${ischeckout ? 'bg-white': ''}`}>
                <div className="p-4 text-sm">
                    <div className="grid grid-cols-2 items-center pb-4">
                        <div></div>
                        {cards.length > 1 &&
                            <div className='flex justify-end text-right right-0'>
                                <svg onClick={() => deleteCard(card.id)} className='ml-2' width="10" height="10" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.18031 7.17995L0.820312 0.819946" stroke="#151515" stroke-linecap="round" stroke-linejoin="bevel"/>
                                <path d="M7.18031 0.819946L0.820312 7.17995" stroke="#151515" stroke-linecap="round" stroke-linejoin="bevel"/>
                            </svg>
                            </div>
                        }
                    </div>
                    <div className="flex flex-row justify-between">
                        <div className='w-5/6'><p className='font-semibold pb-2'>{card.card_brand} se terminant par {card.card_lastnumber}</p></div>
                        <div className=''>
                            <img src={card.card_brand == 'Visa' ? getImage('pictoVisa.png'): getImage('pictoMastercard.png')} alt="" />
                        </div>
                    </div>
                    <p>{getUser('firstname')} {getUser('lastname')}</p>
                    <p>Date d'expiration : {card.card_expiry}</p>
                </div>
            </div>
            ))}
        </div>
    </div>
    }
    </>
  )
}

export default CartesSavedAccoundComponent