import React, { useEffect, useState } from 'react'
import HeaderComponent from '../components/headerComponent'
import { Link } from 'react-router-dom';
import FooterComponent from '../components/footerComponent';

const BlogPage = () => {


    const [articles, setArticles] = useState([]);

    const getArticles = async () => {
        const response = await fetch(process.env.REACT_APP_DIRECTUS_URL + 'items/blog', {referrerPolicy: "unsafe-url" });
        const data = await response.json();
        setArticles(data.data);
        console.log(data.data)
    }

    useEffect(() => {
        getArticles()
    }, []);

  return (
        <>
        <HeaderComponent />
        <div className='container mx-auto'>
            <div className="grid grid-cols-4 mobile:grid-cols-1 gap-6 py-12">
                {articles.map((article) => (
                    <div className='shadow-lg p-6 rounded-lg'>
                        <div className='h-[200px] rounded-lg' style={{
                            backgroundImage: `url(${process.env.REACT_APP_DIRECTUS_URL + 'assets/'+article.image_presentation})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: '#f1f1f1'
                        }}></div>
                        <p className='font-semibold text-lg py-6'>{article.article_name}</p>
                        <Link to={'/blog/'+article.id} className='bg-grey text-orange px-4 py-2 rounded-full'>Voir l'article</Link>
                    </div>
                ))}
            </div>
        </div>
        <FooterComponent />
        </>
  )
}

export default BlogPage