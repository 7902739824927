import React, { useEffect, useState } from 'react'
import { useAuthState } from '../store/store';
import HeaderComponent from '../components/headerComponent';
import { useOrders } from '../hooks/usePrestashopOrders';
import LoadingComponent from '../components/loadingComponent';
import OrderAccountComponent from '../components/accountComponents/orderComponent';
import InfoCustomerComponent from '../components/accountComponents/infosCustomerComponent';
import AdresseAccountComponent from '../components/accountComponents/adresseAccountComponent';
import { Link, useParams } from 'react-router-dom';
import CartesSavedAccoundComponent from '../components/accountComponents/cartesSavedAccoundComponent';
import FooterComponent from '../components/footerComponent';
import PasswordChangeComponent from '../components/accountComponents/passwordChangeComponent';

const MonComptePage = () => {

  const {tab} = useParams();

  const {getUser, logout, loadingUser} = useAuthState();
  const [accountTab, setAccounTab] = useState('');
  const {orders, loading, errors} = useOrders();

  const [email, setEmail] = useState('');

  

  return (
    <>
      {
        !getUser('id_customer') ? <LoadingComponent /> :
        <>
        <HeaderComponent />
        <div className="container mx-auto">
          <div className='flex flex-row items-center py-12'>
            <img className='pr-4' src={window.location.origin + '/images/profile-user 1.png'} alt="" />
            <p className='text-3xl'>{getUser('firstname')} {getUser('lastname')}</p>
          </div>
          <div className="lg:flex flex-row gap-6">
            <div className="w-full flex flex-col lg:w-1/3 font-medium">
              <Link to={'/mon-compte/information'} class={`${tab == 'information' ? 'bg-greySoft border-orange' : 'bg-white border-greySoft'} border-l-4 text-black p-4`}>
                Mes information
              </Link>
              <Link to={'/mon-compte/commandes'} class={`${tab == 'commandes' ? 'bg-greySoft border-orange' : 'bg-white border-greySoft'} border-l-4 text-black p-4`}>
                Mes commandes
              </Link>
              <Link to={'/mon-compte/adresses'} class={`${tab == 'adresses' ? 'bg-greySoft border-orange' : 'bg-white border-greySoft'} border-l-4 text-black p-4`}>
                Mes adresses
              </Link>
              <Link to={'/mon-compte/cartes'} class={`${tab == 'cartes' ? 'bg-greySoft border-orange' : 'bg-white border-greySoft'} border-l-4 text-black p-4`}>
                Mes moyens de paiement
              </Link>
              <Link to={'/mon-compte/mot-de-passe'} class={`${tab == 'mot-de-passe' ? 'bg-greySoft border-orange' : 'bg-white border-greySoft'} border-l-4 text-black p-4`}>
                Mot de passe
              </Link>
              <div onClick={() => logout()} class={`${accountTab == 6 ? 'bg-greySoft border-orange' : 'bg-white border-greySoft'} border-l-4 text-black p-4`}>
                Déconnexion
              </div>
            </div>
            <div className="pt-4 lg:pt-0 w-full lg:w-2/3">
                {tab == 'information' && (<InfoCustomerComponent />)}
                {tab == 'commandes' && (<OrderAccountComponent />)}
                {tab == 'adresses' && (<AdresseAccountComponent />)}
                {tab == 'cartes' && (<CartesSavedAccoundComponent />)}
                {tab == 'mot-de-passe' && (<PasswordChangeComponent />)}
            </div>
          </div>
        </div>
        <FooterComponent />
        </>
      }
    </>
  )
}

export default MonComptePage