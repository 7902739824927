import React from 'react'

const NotifyAddedToCart = ({product, text}) => {

  return (
    <div className='flex flex-row text-sm items-center !font-sans font-semibold text-black'>
        <img className='w-10 h-10' src={product.image} alt="" />
        <p className='pl-4'>{text}</p>
    </div>
  )
}

export default NotifyAddedToCart