import React, { useEffect, useState } from 'react'
import HeaderComponent from '../components/headerComponent'
import FooterComponent from '../components/footerComponent'
import { useParams } from 'react-router-dom';

const PagesContent = () => {

    const {slug} = useParams();
    const [content, setContent] = useState();

    const getContent = async () => {
      const response = await fetch(process.env.REACT_APP_DIRECTUS_URL + 'items/pages?filter[slug][_eq]='+slug,);
      const data = await response.json();
      setContent(data.data);
    }

  useEffect(() => {
    getContent()
}, [content]);

  return (
    <div>
        <HeaderComponent />
        <div class="container mx-auto py-12">
        {
          content ? 
          <div>
            <h1 className='text-center font-semibold text-3xl'>{content[0].titre}</h1>
            <div className='py-12' dangerouslySetInnerHTML={{ __html: content[0].contenu }}></div>
          </div>
          : ''
        }
        </div>
        <FooterComponent />
    </div>
  )
}

export default PagesContent