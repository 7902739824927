import React from 'react'

const Input = ({name, placeholder, type, className, label, valueChanged, value ,formatter}) => {

    const handleOnChange = (e) => {
        let inputValue = e.target.value;
        if (formatter) {
            inputValue = formatter(inputValue);
        }
        valueChanged(inputValue);
    };
  return (
    <div class="my-2 py-1 w-full px-4 rounded-md border border-greyAccent bg-white">
        <label for="" class="text-greyAccent text-xs">{label}</label>
        <input 
        className={`w-full text-black ${className}`}
        type={type}
        onChange={handleOnChange}
        name={name}
        value={value}
        placeholder={placeholder}
        />    
    </div>
  )
}

export default Input