import React, { useState } from 'react'
import { useAuthState } from '../../store/store';
import { Link, useNavigate } from 'react-router-dom';

const MonCompteDropDown = () => {

    const {isAuthenticated, getUser, logout, loadingUser} = useAuthState();
    const navigate = useNavigate();
    const [customerMenuOpen, setCustomerMenuOpen] = useState(false);

    
    const handleAuthenticatedAction = () => {
        if(isAuthenticated()) {
            setCustomerMenuOpen(!customerMenuOpen);
        } else {
            setCustomerMenuOpen(false);
            navigate('/login');
        }
    }

  return (
    <div className='flex flex-row relative' onClick={() => handleAuthenticatedAction()}>
        <div className='text-left'>
            <p className='text-xs'>Bonjour, {isAuthenticated() && loadingUser() == false ? <span className='font-semibold text-ellipsis'>{getUser('firstname') + ' ' + getUser('lastname')}</span> : 'identifiez-vous'}</p>
            <p className='font-semibold text-sm'>Mon compte</p>
        </div>
        <div className='pl-3'>
            <img className='' src="/images/ic-actions-user.png" alt="" />
        </div>
        <div className={`w-72 p-3 absolute bg-white rounded-md top-10 -left-24 z-20 account-card-menu transition-all duration-300 ease-in-out delay-150 shadow-lg ${isAuthenticated && customerMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
            <ul className='[&_li:hover]:bg-grey '>
                <li className='p-2 font-medium flex flex-row rounded-md'> <img className='pr-3' src={window.location.origin + '/images/account-circle-line.svg'} alt="" /> <Link to={'/mon-compte/information'}>Mes informations</Link></li>
                <li className='p-2 font-medium flex flex-row rounded-md'><img className='pr-3' src={window.location.origin + '/images/box-3-line.svg'} alt="" /> <Link to={'/mon-compte/commandes'}>Mes commandes</Link></li>
                <li className='p-2 font-medium flex flex-row rounded-md'><img className='pr-3' src={window.location.origin + '/images/map-pin-line.svg'} alt="" /> <Link to={'/mon-compte/adresses'}>Mes adresses</Link></li>
                <li className='p-2 font-medium flex flex-row rounded-md'><img className='pr-3' src={window.location.origin + '/images/bank-card-2-line.svg'} alt="" /> <Link to={'/mon-compte/cartes'}>Mes moyens de paiement</Link></li>
                <li className='p-2 font-medium flex flex-row rounded-md'><img className='pr-3' src={window.location.origin + '/images/lock-line.svg'} alt="" /> <Link to={'/mon-compte/mot-de-passe'}>Mot de passe</Link></li>
                <li onClick={() => logout()} className='p-2 font-medium flex flex-row rounded-md'><img className='pr-3' src={window.location.origin + '/images/logout-box-r-line.svg'} alt="" /> Déconnexion</li>
            </ul>
        </div>
    </div>
  )
}

export default MonCompteDropDown