import React from 'react'
import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
import ProductCardComponent from './productCardComponent';


const SliderProductComponent = ({products}) => {
    
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        responsive: [
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
        ]
      };
  return (
    <>
        <Slider {...settings}>
            {
                products.map((product) => (
                    
                    <div className=''>
                      <ProductCardComponent key={product.id} product={product} />
                    </div>
                    
                ))
            }
        </Slider>
    </>
  )
}

export default SliderProductComponent