import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "../store/store";

const AuthGuard = ({ children }) => {
    const { isAuthenticated, checkToken } = useAuthState();
    const navigate = useNavigate();
  
    useEffect(() => {
        const checkAuthentication = async () => {
            console.log(await isAuthenticated());
            console.log('TOKEN AUTH VALIDE ? ' + await checkToken());
            if ((isAuthenticated()) === false || (await checkToken()) === false) {
                // Rediriger vers la page de connexion si l'utilisateur n'est pas authentifié
                navigate('/login');
            }
        };

        checkAuthentication();
    }, [isAuthenticated, checkToken, navigate]);
  
    return children;
};
  
  export default AuthGuard;