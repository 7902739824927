export const siretFormatter = (value) => {
    // Supprimer tous les caractères non numériques
    const cleaned = value.replace(/\D/g, '').slice(0, 14); // Limiter à 14 caractères

    // Insérer des espaces après chaque bloc de chiffres
    const formatted = cleaned.replace(/^(\d{3})?(\d{3})?(\d{3})?(\d{0,5})?$/, function(_, p1, p2, p3, p4) {
        let result = '';
        if (p1) result += p1;
        if (p2) result += ' ' + p2;
        if (p3) result += ' ' + p3;
        if (p4) result += ' ' + p4;
        return result.trim();
    });

    return formatted;
};


export const tvaFormatter = (value) => {
    // Supprimer tous les caractères non numériques
    const cleaned = value.replace(/\D/g, '');

    // Formater le numéro de TVA
    let formatted = cleaned;
    if (cleaned.length > 2) {
        formatted = cleaned.substring(0, 2) + ' ' + cleaned.substring(2);
    }
    if (cleaned.length > 5) {
        formatted = formatted.substring(0, 5) + ' ' + formatted.substring(5);
    }
    if (cleaned.length > 8) {
        formatted = formatted.substring(0, 8) + ' ' + formatted.substring(8);
    }
    if (cleaned.length > 12) {
        formatted = formatted.substring(0, 12) + ' ' + formatted.substring(12);
    }

    return formatted.trim();
};


export const phoneFormatter = (value) => {
    // Supprimer tous les caractères non numériques
    const cleaned = value.replace(/\D/g, '');

    // Limiter à 10 chiffres pour correspondre à un numéro de téléphone français
    const formatted = cleaned.slice(0, 10);

    // Formater le numéro de téléphone
    let formattedNumber = '';
    for (let i = 0; i < formatted.length; i++) {
        formattedNumber += formatted[i];
        if ((i + 1) % 2 === 0 && i < formatted.length - 1) {
            formattedNumber += ' ';
        }
    }

    return formattedNumber.trim();
};



export const creditCardFormatter = (value) => {
    // Supprimer tous les caractères non numériques
    const cleaned = value.replace(/\D/g, '');

    // Formater le numéro de carte bancaire en groupes de 4 chiffres
    let formatted = '';
    for (let i = 0; i < cleaned.length; i++) {
        formatted += cleaned[i];
        // Ajouter un espace après chaque groupe de 4 chiffres, mais seulement si c'est le dernier groupe
        if ((i + 1) % 4 === 0 && i < cleaned.length - 1) {
            formatted += ' ';
        }
    }

    // Limiter à un total de 4 groupes de chiffres
    formatted = formatted.substring(0, 19);

    return formatted.trim();
};


export const DD_MM_formatter = (value) => {
    // Supprimer tous les caractères non numériques
    const cleaned = value.replace(/\D/g, '');

    // Formater la date en MM/YY
    let formatted = '';
    for (let i = 0; i < cleaned.length; i++) {
        formatted += cleaned[i];
        // Ajouter un "/" après le mois
        if (i === 1 && i < cleaned.length - 1) {
            formatted += '/';
        }
    }

    // Limiter à une longueur maximale de 5 caractères (MM/YY)
    formatted = formatted.substring(0, 5);

    return formatted.trim();
};


export const cvcFormatter = (value) => {
    // Supprimer tous les caractères non numériques
    const cleaned = value.replace(/\D/g, '');

    // Limiter à une longueur maximale de 3 caractères
    const formatted = cleaned.slice(0, 3);

    return formatted;
};

export const dateFormatFrench = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString('fr-FR', options);
}