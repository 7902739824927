import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../store/store';
import Input from '../html/input';
import CountrySelect from '../html/CountrySelect';
import { toast } from 'react-toastify';

const PasswordChangeComponent = ({onAdressSelected, ischeckout = false}) => {
    
    const {getUser} = useAuthState();
    const [addresses, setAddresses] = useState([]);
    const [formPassword, setformPassword] = useState({});

   const getFormData = (key, defaultValue) => {
        return formPassword[key] ? formPassword[key] : defaultValue;
    }

    const setFormData = (key, value) => {
        setformPassword({
            ...formPassword,
            [key]: value
        });
    }

    const savePassword = () => {

        // verifier si les champs sont remplis
        if (getFormData('old_password') && getFormData('new_password') && getFormData('new_password') === getFormData('confirm_new_password')) {
            
          } else {
            toast.error('Veuillez vérifier les mots de passe saisis');
            return;
          }
       
        const formData = new FormData();
         // Lors de l'utilisation de formPassword.iso_code, vérifiez d'abord si formPassword et iso_code sont définis

        formData.append('email', getUser('email'));
        formData.append('old_password', getFormData('old_password'));
        formData.append('new_password', getFormData('new_password'));
       
        fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/auth/ChangePassword.php', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            if(data.success) {
                toast.success('Mot de passe modifié avec succès');
            } else {
                toast.error('Une erreur est survenue lors de la modification du mot de passe');
            }
        })

    }
    

  return (
    <>
    
    <div>
        <div className="grid grid-cols-2">
            <div><h2 className='font-semibold text-xl'>Modifier le mot de passe du compte</h2></div>
        </div>



        <div className='my-2 py-1 w-full px-2 rounded-md border border-greyAccent bg-white'>
            <label htmlFor="" className='text-greyAccent text-xs'>Entrez le mot de passe actuel</label>
            <input onChange={(e) => setFormData('old_password', e.target.value)} value={getFormData('old_password')} className='w-full text-black font-medium' type="password" placeholder="" id="" />
        </div>
        <div className='my-2 py-1 w-full px-2 rounded-md border border-greyAccent bg-white'>
            <label htmlFor="" className='text-greyAccent text-xs'>Entrez le nouveau mot de passe</label>
            <input onChange={(e) => setFormData('new_password', e.target.value)} value={getFormData('new_password')} className='w-full text-black font-medium' type="password" placeholder="" id="" />
        </div>
        <div className='my-2 py-1 w-full px-2 rounded-md border border-greyAccent bg-white'>
            <label htmlFor="" className='text-greyAccent text-xs'>Confirmez le nouveau mot de passe</label>
            <input onChange={(e) => setFormData('confirm_new_password', e.target.value)} value={getFormData('confirm_new_password')} className='w-full text-black font-medium' type="password" placeholder="" id="" />
        </div>
        <div class="flex justify-end py-4">
            <button onClick={(e) => savePassword()} className='bg-green font-semibold text-white rounded-lg px-4 py-2'>Enregistrer</button>
        </div>
    </div>
    
    </>
  )
}

export default PasswordChangeComponent