import { useState } from "react";
import { useProduct, useProductsCategoryName } from "../hooks/usePrestashopProducts";
import { useCartState } from "../store/store";
import { Link } from "react-router-dom";
import { toPrice } from "../utils/someFunctions";
import SliderProductComponent from "./sliderProductsComponent";
import ProductCardComponent from "./productCardComponent";

export default function Cart({ isOpen, onClose }) {

    const { getCartNumberItems, getCart, removeFromCart, updateQuantity, getTotalCartHT, getTotalCartTVA, getTotalCartTTC } = useCartState();
    const cartItems = getCart();
    const numberOfItems = getCartNumberItems();
    const [total, setTotal] = useState(0);

    return (
    <div>
      {/* {isOpen && ( */}
        <div className={`fixed inset-0 overflow-y-auto ${isOpen ? 'z-50' : '-z-50'} flex items-center justify-center`}>
        <div className="fixed inset-0 transition-opacity">
          <div
            className={`absolute inset-0  ${isOpen ? 'bg-black opacity-50' : ''}`}
            onClick={onClose}
          ></div>
        </div>
        <div className={`fixed inset-y-0 right-0 w-2/5 mobile:w-full bg-white flex flex-col  z-10 transform overflow-y-auto ${ isOpen ? "translate-x-0" : "translate-x-full"} transition-transform ease-in-out duration-300`}>
          <div className="p-8 flex justify-between">
            <h2 className="text-2xl font-semibold">Panier</h2>
            <button onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-col justify-between grow p-8">
            <div>
            {cartItems.length > 0 ?   cartItems.map((item) => (
                <CartItem id={item.product_id} key={item} item={item} />
            )) :
            (
                <div className="flex flex-col items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="61" height="68" viewBox="0 0 61 68" fill="none">
                        <path d="M11.6828 0.666656H48.3495C49.3985 0.666656 50.3865 1.16062 51.0162 1.99999L60.0162 14V64C60.0162 65.841 58.5238 67.3333 56.6828 67.3333H3.34945C1.50851 67.3333 0.0161133 65.841 0.0161133 64V14L9.01611 1.99999C9.64565 1.16062 10.6336 0.666656 11.6828 0.666656ZM53.3495 20.6667H6.68278V60.6667H53.3495V20.6667ZM51.6828 14L46.6828 7.33332H13.3494L8.34945 14H51.6828ZM20.0161 27.3333V34C20.0161 39.523 24.4932 44 30.0162 44C35.5388 44 40.0162 39.523 40.0162 34V27.3333H46.6828V34C46.6828 43.2047 39.2208 50.6667 30.0162 50.6667C20.8114 50.6667 13.3494 43.2047 13.3494 34V27.3333H20.0161Z" fill="#D1D1D1"/>
                    </svg>
                    <p className="text-center text-[#BABABA] pt-6">Votre panier est vide</p>
                </div>
            )
            }
            </div>
          </div>
          {numberOfItems > 0 && (
            <div className="bg-grey p-8 justify-self-end">
            <div className="grid grid-cols-2">
                <div>
                    <p className="py-2">Sous-total</p>
                    <p className="py-2">TVA 20 %</p>
                    <p className="font-bold py-2">TOTAL</p>
                </div>
                <div className="text-right">
                    <p className="py-2">{toPrice(getTotalCartHT())}</p>
                    <p className="py-2">{toPrice(getTotalCartTVA())}</p>
                    <p className="font-bold py-2">{toPrice(getTotalCartTTC())}</p>
                </div>
            </div>
            <div className="h-0.5 bg-greySoft w-full"></div>
            <div className="pt-6 flex justify-end">
                <Link to={'/passer-commande'} className="bg-green font-semibold text-white rounded-lg px-4 py-2 flex flex-row items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M4.33651 1.33325H11.6699C11.8797 1.33325 12.0773 1.43205 12.2032 1.59992L14.0032 3.99992V13.9999C14.0032 14.3681 13.7047 14.6666 13.3365 14.6666H2.66984C2.30165 14.6666 2.00317 14.3681 2.00317 13.9999V3.99992L3.80317 1.59992C3.92908 1.43205 4.12667 1.33325 4.33651 1.33325ZM12.3365 3.99992L11.3365 2.66659H4.66984L3.66984 3.99992H12.3365ZM6.00317 6.66659H4.66984V7.99992C4.66984 9.84085 6.16223 11.3333 8.00319 11.3333C9.84412 11.3333 11.3365 9.84085 11.3365 7.99992V6.66659H10.0032V7.99992C10.0032 9.10452 9.10772 9.99992 8.00319 9.99992C6.89859 9.99992 6.00317 9.10452 6.00317 7.99992V6.66659Z" fill="white"/>
                    </svg>
                    <span className="pl-3">Valider mon panier</span>
                </Link>
            </div>
            </div>
          )}
        </div>
      </div>
      {/* )} */}
    </div>
  );
}


export const CartItem = ({ item, allowQte = true}) => {

    const { product, loading, error } = useProduct('id_product', item.product_id);
    const { updateQuantity, removeFromCart } = useCartState();
  
    return (
       <div className="justify-between py-6 border-b-greySoft border-b">
      <div className="grid grid-cols-3 mobile:grid-cols-2 ">
    {loading ? (
        <>
            <div className="w-1/6 rounded-lg flex flex-col"><div className="animate-pulse w-16 h-16 bg-greySoft rounded-lg"></div></div>
            <div className="w-4/6">
                <div className="animate-pulse h-3 rounded-lg w-full bg-greySoft"></div>
                <div className="animate-pulse h-3 rounded-lg w-32 mt-4 bg-greySoft"></div>
                <div className="animate-pulse h-3 rounded-lg w-24 mt-4 bg-greySoft"></div>
                <div className="animate-pulse h-3 rounded-lg w-12 mt-4 bg-orange"></div>
            </div>
            <div className="w-1/6 flex items-center"></div>
        </>
      ) : (
        <>
        <div className=" rounded-lg flex flex-col">
            <div className={`w-2/3 bg-grey p-2 rounded-lg`}>
                <img className="mix-blend-multiply" src={item.image} alt="" />
            </div>
            {allowQte && (
                <button className="flex flex-row items-center pt-3" onClick={() => removeFromCart(item.product_id) }>
                    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Layer">
                        <g id="Vrstva 96">
                        <path id="Vector" d="M7.17982 7.18007L0.819824 0.820068" stroke="#151515" stroke-linecap="round" stroke-linejoin="bevel"/>
                        <path id="Vector_2" d="M7.17982 0.820068L0.819824 7.18007" stroke="#151515" stroke-linecap="round" stroke-linejoin="bevel"/>
                        </g>
                        </g>
                    </svg>
                    <span className="pl-2 font-light text-xs">Supprimer</span>
                </button>
            )}
        </div>
        <div className="">
            <p className="font-semibold text-lg truncate ...">{product.name}</p>
            <p className="italic"><span className="text-greyAccent pr-14 text-sm not-italic">Ref: </span> {product.reference}</p>
            <p className=""><span className="text-greyAccent pr-4 text-sm">Livraison: </span> Livré en 24h</p>
            <div className="py-3">
                {product.old_price_ht && product.old_price_ht != product.price_ht && (
                    <p className='text-[#A9A9A9] line-through text-sm'>{toPrice(product.old_price_ht)} HT</p>
                )}
                <p className='text-orange font-semibold text-xl'>{toPrice(product.price_ht)} HT</p>
                <p className='text-black font-semibold'>{toPrice(product.price_ttc)} TTC</p>
            </div>
        </div>
        <div className="flex items-center">
            <div className=" p-3 flex flex-row items-center">
                {allowQte && (
                    <button className="px-4 rounded-full text-xl bg-orange text-white" onClick={() => updateQuantity(item, item.quantity - 1)}>-</button>
                )}
                <span className='px-6 text-2xl'>{item.quantity} {!allowQte && ('pcs')} </span>
                {allowQte && (
                     <button className="px-4 rounded-full text-xl bg-orange text-white" onClick={() => updateQuantity(item, item.quantity + 1)}>+</button>
                )}
            </div>
        </div>
    </>
      )}
      
      </div>
      <div className="flex flex-row">
        <div className="w-1/3 mobile:hidden"></div>
        <div>
              {item.garanties && item.garanties.map((garantie) => (
                  <div className="flex flex-wrap items-center text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                            <circle cx="7" cy="7.5" r="4" fill="#F1671A"/>
                            <circle cx="7" cy="7.5" r="5.5" stroke="#F1671A" stroke-opacity="0.24" stroke-width="3"/>
                        </svg>
                        <span className="pl-3 font-semibold mobile:text-xs">{garantie.name}</span>
                        <span className="pl-6 mobile:pl-0 font-light text-xs"> + {toPrice(garantie.price)}</span>
                    </div>  
                ))}
                {item.options && item.options.map((option) => (
                    <div className="flex flex-row items-center text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                            <circle cx="7" cy="7.5" r="4" fill="#F1671A"/>
                            <circle cx="7" cy="7.5" r="5.5" stroke="#F1671A" stroke-opacity="0.24" stroke-width="3"/>
                        </svg>
                        <span className="pl-3 font-semibold mobile:text-xs">{option.name}</span>
                        <span className="pl-6 mobile:pl-0 font-light text-xs"> + {toPrice(option.price)}</span>
                    </div>  
                ))}
                {item.recyclage != '' && (
                    <div className="flex flex-row items-center text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                            <circle cx="7" cy="7.5" r="4" fill="#F1671A"/>
                            <circle cx="7" cy="7.5" r="5.5" stroke="#F1671A" stroke-opacity="0.24" stroke-width="3"/>
                        </svg>
                        <span className="pl-3 font-semibold mobile:text-xs">Reprise matériel</span>
                        <span className="pl-6 mobile:pl-0 font-light text-xs"> - {toPrice(item.recyclage.price)}</span>
                    </div>  
                )}
                {item.cadeau != '' && item.cadeau  && (
                    <div className="flex flex-row items-center text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                            <circle cx="7" cy="7.5" r="4" fill="#F1671A"/>
                            <circle cx="7" cy="7.5" r="5.5" stroke="#F1671A" stroke-opacity="0.24" stroke-width="3"/>
                        </svg>
                        <span className="pl-3 font-semibold mobile:text-xs">{item.cadeau.name} offert</span>
                    </div>  
                )}
        </div>
      </div>
        
      </div>
    );
  };


export const CartCrossSelling = ({ item, allowQte = true}) => {

    const { product, loading, error } = useProduct('id_product', item.product_id);
    const { setNewOption } = useCartState();
    const { products: productsCat, error: errorCat, loading: loadingCat } = useProductsCategoryName(product.categorie_name_defaut, 2);

  
    return (
       <div className="justify-between py-6 border-b-greySoft">
    {loading ? (
        <>
            {
                [1, 2, 3].map((i) => (
                    <>
                        <div className="animate-pulse w-full h-4 my-4 bg-greySoft rounded-full"></div>
                        <div className="animate-pulse w-2/3 h-4 my-4 bg-greySoft rounded-full"></div>
                        <div className="animate-pulse w-1/3 h-4 my-4 bg-greySoft rounded-full"></div>
                        <div className="animate-pulse w-2/3 h-4 my-4 bg-greySoft rounded-full"></div>
                        <div className="animate-pulse w-full h-4 my-4 bg-greySoft rounded-full"></div>
                    </>
                ))
            }
        </>
      ) : (
        <>

            
            {product.garanties && product.garanties != '' && (
                <>
                <h1 className='text-3xl text-black font-extrabold'>N’oubliez pas vos options, protégez votre matériel</h1>
                <p className='py-5'>Choisissez nos extensions de garantie et préservez votre {product.name} en toute quiétude !</p>
                <div className="grid grid-cols-3 mobile:grid-cols-1 gap-3">
                    {product.garanties != '' && product.garanties.map((garantie, i) => (
                        <div key={i} onClick={() =>  setNewOption(item, 'garanties', i, garantie, false)} className={`border-2 rounded-lg p-2 cursor-pointer ${ item.garanties && item.garanties.some(opt => opt.name === garantie.name) ? 'bg-orange text-white border-black' : 'bg-white text-black border-greySoft' }`}>
                            <p className=" font-bold">Garantie {garantie.name}</p>
                            <p className="font-light text-xs">{garantie.description}</p>
                            <p className="italic text-right">+ {toPrice(garantie.price)}</p>
                        </div>
                    ))}
                </div>
                </>
            )}
            {product.options && product.options != '' && (
                <div className="py-4">
                <h1 className='text-xl text-black font-extrabold py-5'>Choisissez des options supplémentaires</h1>
                <div className="grid grid-cols-1 gap-3">
                    {product.options.map((option, i) => (
                        <div key={i} onClick={() => {setNewOption(item, 'options', i, option, true)}} className={`border-2 rounded-lg p-2 cursor-pointer ${item.options && item.options.some(opt => opt.name === option.name) ? 'bg-orange text-white border-black' : 'bg-white text-black border-greySoft' }`}>
                            <p className=" font-bold">{option.name}</p>
                            <p className="font-light text-xs">{option.description}</p>
                            <p className="italic text-right">+ {toPrice(option.price)}</p>
                        </div>
                    ))}
                </div>
                </div>
            )}
            <div>
                <h1 className='text-2xl text-black font-extrabold'>Ces produits pourraient également vous intéressé</h1>
                <div className="grid grid-cols-3 mobile:grid-cols-1">
                 {productsCat.shuffle().slice(0, 3).map((product) => (
                    <ProductCardComponent key={product.id} product={product} />
                 ))}
                </div>
            </div>
        
        </>
      )}
      
      </div>
    );
  };


