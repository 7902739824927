import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../store/store';
import Input from '../html/input';
import CountrySelect from '../html/CountrySelect';
import { toast } from 'react-toastify';

const AdresseAccountComponent = ({onAdressSelected, ischeckout = false}) => {
    
    const {getUser} = useAuthState();
    const [addresses, setAddresses] = useState([]);
    const [formAdress, setFormAdress] = useState({});

   

    const [tooggleAdress, setTooggleAdress] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedAdress, setSelectedAdress] = useState(null);

    const getAdresses = () => {
        const formData = new FormData();
        formData.append('id_customer', getUser('id_customer'));
        formData.append('type', 'get');
        fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/customers/customerAdresses.php', {
           method: 'POST',
           body: formData
       })
       .then(response => response.json())
       .then(data =>  setAddresses(data.adresses))
       .then(() => setLoading(false))
    }

    useEffect(() => {
        getAdresses();
   }, [tooggleAdress, addresses])

   const editAdresse = (id) => {
    setTooggleAdress(true);
    setFormAdress(addresses.find(address => address.id_address == id));
   }

   const createAdresse = () => {
    setTooggleAdress(true);
    setFormAdress({});
   }

   const getFormData = (key, defaultValue) => {
        return formAdress[key] ? formAdress[key] : defaultValue;
    }

    const setFormData = (key, value) => {
        setFormAdress({
            ...formAdress,
            [key]: value
        });
    }

    const saveAdresse = (id = null) => {

        // verifier si les champs sont remplis
        if (getFormData('company') && getFormData('lastname') && getFormData('firstname') && getFormData('address1') && getFormData('city') && getFormData('postcode')) {
            
          } else {
            toast.error('Veuillez vérifier tous les champs du formulaire');
            return;
          }
       
        const formData = new FormData();
         // Lors de l'utilisation de formAdress.iso_code, vérifiez d'abord si formAdress et iso_code sont définis

        formData.append('id_customer', getUser('id_customer'));
        formData.append('alias', getFormData('alias', 'Adresse'));
        formData.append('company', getFormData('company'));
        formData.append('lastname', getFormData('lastname'));
        formData.append('firstname', getFormData('firstname'));
        formData.append('address1', getFormData('address1'));
        formData.append('city', getFormData('city'));
        formData.append('iso_code', getFormData('iso_code', 'fr'));
        formData.append('postcode', getFormData('postcode'));
        if(id) {
            formData.append('id_address', id);
            formData.append('type', 'update');
            console.log('update');
            console.log(id);
        } else {
            formData.append('type', 'create');
        }
        fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/customers/customerAdresses.php', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => console.log(data))

        setTooggleAdress(false);
    }

    const deleteAdresse = (id) => {
        const formData = new FormData();
        formData.append('id_address', id);
        formData.append('type', 'delete');
        fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/customers/customerAdresses.php', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => console.log(data))
    }

    const AdressSelect = (address) => {
        if(ischeckout) {
            onAdressSelected(address.id_address);
            setSelectedAdress(address.id_address);
        }
    }
    

  return (
    <>
    {loading == true &&
    <div>
        <div className="w-4/6">
            <div className="animate-pulse h-3 rounded-lg w-full bg-greySoft"></div>
            <div className="animate-pulse h-3 rounded-lg w-32 mt-4 bg-greySoft"></div>
            <div className="animate-pulse h-3 rounded-lg w-24 mt-4 bg-greySoft"></div>
            <div className="animate-pulse h-3 rounded-lg w-12 mt-4 bg-orange"></div>
        </div>
        <div className="w-1/6 flex items-center"></div>
    </div>
    }
    {loading == false &&
    <div>
        <div className="grid grid-cols-2">
            <div><h2 className='font-semibold text-xl'>Mes adresses</h2></div>
            <div className='text-right'>
                {tooggleAdress == false && <button onClick={() => createAdresse()}>+ Ajouter une adresse</button>}
                {tooggleAdress && <button onClick={() => setTooggleAdress(!tooggleAdress)}>Annuler</button>}
            </div>
        </div>

        {tooggleAdress && (
            <div className="grid grid-cols-2 mobile:grid-cols-1 mobile:gap-0 gap-6 py-6">
                <Input valueChanged={(v) => setFormData('alias', v)} value={getFormData('alias')} placeholder={'Alias de l\'adresse'} label={"Alias"} />
                <Input valueChanged={(v) => setFormData('company', v)} value={getFormData('company')} placeholder={'Nom de la société'} label={"Société"} />
                <Input valueChanged={(v) => setFormData('lastname', v)} value={getFormData('lastname')} placeholder={'Nom'} label={"Nom"} />
                <Input valueChanged={(v) => setFormData('firstname', v)} value={getFormData('firstname')} placeholder={'Prénom'} label={"Prénom"} />
                <Input valueChanged={(v) => setFormData('address1', v)} value={getFormData('address1')} placeholder={'Adresse'} label={"Adresse postale"} />
                <Input valueChanged={(v) => setFormData('city', v)} value={getFormData('city')} placeholder={'Ville'} label={"Ville"} />
                <CountrySelect onChange={(v) => setFormData('iso_code', v.target.value)} valueSelected={getFormData('iso_code')}  />
                <Input valueChanged={(v) => setFormData('postcode', v)} value={getFormData('postcode')} placeholder={'Code postal'} label={"Code postal"} />
                <div></div>
                <div className='text-right'><button onClick={() => saveAdresse(getFormData('id_address'))} className='bg-green font-semibold text-white rounded-lg px-4 py-2'>Enregistrer l'adresse</button></div>
            </div>
        )}


        <div className="grid grid-cols-2 gap-12 mobile:grid-cols-1 mobile:gap-3 py-4">
            {tooggleAdress == false && addresses.map((address, index) => (
                <div onClick={() => AdressSelect(address)} className={`border rounded-sm ${selectedAdress == address.id_address ? 'border-orange' : 'border-greySoft'}`}>
                <div className="p-4 text-sm">
                    <div className="grid grid-cols-2 items-center justify-between">
                        <div><p className='font-semibold text-sm py-2'>{address.alias}</p></div>
                        <div className='flex justify-end items-center'>
                            <svg onClick={() => editAdresse(address.id_address)} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.1716 0.997792L7.83827 2.33113H1.33333V11.6645H10.6667V5.15955L12 3.82622V12.3312C12 12.6993 11.7015 12.9978 11.3333 12.9978H0.666667C0.29848 12.9978 0 12.6993 0 12.3312V1.66446C0 1.29627 0.29848 0.997792 0.666667 0.997792H9.1716ZM11.6569 0.398132L12.5997 1.34094L6.4714 7.46922L5.53027 7.47082L5.5286 6.52642L11.6569 0.398132Z" fill="#4E4E4E"/>
                            </svg>
                            {addresses.length > 1 &&
                                <svg onClick={() => deleteAdresse(address.id_address)} className='ml-2' width="10" height="10" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.18031 7.17995L0.820312 0.819946" stroke="#151515" stroke-linecap="round" stroke-linejoin="bevel"/>
                                    <path d="M7.18031 0.819946L0.820312 7.17995" stroke="#151515" stroke-linecap="round" stroke-linejoin="bevel"/>
                                </svg>
                            }
                        </div>
                    </div>
                    <p>{address.firstname} {address.lastname}</p>
                    <p>{address.address1}</p>
                    <p>{address.postcode}, {address.city}</p>
                    <p>{address.country}</p>
                </div>
            </div>
            ))}
        </div>
    </div>
    }
    </>
  )
}

export default AdresseAccountComponent