import React, { useEffect, useState } from "react";
import api, { apiKEY, apiURL } from "../utils/fetch";
import { isValidReduction } from "../utils/someFunctions";
import { useCategories } from "./usePrestashopCategory";
import { useCategorieChoosedParams } from "../store/store";


// /**
//  * Recupère les produits de la categorie avec l'id de catégorie
//  * @param {any} id
//  * @returns {any}
//  */
// const useProductCategorie = (id, limit = '') => {

//   const [productByCat, setProductByCat] = useState([])
//   const [error,setError] = useState(null)
//   const [loading,setLoading] = useState(false)

//   useEffect(() => {
//       (
//           async function(){
//               try{
//                   setLoading(true)
//                   const categorieData = await api(`categories/${id}`, `limit=[8]`);
      
//                   // Use map to create an array of promises
//                   const productPromises = categorieData.category.associations.products.map(async (element) => {
//                     const product = await api(`products/${element.id}`, 'filter[state]=1');
//                     const catName = await api(`categories/${product.product.id_category_default}`);
//                     const productPrices = await api(`taxes/${product.product.id_tax_rules_group}`);

//                     product.product['category_default_name'] = catName.category.name;
//                     product.product['category_link_rewrite'] = catName.category.link_rewrite;
//                     product.product['price_ttc'] = (1 + productPrices.tax.rate / 100) * product.product.price;
//                     try {
//                         const productPrice = await api(`specific_prices`, `display=full&filter[id_product]=${element.id}`);
                        

//                         // Changement des prix si il y a une réduction en cours
//                         productPrice.specific_prices.forEach(specificPrice => {
//                             if(isValidReduction(specificPrice.from, specificPrice.to) && specificPrice.reduction_type == 'amount') {
//                                 product.product['specific_prices'] = productPrice.specific_prices;
//                                 product.product['price_ht_without_reduction'] = product.product['price']
//                                 product.product['price_ttc'] = ((1 + productPrices.tax.rate / 100) * product.product.price) - parseFloat(specificPrice.reduction);
//                                 product.product['price'] = product.product.price - parseFloat(specificPrice.reduction);
//                             }
//                             if(isValidReduction(specificPrice.from, specificPrice.to) && specificPrice.reduction_type == 'percentage') {
//                                 product.product['specific_prices'] = productPrice.specific_prices;
//                                 product.product['price_ht_without_reduction'] = product.product['price']
//                                 product.product['price_ttc'] = ((1 + productPrices.tax.rate / 100) * product.product.price) * parseFloat(1-specificPrice.reduction);
//                                 product.product['price'] = product.product.price * parseFloat(1-specificPrice.reduction);
//                             }
//                         });
//                     } catch (error) {
                        
//                     }
//                     return product.product;
//                   });
                
//                   // Update the state with the fetched products
//                   setProductByCat(await Promise.all(productPromises));
//               }catch(err){
//                   setError(err)
//               }finally{
//                   setLoading(false)
//               }
//           }
//       )()
//   }, [id])

//   return { productByCat, error, loading }

// }



// /**
//  * Récupère un produit par son link_rewrite
//  * @param {any} id=''
//  * @param {any} linkrewrite=''
//  * @returns {any}
//  */
// const useProduct = (LINKREWRITE = '') => {

//   const [product, setProduct] = useState([])
//   const [error,setError] = useState(null)
//   const [loading,setLoading] = useState(false)

//   useEffect(() => {
//       (
//           async function(){
//               try{
//                   setLoading(true)

//                     // Récupération du produit
//                     const productData = await api(`products/${LINKREWRITE}`);

//                     // Ajout des images au produit
//                     let productImages = [];
//                     productData.product.associations.images.forEach( element => {
//                         productImages.push(`${apiURL}/api/images/products/${productData.product.id}/${element.id}?ws_key=${apiKEY}`)
//                     });
//                     productData.product['images'] = productImages;


//                     // Ajout de l'image par défaut
//                     productData.product['image_defaut'] = `${apiURL}/api/images/products/${productData.product.id}/${productData.product.id_default_image}?ws_key=${apiKEY}`;


//                     // Ajout du prix TTC
//                     const productPrices = await api(`taxes/${productData.product.id_tax_rules_group}`);
//                     productData.product['price_ttc'] = (1 + productPrices.tax.rate / 100) * productData.product.price;



//                     // Ajout des prix spécifiques
//                     try {
//                         const productPrice = await api(`specific_prices`, `display=full&filter[id_product]=${productData.product.id}`);
//                         productData.product['specific_prices'] = productPrice.specific_prices;
//                         productData.product['price_ht_without_reduction'] = productData.product['price']

//                         // Changement des prix si il y a une réduction en cours
//                         productPrice.specific_prices.forEach(specificPrice => {
//                             if(isValidReduction(specificPrice.from, specificPrice.to) && specificPrice.reduction_type == 'amount') {
//                                 productData.product['price_ttc'] = ((1 + productPrices.tax.rate / 100) * productData.product.price) - parseFloat(specificPrice.reduction);
//                                 productData.product['price'] = productData.product.price - parseFloat(specificPrice.reduction);
//                             }
//                             if(isValidReduction(specificPrice.from, specificPrice.to) && specificPrice.reduction_type == 'percentage') {
//                                 productData.product['price_ttc'] = ((1 + productPrices.tax.rate / 100) * productData.product.price) * parseFloat(1-specificPrice.reduction);
//                                 productData.product['price'] = productData.product.price * parseFloat(1-specificPrice.reduction);
//                             }
//                         });
//                     } catch (error) {
                        
//                     }

//                     // Ajout de la categorie    
//                     const catName = await api(`categories/${productData.product.id_category_default}`);
//                     productData.product['categorie_name_defaut'] = catName.category.name;

                    

//                     // Ajout des extensions au produits
//                     let optionsExtensions = []
//                     const productOptionExtension = await api('gestionoptionproduits', `display=full&filter[product_id]=${productData.product.id}`);
//                     productOptionExtension.gestionoptionproduits.forEach((e) => {
//                         optionsExtensions[e.option_name] = e
//                     });
//                     productData.product['options_extensions'] = optionsExtensions; // Assuming `e` contains the entire option data


                    
//                     // Ajout des caracteristiques produit
//                     try {
//                         let productFeatures = [];
//                         productData.product.associations.product_features.forEach(async element => {
//                             const productFeature = await api(`product_features/${element.id}`)
//                             const productFeatureValues = await api(`product_feature_values/${element.id_feature_value}`)
                            
//                             productFeatures.push([productFeature.product_feature.name, productFeatureValues.product_feature_value.value]);
//                         });
//                         productData.product['features'] = productFeatures;
//                     } catch (error) {
                        
//                     }

//                     // Ajout des documentations techniques
//                     try {
//                         let productAttachments = [];
//                         productData.product.associations.attachments.forEach( async element => {
//                             const productAttachement = await api(`attachments/${element.id}`);
//                             productAttachments.push({
//                                 'file_name': productAttachement.attachment.name,
//                                 'file_url': `${apiURL}/api/attachments/file/${element.id}?ws_key=${apiKEY}`,
//                                 'size': (productAttachement.attachment.file_size / (1024 * 1024)).toFixed(2)
//                             });
//                         });
//                         productData.product['pdf'] = productAttachments;
//                     } catch (error) {
                        
//                     }
                    
                    
//                     setProduct(productData.product);
                  
//               }catch(err){
//                   setError(err)
//               }finally{
//                   setLoading(false)
//               }
//           }
//       )()
//   }, [LINKREWRITE])

//   return { product, error, loading }

// }
// /**
//  * Récupère un produit par son link_rewrite
//  * @param {any} id=''
//  * @param {any} linkrewrite=''
//  * @returns {any}
//  */
// const useProduct = (LINKREWRITE = '') => {

//   const [product, setProduct] = useState()
//   const [error,setError] = useState(null)
//   const [loading,setLoading] = useState(false)


//   console.log(LINKREWRITE);
//   useEffect(() => {
//       (
        
//           async function(){

//             console.log(LINKREWRITE)
//               try{
//                   setLoading(true)

//                     var formdata = new FormData();

//                     formdata.append("link_rewrite", LINKREWRITE);

//                     const requestOptions = {
//                       method: "POST",
//                       body: formdata,
//                     };
                    
//                     const response = await fetch(process.env.REACT_APP_PRESTASHOP_URL+"modules/reactconnector/api/products/GetProduct.php", requestOptions)
//                     // const product = await response.json();
//                     console.log(response);                    
//                     // setProduct(product);
//               }catch(err){
//                   setError(err)
//               }finally{
//                   setLoading(false)
//               }
//           }
//       )()
//   }, [LINKREWRITE])

//   return { product, error, loading }

// }



// /**
//  * Récupère un produit par son link_rewrite
//  * @param {any} id=''
//  * @param {any} linkrewrite=''
//  * @returns {any}
//  */
// const useProduct = (LINKREWRITE = '') => {

//   const [product, setProduct] = useState([])
//   const [error,setError] = useState(null)
//   const [loading,setLoading] = useState(false)

//   useEffect(() => {
//       (
//           async function(){
//               try{
//                   setLoading(true)
//                   var formdata = new FormData();

//                   formdata.append("link_rewrite", LINKREWRITE);

//                   const requestOptions = {
//                     method: "POST",
//                     body: formdata,
//                   };
                  
//                   const response = await fetch(process.env.REACT_APP_PRESTASHOP_URL+"modules/reactconnector/api/products/GetProduct.php", requestOptions)

//                   const productData = await response.json();
//                   console.log('productData');           
// //
                    
                    
//                     setProduct([productData]);
                  
//               }catch(err){
//                   setError(err)
//               }finally{
//                   setLoading(false)
//               }
//           }
//       )()
//   }, [LINKREWRITE])

//   return { product, error, loading }

// }

const useProductsCategoryName = (name, link_cat_rewrite, limit = '') => {

    const [products, setProducts] = useState([]);
    const [error,setError] = useState(null);
    const [loading,setLoading] = useState(false);
    const {categories, error: errorCAt, loading: loadingCat} = useCategories();
    const {getCategorieParams, setCategorieParams} = useCategorieChoosedParams();

    useEffect(() => {
        (async function fetchData(){
            try{
                setLoading(true)
                var formdata = new FormData();

                if(name !== ''){
                    formdata.append("category_name", name);
                }else {
                    formdata.append("link_rewrite", link_cat_rewrite);
                }

                console.log(formdata.get('link_rewrite'))

                const requestOptions = {
                  method: "POST",
                  body: formdata,
                };
                
                const response = await fetch(process.env.REACT_APP_PRESTASHOP_URL+"modules/reactconnector/api/products/GetProducts.php", requestOptions)
                const productData = await response.json();

                // filter products by active
               
                console.log(productData);

                // setProducts(productData);
                // with limit if limit is set
                if(limit !== ''){
                    console.log(limit);
                    // setProducts(productData);
                    const limited = productData.slice(0, limit)
                    console.log(limited)
                    setProducts(limited);
                }else {
                    setProducts(productData);
                }

                if(loadingCat == false) {
                    console.log(categories)
                    // setCategorieParams(cat.name, cat.name, cat.id_category, cat.description, cat.id_category);
                    // Je veux chercher dans les enfant ou sous enfant link_rewrite == link_cat_rewrite
                    categories.forEach(cat => {
                        if(cat.link_rewrite == link_cat_rewrite) {
                            setCategorieParams(cat.name, cat.name, cat.id_category, cat.description, cat.id_category);
                        }
                        if(cat.children) {
                            cat.children.forEach(child => {
                                if(child.link_rewrite == link_cat_rewrite) {
                                    setCategorieParams(child.name, cat.name, child.id_category, child.description, cat.id_category);
                                }
                            })
                        }
                    })
                }

                
            }catch(err){
                setError(err)
            }finally{
                setLoading(false)
            }
        })()
    }, [name]) // Utilisez 'name' comme dépendance si vous voulez refaire la requête chaque fois que 'name' change
    return { products, error, loading }
}



const useProduct = (param_name, link_rewrite) => {

    const [product, setProduct] = useState([]);
    const [error,setError] = useState(null);
    const [loading,setLoading] = useState(false);

    useEffect(() => {
        (async function fetchData(){
            try{
                setLoading(true)
                var formdata = new FormData();

                formdata.append(param_name, link_rewrite);

                const requestOptions = {
                  method: "POST",
                  body: formdata,
                };
                
                const response = await fetch(process.env.REACT_APP_PRESTASHOP_URL+"modules/reactconnector/api/products/GetProduct.php", requestOptions)

                const productData = await response.json(); 

                setProduct(productData);
                
            }catch(err){
                setError(err)
            }finally{
                setLoading(false)
            }
        })()
    }, [link_rewrite]) // Utilisez 'name' comme dépendance si vous voulez refaire la requête chaque fois que 'name' change
    return { product, error, loading }
}




export {useProduct, useProductsCategoryName};