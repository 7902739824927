import React, { useEffect, useState } from "react";
import api from "../utils/fetch";


/**
 * Recupère les produits de la categorie avec l'id de catégorie
 * @param {any} id
 * @returns {any}
 */
const useCategories = () => {

  const [categories, setCategories] = useState([])
  const [error,setError] = useState(null)
  const [loading,setLoading] = useState(false)

  useEffect(() => {
      (
          async function(){
            try{
                setLoading(true)

                const requestOptions = {
                  method: "POST",
                };
                
                const response = await fetch(process.env.REACT_APP_PRESTASHOP_URL+"modules/reactconnector/api/categories/GetMenuCategories.php", requestOptions)

                const productData = await response.json();

                setCategories(productData);
                
            }catch(err){
                setError(err)
            }finally{
                setLoading(false)
            }
          }
      )()
  }, [])

  return { categories, error, loading }

}






export {useCategories};