import React, { useState } from 'react'
import LoadingComponent from '../loadingComponent'
import { useOrders } from '../../hooks/usePrestashopOrders';
import { useAuthState } from '../../store/store';
import { toPrice } from "../../utils/someFunctions";
import { useProduct } from '../../hooks/usePrestashopProducts';
import { Link } from 'react-router-dom';
import { dateFormatFrench } from '../../utils/formatters';

const OrderAccountComponent = () => {

    const {getUser} = useAuthState();
    const [orderTab, setOrderTab] = useState(1);
    const {orders, loading, errors} = useOrders(getUser('id_customer'));

    console.log(orders)

  return (
    loading ? <LoadingComponent /> :
    <div>
        <div className="grid grid-cols-2">
            <div onClick={() => setOrderTab(1)} className={`text-center ${orderTab == 1 ? 'text-black font-semibold' : 'text-greySoft font-semibold'}`}>Passées ({orders.filter(order => order.order_status === 'complet').length})</div>
            <div onClick={() => setOrderTab(2)} className={`text-center ${orderTab == 2 ? 'text-black font-semibold' : 'text-greySoft font-semibold'}`}>En cours ({orders.filter(order => order.order_status === 'en cours').length})</div>
        </div>
        <div className={`${orderTab == 1 ? 'items-start': 'justify-end'} relative bg-greySoft flex flex-row h-2 rounded-full my-4`}>
            <div className={` absolute h-2 bg-orange w-1/2 rounded-full`}></div>
        </div>
        

        { orders && orderTab === 2 && orders.filter(order => order.order_status === 'en cours').map((order) => (
        <div className='border-2 border-greySoft rounded-md p-4 my-4'>
          <h3 className='font-semibold text-xl'>Commande n° : {order.order_id}</h3>
          <div className='flex flex-row justify-end'>
              <Link className='pr-6 flex flex-row mobile:text-sm'>Télécharger la facture <img className='pl-2' src={window.location.origin + '/images/fac-svg.svg'} alt="" /></Link>
              <Link className='pr-6 flex flex-row mobile:text-sm'>Voir le détail <img className='pl-2' src={window.location.origin + '/images/arrow-right-line.svg'} alt="" /></Link>
          </div>
          <div className="h-0.5 my-4 w-full bg-greySoft"></div>
          {
                loading ? <LoadingComponent /> :
                order.products.map((product) => (
                    <OrderItemAccount key={order.id_order} data={product} />
                ))
            }
            <div className="h-0.5 my-4 w-full bg-greySoft"></div>
            <div className="grid grid-cols-4 mobile:grid-cols-2 mobile:text-sm">
                <div></div>
                <div></div>
                <div className='border-l-2 border-greySoft flex items-center'><span className='pl-2'>Passée le {dateFormatFrench(order.order_date)}</span></div>
                <div className='border-l-2 border-greySoft flex items-center'><span className='pl-2'>Total : {toPrice(order.total_paid)} TTC</span></div>
            </div>
        </div>
        ))}
        { orders && orderTab === 1 && orders.filter(order => order.order_status === 'complet').map((order) => (
        <div className='border-2 border-greySoft rounded-md p-4 my-4'>
          <h3 className='font-semibold text-xl'>Commande n° : {order.order_id}</h3>
          <div className='flex flex-row justify-end'>
              <a href="" className='pr-6 flex flex-row'>Télécharger la facture <img className='pl-2' src={window.location.origin + '/images/fac-svg.svg'} alt="" /></a>
              <a href="" className='pr-6 flex flex-row'>Voir le détail <img className='pl-2' src={window.location.origin + '/images/arrow-right-line.svg'} alt="" /></a>
          </div>
          <div className="h-0.5 my-4 w-full bg-greySoft"></div>
          {
                loading ? <LoadingComponent /> :
                order.products.map((product) => (
                    <OrderItemAccount key={order.id_order} data={product} />
                ))
            }
            <div className="h-0.5 my-4 w-full bg-greySoft"></div>
            <div className="grid grid-cols-4 mobile:grid-cols-2 mobile:text-sm">
                <div></div>
                <div></div>
                <div className='border-l-2 border-greySoft flex items-center'><span className='pl-2'>Passée le {dateFormatFrench(order.order_date)}</span></div>
                <div className='border-l-2 border-greySoft flex items-center'><span className='pl-2'>Total : {toPrice(order.total_paid)}</span></div>
            </div>
        </div>
        ))}
        
    </div>
  )
}

export default OrderAccountComponent


const OrderItemAccount = (data) => {
    console.log(data.data.product_id)
    const {product, loading} = useProduct('id_product', data.data.product_id)

    const productName = data?.data?.product_name 
    ? data.data.product_name.split(' - ').filter(part => !part.startsWith('Extension') && !part.startsWith('Déballage'))
    : [];

    return (
        <div className="flex flex-wrap py-2">
            <div className='w-1/6 mobile:w-2/6 flex justify-center p-3 '>
                <div className="bg-grey max-h-[100px] rounded-lg"><img className='mix-blend-multiply max-h-[100px]' src={product.cover_image} alt="" /></div>
            </div>
            <div className='w-4/6 mobile:w-3/6 mobile:text-sm'>
                <p className='font-semibold'>{product.name}</p>
                {data.data.product_option.map((option) => (
                    <div className='flex items-center'>
                        <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none"><circle cx="7" cy="7.5" r="4" fill="#F1671A"></circle><circle cx="7" cy="7.5" r="5.5" stroke="#F1671A" stroke-opacity="0.24" stroke-width="3"></circle></svg>
                        {option}
                    </div>
                ))}
                <p>Réf : {data.data.product_reference}</p>
                <p className='text-orange font-semibold'>{toPrice(data.data.product_paid_price_ht)} HT</p>
                <p className='font-semibold'>{toPrice(data.data.product_paid_price_ttc)} TTC</p>
            </div>
            <div><p className='font-semibold'>{data.data.product_quantity} pcs</p></div>
        </div>
    )
}