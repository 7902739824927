import React, { useEffect, useState } from 'react'
import { getImage } from '../utils/someFunctions'
import { Link } from 'react-router-dom'

function FooterComponent() {
  const [content, setContent] = useState();

    const getContent = async () => {
      const response = await fetch(process.env.REACT_APP_DIRECTUS_URL + 'items/pages');
      const data = await response.json();
      setContent(data.data);
    }

  useEffect(() => {
    getContent()
}, []);

  return (
    <>
    <div className='py-12 container mx-auto'>
      <div class="grid grid-cols-4 mobile:grid-cols-1 mobile:gap-4">
        <div className='text-center flex flex-col justify-center items-center'>
          <img src={getImage('Truck1.png')} alt="" />
          <p class="font-bold">Livraison Express</p>
          <p className='text-xs'>Retrait ou 24 dans toute l’Europe</p>
        </div>
        <div className='text-center flex flex-col justify-center items-center'>
          <img src={getImage('Credit card1.png')} alt="" />
          <p class="font-bold">Paiement sécurisé</p>
          <p className='text-xs'>Cb, Paypal, Chèque, Mandat administratif</p>
        </div>
        <div className='text-center flex flex-col justify-center items-center'>
          <img src={getImage('Reuse1.png')} alt="" />
          <p class="font-bold">Politique de retours</p>
          <p className='text-xs'>Retours faciles</p>
        </div>
        <div className='text-center flex flex-col justify-center items-center'>
          <img src={getImage('Tools1.png')} alt="" />
          <p class="font-bold">Maintenance et réparation</p>
          <p className='text-xs'>Contrats de maintenances & Extensions de garanties</p>
        </div>
      </div>
    </div>
    <div className=' bg-[#323232]'>
      <div className='container mx-auto grid grid-cols-2 mobile:grid-cols-1 py-6'>
        <div>
          <p className='text-white text-xl mobile:text-sm font-semibold mobile:text-center'>Inscrivez-vous à la newsletter La Boutique du Traceur</p>
          <p className='text-white mobile:text-sm mobile:py-2 mobile:text-center'>Ne ratez plus nos promos et offres pour vos achats ! </p>
        </div>
        <div className='relative'>
          <input type="text" placeholder='Votre adresse email' className='rounded-xl p-4 w-full' />
          <div className='absolute right-0 top-0'>
            <button className='bg-greyAccent text-white rounded-xl px-4 py-4'>Je m’abonne</button>
          </div>
        </div>
      </div>
    </div>
    <div className='flex flex-row mobile:flex-col container mx-auto py-12'>
      <div class="w-4/6 mobile:w-full grid grid-cols-3 mobile:grid-cols-2">
        <div className=''>
          <p class="font-bold py-2">A propos</p>
          {
            content ? 
            content.map((item, index) => (
              <p className='py-2' key={index}><Link to={'/content/'+item.slug}>{item.titre}</Link></p>
            ))
            : ''
          }
        </div>
        {/* <div className=''>
          <p class="font-bold py-2">Service Client</p>
          <p class="py-2">Centre d’aide </p>
          <p class="py-2">Mode de livraison</p>
          <p class="py-2">Moyen de paiement</p>
          <p class="py-2">Garantie et retour</p>
        </div> */}
        <div className=''>
          <p class="font-bold py-2">Mon compte</p>
          <p className='py-2'><Link to={'/mon-compte/informations'}>Connexion</Link></p>
          <p className='py-2'><Link to={'/mon-compte/commandes'}>Mes commandes</Link></p>
          {/* <p class="py-2">Revendeurs</p> */}
        </div>
        <div className=''>
          <p class="font-bold py-2">Paiement 100% sécurisé</p>
          <img src={getImage('Group 350.png')} alt="" />
        </div>
      </div>
      <div class="w-2/6 mobile:w-full border-l mobile:border-t mobile:border-l-0 px-4 py-4">
        <div class="grid grid-cols-4">
          <div className='text-center items-center justify-center flex flex-col'>
            <img src={getImage('phone-call (1) 1.png')} alt="" />
            <p className='font-semibold text-sm'>Appel</p>
          </div>
          <div className='text-center items-center justify-center flex flex-col'>
            <img src={getImage('mail 1.png')} alt="" />
            <p className='font-semibold text-sm'>Email</p>
          </div>
          <div className='text-center items-center justify-center flex flex-col'>
            <img src={getImage('whatsapp 1.png')} alt="" />
            <p className='font-semibold text-sm'>WhatsApp</p>
          </div>
          <div className='text-center items-center justify-center flex flex-col'>
            <img src={getImage('live-chat 1.png')} alt="" />
            <p className='font-semibold text-sm'>LiveChat</p>
          </div>
        </div>
        <p className='text-center font-semibold text-lg py-4'>Besoin d’aide pour passer commande ?</p>
        <p className='font-medium text-center'>Nous répondrons à toutes vos questions 
        par téléphone au <a href="tel:0 805 130 013" className='text-orange font-semibold'>0 805 130 013</a></p>
      </div>
    </div>
    <div class="text-center  text-sm py-4">
        Copyright © {new Date().getFullYear()} LaBoutiqueduTraceur.fr
    </div>
    </>
  )
}

export default FooterComponent