import React, { useEffect, useRef, useState } from 'react';
import { useAuthState, useCartState } from '../store/store';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CartesSavedAccoundComponent from './accountComponents/cartesSavedAccoundComponent';


const PayplugForm = ({ className, total, onCreateOrder, form, shippingMethod }) => {
  const scriptLoaded = useRef(false);
  const [payplugInstance, setPayplugInstance] = useState(null);

  const { getCartNumberItems, getCart, removeCart, getTotalCartHT, getTotalCartTVA, getTotalCartTTC, setPromoCode, getPromoCode, setLivraison, getLivraison } = useCartState();
  const cartItems = getCart();
  const navigate = useNavigate();
  const {getUser, isAuthenticated} = useAuthState();


  const [saveCard, setSaveCard] = useState(false);

  const [selectedCardSaved, setSelectedCardSaved] = useState(null);

  const [displayForm, setDisplayForm] = useState(false);
  const [hasSavedCards, setHasSavedCard] = useState(false);

  // Gérer le changement d'état de la case à cocher
  const handleCheckBoxChange = (event) => {
    setSaveCard(!saveCard);
  };

  useEffect(() => {
    if (!scriptLoaded.current) {
      const baliseScript = document.createElement("script");
      baliseScript.src = "https://cdn.payplug.com/js/integrated-payment/v1@1/index.js";
      baliseScript.addEventListener("load", () => {
        console.log("Script Payplug chargé");

        // Initialiser l'objet IntegratedPayment
        const integratedPayment = new window.Payplug.IntegratedPayment(
          true
        );



        // Add card holder field
        const cardHolder = integratedPayment.cardHolder(document.getElementById('cardholder-input-container'), {
          default: {
            fontFamily: "'Poppins', sans-serif",
          },
          placeholder: 'Jhon Doe',
        });

        // Add each payments fields
        const cardNumber = integratedPayment.cardNumber(document.getElementById('pan-input-container'), {
          default: {
            fontFamily: "'Poppins', sans-serif",
            fontWeight: '600'
          },
          invalid: {
            fontFamily: "'Poppins', sans-serif",
            color: '#FF695E',
          },
        });
        const cvv = integratedPayment.cvv(document.getElementById('cvv-input-container'), {
          default: {
            fontFamily: "'Poppins', sans-serif",
          },
          invalid: {
            fontFamily: "'Poppins', sans-serif",
            color: '#FF695E',
          },
        });
        const exp = integratedPayment.expiration(document.getElementById('exp-input-container'), {
          default: {
            fontFamily: "'Poppins', sans-serif",
          },
          invalid: {
            fontFamily: "'Poppins', sans-serif",
            color: '#FF695E',
          },
        });

        integratedPayment.onBefore3ds(() => {
          // Anything you want to do before the customer will be redirected to the 3DS screen.
        })
        integratedPayment.setDisplayMode3ds(window.Payplug.DisplayMode3ds.LIGHTBOX);

        integratedPayment.onCompleted((event) => {
          if(event.error) {
            // Handle payment error
            console.log('error', event.error);
          }
          
        });
        setPayplugInstance(integratedPayment);

      });

      document.head.appendChild(baliseScript);

      // Mettre à jour le ref pour indiquer que le script est maintenant chargé
      scriptLoaded.current = true;

      // Nettoyer la balise script lorsque le composant est démonté
      return () => {
        document.head.removeChild(baliseScript);
      };
    }
  }, []); // Le tableau vide indique que cet effet ne dépend d'aucune variable, donc il s'exécute une seule fois.


  const createOrder = async (payment_id) => {

    console.log('payment_id', payment_id);
    
    // try {
      var formdata = new FormData();

      // Enregistrement des produits dans la commande
      formdata.append('products', JSON.stringify(cartItems));

      // Enregistrement de la commande dans le client connecté
      if(isAuthenticated() == true && getUser('id_customer')) {
        formdata.append('id_customer', getUser('id_customer'));
        formdata.append('id_address', form.id_address);
      }

      // Si adresse de livraison différente de facturation
      formdata.append('delivery_different_billing', form.delivery_different_billing);

      // Création du client
      if(isAuthenticated() == false) {
        formdata.append('nom', form.nom);
        formdata.append('prenom', form.prenom);
        formdata.append('email', form.email);
        formdata.append('telephone', form.telephone);
        formdata.append('siret', form.siret);
        formdata.append('entreprise', form.entreprise);
        formdata.append('adresse_delivery', form.adresse_delivery);
        formdata.append('ville_delivery', form.ville_delivery);
        formdata.append('code_postal_delivery', form.code_postal_delivery);
        formdata.append('pays_delivery', form.pays_delivery);
      }

      // Si adresse de livraison différente de facturation création de l'adresse
      if(isAuthenticated() == false && form.delivery_different_billing == true) {
        formdata.append('adresse_billing', form.adresse_billing);
        formdata.append('ville_billing', form.ville_billing);
        formdata.append('pays_billing', form.pays_billing);
        formdata.append('code_postal_billing', form.code_postal_billing);
      }
      // Method de paiement
      formdata.append('payment_method', 'cb');
      formdata.append('payment_id', payment_id);
      formdata.append('total_ht', parseFloat(getTotalCartHT()).toFixed(2));
      formdata.append('total_ttc', parseFloat(getTotalCartTTC()).toFixed(2));
      // Méthode de transport
      formdata.append('shipping_method', shippingMethod);



      const response = await fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/orders/CreateOrder.php', {
        method: 'POST',
        body: formdata
      });

      console.log(response)

      const data = await response.json();
      console.log(data);
      if(response.ok) {
        removeCart();
        navigate('/order-confirmation');
      }
    // }
    // catch (error) {
    //   toast.error('Une erreur est survenue lors de la création de la commande');
    // }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    var payment_id = 0;

    console.log("total", total)
    // ici faire appel la requete pour récuperer l'url de paiement
    const formData = new FormData();
    formData.append('total', parseInt(total));
    formData.append('currency', 'EUR');
    formData.append('url_redirect_success', window.location.origin +'/order-confirmation');

    // Ajoute l'id du client si connecté
    if(isAuthenticated() == true && getUser('id_customer')) {
      formData.append('id_customer', getUser('id_customer'));
      formData.append('save_card', saveCard);
      if(selectedCardSaved) {
        formData.append('selected_card', selectedCardSaved);
        console.log('selectedCardSaved', selectedCardSaved);
      }
    }

    fetch(process.env.REACT_APP_PRESTASHOP_URL+"modules/reactconnector/api/payments/createPayment.php", {
      method: "POST",
      body: formData
    }).then(response => response.json())
      .then(data => {
        console.log('paiement en cours');
        console.log(data);
        payment_id = data.payment;
        payplugInstance.pay(payment_id, window.Payplug.Scheme.AUTO);

        payplugInstance.onCompleted(async (event) => {
          if(event.error) {
            // Handle payment error
            console.log('error', event.error);
            if(event.error.name == "INVALID_FORM") {
              toast.error('Vérifiez les informations de votre carte de paiement');
            }
          }
          else {
            console.log('success', event);
            await createOrder(event.token);
          }
          
        });

        if(selectedCardSaved != null && payment_id != 0) {
          createOrder(payment_id);
        }

      });
  };

  const onAddCard = () => {
    setDisplayForm(true);
  }


  const getSaveCard = () => {
    const formData = new FormData();
    formData.append('id_customer', getUser('id_customer'));
    formData.append('type', 'get');
    fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/customers/customerCards.php', {
       method: 'POST',
       body: formData
    })
    .then(response => response.json())
    .then(data => {
      if(data.cards.length > 0) {
        setHasSavedCard(true);
      }
    })
  };

  useEffect(() => {
    getSaveCard();
    if(isAuthenticated() && hasSavedCards == true) {
     setDisplayForm(false);
    }

    if(isAuthenticated() && hasSavedCards == false) {
      setDisplayForm(true);
    }

    if(isAuthenticated() == false) {
      setDisplayForm(true);
    }
  }, [])
  

  return (
    <div>
      { hasSavedCards == false &&
      <form id="my-payment-form" className='pt-3' onSubmit={(e) => handleSubmit(e)}>
        <div className='px-4 py-2 rounded-md border border-greyAccent bg-white'>
            <label htmlFor="" className='font-semibold'>Titulaire</label>
            <div id="cardholder-input-container" className='bg-white grow rounded-lg'></div>
        </div>
        <div className='grid grid-cols-3 mobile:grid-grid-cols-1 gap-3 py-3'>
          <div className='px-4 py-2 rounded-md border border-greyAccent bg-white'>
            <label htmlFor="" className='font-semibold'>Numéro de carte</label>
                <div id="pan-input-container" className='bg-white rounded-lg'>{/* Placeholder for card number input */}</div>
            </div>
          <div className='px-4 py-2 rounded-md border border-greyAccent bg-white'>
            <label htmlFor="" className='font-semibold'>Cryptogramme</label>
            <div id="cvv-input-container" className='bg-white rounded-lg '>{/* Placeholder for CVV input */}</div>
          </div>
          <div className='px-4 py-2 rounded-md border border-greyAccent bg-white'>
            <label htmlFor="" className='font-semibold'>Date d'expiration</label>
            <div id="exp-input-container" className='bg-white rounded-lg'>{/* Placeholder for expiration input */}</div>
          </div>
        </div>
        {
          isAuthenticated() &&
          <>
          <div className='flex items-center py-4'>
            {/* <input id='save-card-account' type='checkbox' checked={saveCard} onChange={handleCheckBoxChange} name='save_card' value='save_card' className='mr-2 accent-black' /> */}
            {/* <label htmlFor="save-card-account" className='text-xs'>Enregistrer la carte sur mon compte</label> */}
          </div>
          </>
        }
        <button type='submit' className='bg-green font-semibold text-white rounded-lg px-12 py-2'>Valider le paiement</button>
      </form>
      }
        {
          isAuthenticated() && hasSavedCards == true &&
          <>
            <CartesSavedAccoundComponent ischeckout={true} onCardSelected={(id) => setSelectedCardSaved(id)} onAddCard={() => setHasSavedCard(false)} />
            {
              selectedCardSaved != null &&
              <button onClick={(e) => handleSubmit(e)} className='bg-green font-semibold text-white rounded-lg px-12 py-2'>Valider le paiement</button>
            }
          </>
        }
    </div>
  );
};

export default PayplugForm;


