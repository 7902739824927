export const toPrice = (price) => {
    return parseFloat(price).toFixed(2).replace('.', ',') + ' €';
};


/** Vérifie la validité d'une réduction sur un produit */
export const isValidReduction = (startDate, endDate) => {
    const currentDate = new Date();
    const startDateTime = new Date(startDate).getTime();
    
    // Vérifie si la date de fin est définie et n'est pas "0000-00-00 00:00:00"
    if (endDate && endDate !== "0000-00-00 00:00:00") {
        const endDateTime = new Date(endDate).getTime();
        const currentDateTime = currentDate.getTime();
        return currentDateTime >= startDateTime && currentDateTime <= endDateTime;
    } else {
        // Si la date de fin est "0000-00-00 00:00:00", la réduction est considérée comme illimitée dans le temps
        return true;
    }
};


export const getImage = (imageName) => {
    return window.location.origin + '/images/'+ imageName;
}

export const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
}

export const removeHTMLTags = (str) => {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
}

// Permet de mélanger les éléments d'un tableau
if (!Array.prototype.shuffle) {
    Array.prototype.shuffle = function() {
        for (let i = this.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [this[i], this[j]] = [this[j], this[i]];
        }
        return this;
    };
}