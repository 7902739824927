import React, { useEffect, useState } from 'react'
import { toPrice } from '../utils/someFunctions';

const ModalExtensionGarantieComponent = ({onChooseOption, garanties}) => {

    const [option, setOption] = useState({name: '', description: '', price: 0});
  return (
    <div className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center ">
        <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div className="bg-white py-12 px-12 my-8 rounded-xl shadow-lg z-50 xl:max-w-screen-2xl md:max-w-screen-md sm:max-w-screen-sm">
            <div className="">
                <div className='flex flex-row justify-between'>
                <div className=''>
                    <h1 className='md:text-4xl font-semibold'>Protégez votre investissement !</h1>
                    <p className='mt-4 md:w-3/4'>Choisissez nos extensions de garantie et préservez votre traceur grand format en toute sérénité !</p>
                </div>
                <div className=''>
                    <img src={window.location.origin + '/images/Warranty.png'} alt="" />
                </div>
                </div>
                <p className='py-8 font-light'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                </p>
                <div className="w-full h-0.5 bg-greyAccent"></div>
                <div className="">
                    <h2 className='text-center py-6 text-black font-semibold md:text-2xl'>Sélectionnez une extension de garantie : </h2>
                    <div className="grid grid-cols-4 gap-6 justify-center items-center">
                    {garanties.map((garantie) => (
                        <div className={`border rounded-xl p-4 my-3 md:my-0 ${option.name === garantie.name ? 'bg-orange text-white': 'bg-white text-black'} ` }>
                            <p className="text-4xl font-bold text-center">{garantie.name}</p>
                            <p className="text-4xl font-bold text-center py-8">{toPrice(garantie.price)}</p>
                            <p className='text-center'>{garantie.description}</p>
                            <div className="pt-8 text-center">
                                <button className="rounded-full border bg-white text-black border-greySoft uppercase font-bold px-4 py-2" onClick={() => setOption(garantie)}>Choisir</button>
                            </div>
                        </div>
                    ))}
                    </div>
                    <div className='lg:px-52 pt-8'>
                        <input type="checkbox" checked={option === 3 ? true : false} id="aucune_garantie" 
                        onChange={() => setOption(3)} />
                        <label className='ml-2' htmlFor="aucune_garantie">Je ne souhaite aucune extension de garantie pour ce produit</label>
                    </div>
                    <div className="flex justify-center lg:justify-end items-end">
                        <button onClick={() => setOption()} className='px-12 py-2 text-white rounded-lg bg-green font-bold'>Valider ma séléction</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ModalExtensionGarantieComponent