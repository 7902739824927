import React, { useEffect, useState } from 'react'
import { useAuthState, useCartState } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { create } from 'filepond';
import { toast } from 'react-toastify';

const RibFormComponent = ({form, shippingMethod}) => {

    const {getCart, removeCart, getTotalCartHT, getTotalCartTTC } = useCartState();
    const cartItems = getCart();
    const navigate = useNavigate();
    const {getUser, isAuthenticated} = useAuthState();

    const [rib, setRib] = useState({});

    useEffect(() => {
         fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/payments/getPaymentMethods.php', {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => setRib(data.RIB))
        
    }, [])

    const createOrder = async () => {
    
        var formdata = new FormData();
    
        // Enregistrement des produits dans la commande
        formdata.append('products', JSON.stringify(cartItems));
    
        // Enregistrement de la commande dans le client connecté
        if(isAuthenticated() == true && getUser('id_customer')) {
          formdata.append('id_customer', getUser('id_customer'));
          formdata.append('id_address', form.id_address);
        }
    
        // Si adresse de livraison différente de facturation
        formdata.append('delivery_different_billing', form.delivery_different_billing);

        // Création du client
        if(isAuthenticated() == false) {
          formdata.append('nom', form.nom);
          formdata.append('prenom', form.prenom);
          formdata.append('email', form.email);
          formdata.append('telephone', form.telephone);
          formdata.append('siret', form.siret);
          formdata.append('entreprise', form.entreprise);
          formdata.append('adresse_delivery', form.adresse_delivery);
          formdata.append('ville_delivery', form.ville_delivery);
          formdata.append('code_postal_delivery', form.code_postal_delivery);
          formdata.append('pays_delivery', form.pays_delivery);
        }

        // Si adresse de livraison différente de facturation création de l'adresse
        if(isAuthenticated() == false && form.delivery_different_billing == true) {
          formdata.append('adresse_billing', form.adresse_billing);
          formdata.append('ville_billing', form.ville_billing);
          formdata.append('pays_billing', form.pays_billing);
          formdata.append('code_postal_billing', form.code_postal_billing);
        }
        // Method de paiement
        formdata.append('payment_method', 'virement_bancaire');
        formdata.append('total_ht', parseFloat(getTotalCartHT()).toFixed(2));
        formdata.append('total_ttc', parseFloat(getTotalCartTTC()).toFixed(2));

        console.log('total_ht', getTotalCartTTC());
    
        // Méthode de transport
        formdata.append('shipping_method', shippingMethod);
    
        const response = await fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/orders/CreateOrder.php', {
          method: 'POST',
          body: formdata
        });
    
        const data = await response.json();
        console.log(data);
        if(response.ok && data.success == true) {
          removeCart();
          navigate('/order-confirmation');
        } else {
          console.log(data);
          toast.error('Erreur, veuillez réessayer, votre commande n\'a pas été enregistrée');
        }
      }
  return (
    <div className=''>
        <div className='flex flex-wrap gap-6 py-4'>
            <div className='mobile:w-full'>
                <div className='font-semibold uppercase'>IBAN</div>
                <div>{rib.IBAN}</div>
            </div>
            <div>
                <div className='font-semibold uppercase'>BIC</div>
                <div>{rib.BIC}</div>
            </div>
            <div>
                <div className='font-semibold uppercase'>Titulaire</div>
                <div>Laboutique</div>
            </div>
        </div>
        <button onClick={() => createOrder()} className='bg-green font-semibold text-white rounded-lg px-12 py-2'>Valider le paiement</button>
    </div>
  )
}

export default RibFormComponent