import { useState, useEffect, useRef } from 'react';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useAuthState, useCartState } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const PaypalComponent = ({form, shippingMethod}) => {
  

  const {getCart, removeCart, getTotalCartHT, getTotalCartTTC } = useCartState();
  const cartItems = getCart();
  const navigate = useNavigate();
  const {getUser, isAuthenticated} = useAuthState();

  const total = getTotalCartTTC().toFixed(2).toString();

  console.log('total', total);

  // Transfrom the total to a string



  const onCreateOrder = (data,actions) => {
    return actions.order.create({
        purchase_units: [
            {
                amount: {
                    value: total,
                },
            },
        ],
    });
}


  const createOrder = async () => {
      
    var formdata = new FormData();

    // Enregistrement des produits dans la commande
    formdata.append('products', JSON.stringify(cartItems));

    // Enregistrement de la commande dans le client connecté
    if(isAuthenticated() == true && getUser('id_customer')) {
      formdata.append('id_customer', getUser('id_customer'));
      formdata.append('id_address', form.id_address);
    }

    // Si adresse de livraison différente de facturation
    formdata.append('delivery_different_billing', form.delivery_different_billing);

    // Création du client
    if(isAuthenticated() == false) {
      formdata.append('nom', form.nom);
      formdata.append('prenom', form.prenom);
      formdata.append('email', form.email);
      formdata.append('telephone', form.telephone);
      formdata.append('siret', form.siret);
      formdata.append('entreprise', form.entreprise);
      formdata.append('adresse_delivery', form.adresse_delivery);
      formdata.append('ville_delivery', form.ville_delivery);
      formdata.append('code_postal_delivery', form.code_postal_delivery);
      formdata.append('pays_delivery', form.pays_delivery);
    }

    // Si adresse de livraison différente de facturation création de l'adresse
    if(isAuthenticated() == false && form.delivery_different_billing == true) {
      formdata.append('adresse_billing', form.adresse_billing);
      formdata.append('ville_billing', form.ville_billing);
      formdata.append('pays_billing', form.pays_billing);
      formdata.append('code_postal_billing', form.code_postal_billing);
    }
    // Method de paiement
    formdata.append('payment_method', 'paypal');
    formdata.append('total_ht', parseFloat(getTotalCartHT()).toFixed(2));
    formdata.append('total_ttc', parseFloat(getTotalCartTTC()).toFixed(2));

    console.log('total_ht', getTotalCartTTC());

    // Méthode de transport
    formdata.append('shipping_method', shippingMethod);

    const response = await fetch(process.env.REACT_APP_PRESTASHOP_URL+'modules/reactconnector/api/orders/CreateOrder.php', {
      method: 'POST',
      body: formdata
    });

    const data = await response.json();
    console.log(data);
    if(response.ok && data.success == true) {
      removeCart();
      navigate('/order-confirmation');
    } else {
      console.log(data);
      toast.error('Erreur, veuillez réessayer, votre commande n\'a pas été enregistrée');
    }
  }

const onApproveOrder = (data,actions) => {
    return actions.order.capture().then((details) => {
    const name = details.payer.name.given_name;
    createOrder();
  });
}

  const initialOptions = {
    "client-id": process.env.REACT_APP_CLIENT_ID_PAYPAL,
    currency: "EUR",
    intent: "capture",
  };


  return (
    <div >
      {/* Composant PayPalButtons avec la référence */}
      <div>
        <PayPalScriptProvider options={initialOptions} /* options={initialOptions} */ >
          {/* {
          isPending ? <div>Loading...</div> : */}
          <PayPalButtons
            // style={{ 
            //   layout: "horizontal",
            //   color: "silver",
            //   label: "pay",
            //   height: 40,
            // }}
            style={{ layout: "vertical" }}
            createOrder={(data, actions) => onCreateOrder(data, actions)}
            onApprove={(data, actions) => onApproveOrder(data, actions)}

          />
          {/* } */}
        </PayPalScriptProvider>
      </div>
    </div>
  );
};

export default PaypalComponent;

export const PayPalProvider = ({children}) => {
  const initialOptions = {
    "client-id": "AT7QBPu7D1CXs5951kGfv1PnKy6DqG_mFT_X0v35FeoKYxPGQ283m32KPDgNRRiiQMH6yDZlGA-WEtPr",
    currency: "EUR",
    intent: "capture",
  };


  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  return <PayPalScriptProvider options={initialOptions}>{isPending ? <div>Loading...</div> : {children}}</PayPalScriptProvider>
}
