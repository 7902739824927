import React from 'react'
import { Link } from 'react-router-dom'
import FooterComponent from '../components/footerComponent'

const OrderConfirmationPage = () => {
  return (
    <>
    <div className='hidden md:block shadow-lg'>
        <div className='container mx-auto'>
            <div className="py-4 border-b border-grey">
                <div className="flex flex-wrap justify-between">
                    <div className='w-1/3'>
                        Besoin d’aide ? Appelez-nous au <a className='text-orange font-semibold' href="tel:01 48 58 26 26">01 48 58 26 26</a>
                    </div>
                    <div className='font-bold w-1/3 text-center'>Livraison gratuite dès 300€HT d'achat</div>
                    <div className='flex flex-wrap justify-around w-1/3'>
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center py-3">
                <div className='w-1/4 flex justify-center'><Link to="/"><img className='w-full' src={'https://laboutiquedutraceur-16-to-8.mi2a-innovation.com/api/images/general/header?ws_key=aviel_S38M2jgt423n2tUX9qSHDj7nnX'} alt="logo-la-boutique-du-traceur" /></Link></div>
                <div className=' flex justify-between'>
                    <div className='flex flex-row'>
                        <div className='text-left'>
                            <p className='text-xs'>Bonjour, identifiez-vous</p>
                            <p className='font-semibold text-sm'>Mon compte</p>
                        </div>
                        <div className='pl-3'>
                            <img className='' src="/images/ic-actions-user.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* Menu Mobile */}
    <div className='block md:hidden'>
        <div className="container mx-auto">
            <div className="flex flex-row items-center">
                <div className='w-1/5'><img src="/images/menu-icon-mobile.svg" alt="" /></div>
                <div className='w-3/5'><img src={'https://laboutiquedutraceur-16-to-8.mi2a-innovation.com/api/images/general/header?ws_key=aviel_S38M2jgt423n2tUX9qSHDj7nnX'} alt="logo-la-boutique-du-traceur" /></div>
                <div className='w-1/5 flex flex-row justify-around'>
                    <div><img src="/images/icon-user.png" alt="" /></div>
                    <div><img src="/images/icon-panier.svg" alt="" /></div>
                </div>
            </div>
        </div>
    </div>
    <div className='text-center flex flex-col justify-center items-center min-h-[400px]'>
        <svg className='my-12' width="104" height="74" viewBox="0 0 104 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.8373 56.2897L94.7577 0.369385L103.361 8.9725L38.8373 73.4958L0.123047 34.7821L8.72616 26.179L38.8373 56.2897Z" fill="#3CCC5B"/>
        </svg>
        <h1 className='text-4xl mobile:text-3xl font-semibold'>Merci !</h1>
        <h2 className='text-4xl mobile:text-3xl font-semibold py-3'>Votre commande est confirmée</h2>
        <p className='py-12'>Vous allez recevoir un mail de confirmation avec le détail de votre commande</p>
        {/* <button className='px-4 py-2 bg-greyAccent text-white rounded-lg'>Suivre votre commande</button> */}
    </div>
    <FooterComponent />
    </>
  )
}

export default OrderConfirmationPage