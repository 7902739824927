import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useAuthState, useCartState, useCategorieChoosedParams } from '../store/store';
import Cart from './cartComponent';
import { toPrice } from '../utils/someFunctions';
import { useCategories } from '../hooks/usePrestashopCategory';
import MonCompteDropDown from './accountComponents/MonCompteDropDown';
import SearchComponent from './html/SearchComponent';

function HeaderComponent() {
    const {isAuthenticated, getUser, logout} = useAuthState();
    
    const { getCartNumberItems, getCount, getTotalCartHT } = useCartState();

    const {setCategorieParams, getCategorieParams} = useCategorieChoosedParams();

    const {categories, error, loading} = useCategories();
    const [cartModal, setCartModal] = useState(false);
    const [mobileMenuModal, setMobileMenuModal] = useState(false);
    const [subMobileMenuModal, setSubMobileMenuModal] = useState(false);
    const [submenu, setSubmenu] = useState(false);
    const [submenuId, setSubmenuId] = useState(null);
    const navigate = useNavigate();
    

    const setSubCatMenu = (id, state = true) => {
        setSubmenu(state); 
        setSubmenuId(id);
    }

    // Desactiver le scroll du body lors de l'ouverture du modal Cart
    // useEffect(() => {
    //     if (cartModal) {
    //       // Désactiver le défilement du corps de la page
    //       document.body.style.overflow = 'hidden';
    //     } else {
    //       // Réactiver le défilement du corps de la page
    //       document.body.style.overflow = '';
    //     }
    //   }, [cartModal]);

  return (
    <>
    {/* Menu Desktop */}
    <Cart isOpen={cartModal} onClose={() => setCartModal(false)} />
    <div className='hidden md:block'>
        <div className='container mx-auto'>
            <div className="py-4 border-b border-grey">
                <div className="grid grid-cols-3">
                    <div className=''>
                        Besoin d’aide ? Appelez-nous au <a className='text-orange font-semibold' href="tel:01 48 58 26 26">01 48 58 26 26</a>
                    </div>
                    <div className='font-bold text-center'>Livraison gratuite dès 300€HT d'achat</div>
                    <div className='grid grid-cols-3 text-center'>
                        <Link to={'/blog'}>Blog</Link>
                        <Link to={'/content/4-qui-sommes-nous'}>A Propos</Link>
                        <Link to={'/faq'}>Faq</Link>
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center py-3">
                <div className='w-1/4 flex justify-center'><Link to="/"><img className='w-full' src={'https://laboutiquedutraceur-16-to-8.mi2a-innovation.com/api/images/general/header?ws_key=aviel_S38M2jgt423n2tUX9qSHDj7nnX'} alt="logo-la-boutique-du-traceur" /></Link></div>
                <div className='w-2/5'>
                    <div className='w-full'>
                        <SearchComponent maxResutlt='1000' placeholder={'Rechercher une référence, produit... '} />
                    </div>
                    <div className="flex flex-row py-2 justify-between">
                        {/* <a href='' className="mr-2 rounded-lg border border-greyAccent text-greyAccent text-xs flex px-2 py-2">
                        Réparer mon traceur
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="ml-2 w-4 h-4 text-orange">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </a> */}
                        {/* <a href='' className="mr-2 rounded-lg border border-greyAccent text-greyAccent text-xs flex px-2 py-2">
                            Calculer une reprise
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="ml-2 w-4 h-4 text-orange">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </a> */}
                        {/* <a href='' className="mr-2 rounded-lg border border-greyAccent text-greyAccent text-xs flex px-2 py-2">
                            Vérifier ma garantie
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={3} stroke="currentColor" className="ml-2 w-4 h-4 text-orange">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        </a> */}
                    </div>
                </div>
                <div className='w-1/4 flex justify-between'>
                    <MonCompteDropDown />
                    <div className='flex flex-row' onClick={() => setCartModal(true)}>
                        <div className='text-left'>
                            <p className='text-xs'>{toPrice(getTotalCartHT())}</p>
                            <p className='font-semibold text-sm'>Mon panier</p>
                        </div>
                        <div className='pl-6 relative' >
                            <img className='' src="/images/Panier.png" alt="" />
                            { getCartNumberItems() > 0 && (
                                <span className="bg-orange text-white font-bold px-2 py-0 rounded-full z-10 left-3 top-2 absolute">{getCartNumberItems()}</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-orange ">
            <div className="container mx-auto flex py-3 justify-center relative">
                {categories.map((cat,index) => (
                    cat.active == 1 &&
                    <div className='px-4'>
                        {   cat.link_rewrite && cat.active == 1 &&
                            <Link onClick={() => setCategorieParams(cat.name, cat.name, cat.id_category, cat.description, cat.id_category)} to={'/'+cat.id_category+'-'+cat.link_rewrite} onMouseEnter={() => setSubCatMenu(index)} className='uppercase text-white text-xs'>{cat.name}</Link>
                        }
                        {/* {submenu && submenuId == index &&
                            <div onMouseLeave={() => setSubCatMenu(index, false)} className='absolute p-5 top-12 bg-white z-10 shadow-xl rounded-lg'>
                                <div className='flex flex-wrap w-full'>
                                    {categories[submenuId].children.map((subcat, index) => (
                                        <div className='px-4'>
                                            <div className='font-semibold py-2 uppercase'><Link onClick={() => setCategorieParams(subcat.name, subcat.name, subcat.id_category, subcat.description)} to={'/'+subcat.id_category+'-'+subcat.link_rewrite}>{subcat.name}</Link></div>
                                            {subcat.children && subcat.children.map((item, itemIndex) => (
                                                <div key={itemIndex} className=''>
                                                    <Link onClick={() => setCategorieParams(subcat.name+' / '+item.name, item.name, item.id_category, item.description, cat.id_category)} to={'/'+item.id_category+'-'+item.link_rewrite}>{item.name}</Link>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        } */}
                    </div>
                ))}
                
                {categories.sort((a, b) => a.position - b.position).map((cat,index) => (
                    <div className=''>
                        {submenu && submenuId == index &&
                            <div onMouseLeave={() => setSubCatMenu(index, false)} className='absolute p-5 left-0 top-12 bg-white z-10 shadow-xl rounded-lg w-full'>
                                <div className='flex flex-wrap justify-center'>
                                    {categories[submenuId].children.map((subcat, index) => (
                                        <div className='px-4'>
                                            <div className='font-semibold py-2 uppercase'><Link onClick={() => {
                                                setCategorieParams(subcat.name, subcat.name, subcat.id_category, subcat.description)
                                            }} to={'/'+subcat.id_category+'-'+subcat.link_rewrite}>{subcat.name}</Link></div>
                                            {subcat.children && subcat.children.map((item, itemIndex) => (
                                                <div key={itemIndex} className=''>
                                                    <Link onClick={() => {
                                                        setCategorieParams(subcat.name+' / '+item.name, item.name, item.id_category, item.description, cat.id_category);
                                                        setSubCatMenu(index, false);
                                                    }} to={'/'+item.id_category+'-'+item.link_rewrite}>{item.name}</Link>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                ))} 
            </div>
            
        </div>
    </div>
    {/* Menu Mobile */}
    <div className='block md:hidden'>
        {
            mobileMenuModal && (
                <div className="fixed inset-0 overflow-y-auto bg-white z-50">
                    <div class="bg-grey px-4 py-6 flex items-center justify-between">
                        <h3 className='font-bold text-2xl '>
                            {
                                getCategorieParams().cat_name ? 
                                    <>
                                    {/* SVG ARROW LEFT */}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        onClick={() => {
                                            setCategorieParams('', '', '', '', '');
                                            setSubmenu(false);
                                        }}
                                    >
                                        <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M15 19l-7-7 7-7"
                                        />
                                    </svg>
                                    {getCategorieParams().cat_name}
                                    </>
                                : 'CATÉGORIES'
                            }
                        </h3>
                        <button onClick={() => setMobileMenuModal(false)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                        </button>
                    </div>
                    <div>
                    {categories.sort((a, b) => a.position - b.position).map((cat,index) => (
                        <div className=''>
                            {   submenu == false && cat.link_rewrite && cat.active == 1 &&
                                <div onClick={() => {
                                    setCategorieParams(cat.name, cat.name, cat.id_category, cat.description, cat.id_category);
                                    setSubCatMenu(index)
                                    setMobileMenuModal(true)
                                }}
                                className='text-xl flex  justify-between items-center px-4 py-3 border-b border-greySoft font-semibold'>
                                    {cat.name}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 mr-12"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5l7 7-7 7"
                                        />
                                    </svg>

                                </div>
                            }
                            {submenu && submenuId == index &&
                                <div className='px-4 py-3 '>
                                    <div className=''>
                                        {categories[submenuId].children.map((subcat, index) => (
                                            <div>
                                                <div className='font-semibold py-2 uppercase'><Link onClick={() => {
                                                    setCategorieParams(subcat.name, subcat.name, subcat.id_category, subcat.description)
                                                    setMobileMenuModal(false);
                                                }} to={'/'+subcat.id_category+'-'+subcat.link_rewrite}>{subcat.name}</Link></div>
                                                {subcat.children && subcat.children.map((item, itemIndex) => (
                                                    <div key={itemIndex}>
                                                        <Link className='font-normal' onClick={() => {
                                                            setCategorieParams(subcat.name+' / '+item.name, item.name, item.id_category, item.description, cat.id_category);
                                                            setMobileMenuModal(false);
                                                        }} to={'/'+item.id_category+'-'+item.link_rewrite}>{item.name}</Link>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                    </div>
                </div>
            )
        }
        <div className="container mx-auto py-4">
            <div className="flex flex-row items-center gap-4">
                <div className='w-1/5'><img src="/images/menu-icon-mobile.svg" alt="" onClick={() => setMobileMenuModal(true)} /></div>
                <div className='w-3/5'><Link to={'/'}><img src={'https://laboutiquedutraceur-16-to-8.mi2a-innovation.com/api/images/general/header?ws_key=aviel_S38M2jgt423n2tUX9qSHDj7nnX'} alt="logo-la-boutique-du-traceur" /></Link></div>
                <div className='w-1/5 flex flex-row justify-around'>
                    <div><Link to={'/mon-compte/information'}><img src="/images/icon-user.png" alt="" /></Link></div>
                    <div className='flex flex-row' onClick={() => setCartModal(true)}>
                        <div className='pl-6 relative' >
                            <img className='' src="/images/Panier.png" alt="" />
                            { getCartNumberItems() > 0 && (
                                <span className="bg-orange text-white font-bold px-2 py-1 rounded-full z-10 left-3 top-2 absolute text-xs shadow-md">{getCartNumberItems()}</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full pt-4'>
                <SearchComponent placeholder={'Rechercher une référence, produit... '} />
            </div>
        </div>
    </div>
    </>
  )
}

export default HeaderComponent