import './App.css';
import HomePage from './pages/homePage';
import { BrowserRouter as Router, Route, Switch, Routes, Navigate, BrowserRouter, HashRouter } from 'react-router-dom';
import ProductPage from './pages/productPage';
import CategoriePage from './pages/categoriePage';
import { CheckoutPage } from './pages/checkoutPage';
import MonComptePage from './pages/monComptePage';
import LoginPage from './pages/loginPage';
import RegisterPage from './pages/registerPage';
import { useAuthState } from './store/store';
import AuthGuard from './utils/AuthGuard';
import OrderConfirmationPage from './pages/orderConfirmationPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ScrollTop } from './utils/ScrollTop';
import BlogPage from './pages/blogPage';
import BlogArticlePage from './components/blogArticlePage';
import FaqPage from './pages/faqPage';
import PagesContent from './pages/PagesContent';



function App() {

  const {getUser, isAuthenticated} = useAuthState();
  return (
    <>
    <BrowserRouter >
    <ToastContainer />
    <ScrollTop />
    <Routes>
        <Route path='/' Component={HomePage} />
        <Route path='/:categoryName/:linkrewrite' Component={ProductPage} />
        <Route path='/:categoryName' Component={CategoriePage} />
        <Route path='/passer-commande' Component={CheckoutPage} />
        <Route path='/order-confirmation' Component={OrderConfirmationPage} />
        <Route path='/login' Component={LoginPage} />
        <Route path='/creer-mon-compte' Component={RegisterPage} />
        <Route path='/mon-compte/:tab' element={<AuthGuard children={<MonComptePage />}  />} />
        <Route path='/blog' Component={BlogPage} />
        <Route path='/blog/:blogArticleId' Component={BlogArticlePage} />
        <Route path='/faq' Component={FaqPage} />
        <Route path='/content/:slug' Component={PagesContent} />
    </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
